import React, { useState } from 'react';
import CreateGroup from "./CreateGroup";
import defaultProfileImage from '../../assets/images/gorupplaceholder.png';
const Orgnav = ({ setOrgPersonalChat, orgPersonalChat, handleSearchChange,name,icon }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const imageUrl = icon
  ? `https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${icon}`
  : defaultProfileImage;
// console.log(icon?.file_url)
  return (
    <div>
      <div
        style={{
          width: '100%',
          height: '70px',
          backgroundColor: '#FFFCEE',
          display: 'flex',
          flexDirection: 'row',
          alignSelf: 'center',
          marginVertical: '5px',
          cursor: 'pointer',
        }}
        className="flex items-center justify-center p-4 hover:bg-gray-100 cursor-pointer"
      >
        <div
          className="flex-shrink-0 w-12 h-12 flex items-center justify-center text-2xl"
          style={{
            backgroundColor: '#D9D9D9',
            border: '2px solid #c3c3c3',
            borderRadius: '10px',
          }}
        >
              <img
          src={imageUrl}
          alt="Profile"
          style={{ resize: "both" }}
        />
            {/* <img src={ `https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${icon}`} style={{ resize:'both'}}  /> */}
        </div>
        <div className="ml-4 flex-1">
          <div className="font-bold text-gray-800">{name}</div>
          {/* <div className="text-sm text-gray-600">400</div> */}
          <div className="text-sm text-gray-600">message</div>
        </div>
        <div className="ml-4 text-right" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: "5px" }}>
          <img
            src="images/Plus.png"
            alt="Plus"
            style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }}
            onClick={() => setIsModalOpen(true)}
          />
          <img
            src="images/img_search.svg"
            alt="Search"
            style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }}
            onClick={() => setIsSearchOpen(!isSearchOpen)}
          />
        </div>
        <CreateGroup
          open={isModalOpen}
          onClose={handleCloseModal}
          setOrgPersonalChat={setOrgPersonalChat}
          orgPersonalChat={orgPersonalChat}
          icon={icon}
        />
      </div>
      {isSearchOpen && (
        <div style={{ width: '100%', backgroundColor: '#FFFCEE', padding: '10px' }}>
          <input
            type="text"
            placeholder="Search Groups"
            onChange={handleSearchChange}
            style={{ margin: '10px', padding: '5px', width: '95%' }}
          />
        </div>
      )}
    </div>
  );
};

export default Orgnav;
