import React from "react";

import { CloseSVG } from "../../assets/images";
import { Text, Button, Img,  } from "../../components";
import { ReactTable } from "../../components/ReactTable";
import { createColumnHelper } from "@tanstack/react-table";
import { SelectBox } from "components/SelectBox";
import { Heading } from "components/Heading";
import { Radio } from "components/Radio";
import Table from "./Table"

const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];
const table2Data = [
  { rowview: "Dummy name", rowrole: "Dummy name", designation: "Designer", rowlocation: "Pune" },
  { rowrole: "Dummy name", designation: "Designer", rowlocation: "Pune" },
  { rowrole: "Dummy name", designation: "Manager", rowlocation: "Pune" },
  { rowrole: "Dummy name", designation: "Manager", rowlocation: "Pune" },
  { rowrole: "Dummy name", designation: "Designer", rowlocation: "Pune" },
  { rowrole: "Dummy name", designation: "Lead Manager", rowlocation: "Pune" },
];

export default function CreategroupTwoPage(props) {
  const [searchBarValue12, setSearchBarValue12] = React.useState("");
  const table2Columns = React.useMemo(() => {
    const table2ColumnHelper = createColumnHelper();
  
    return [
      table2ColumnHelper.accessor("rowview", {
        cell: (info) => (
          <div className="flex flex-row justify-center">
            <div className="flex flex-row justify-between items-center w-full border-gray-500 border-t border-l border-b border-solid">
              <div className="h-[21px] w-[21px] ml-[18px] border-cyan-600 border border-solid" />
              <Text as="p" className="mt-[13px] mr-[7px]">
                {info?.getValue?.()}
              </Text>
            </div>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row justify-between items-center p-[7px]">
            <div className="h-[21px] w-[21px] mt-[3px] ml-3 bg-blue_gray-100" />
            <Text as="p" className="mr-[34px] sm:mr-5 !text-gray-200">
              Name
            </Text>
          </div>
        ),
        meta: { width: "215px" },
      }),
      table2ColumnHelper.accessor("rowrole", {
        cell: (info) => (
          <Text as="p" className="border-gray-500 border-t border-b border-solid">
            {info?.getValue?.()}
          </Text>
        ),
        header: (info) => (
          <div className="flex flex-row justify-center">
            <Button
              color="cyan_600"
              variant="fill"
              shape="square"
              className="w-full sm:px-5 font-montserrat font-medium"
            >
              Role
            </Button>
          </div>
        ),
        meta: { width: "310px" },
      }),
      table2ColumnHelper.accessor("designation", {
        cell: (info) => (
          <div className="flex flex-row justify-center">
            <div className="flex flex-row sm:flex-col justify-center items-start w-full sm:gap-5">
              <div className="flex flex-row justify-start w-[57%] sm:w-full p-[9px] border-gray-500 border-t border-b border-solid">
                <Text as="p" className="ml-[45px] md:ml-5">
                  {info?.getValue?.()}
                </Text>
              </div>
              <Text as="p" className="mt-2 ml-[-24px] sm:ml-0 sm:mt-0">
                dummy@convenevc.com
              </Text>
            </div>
          </div>
        ),
        header: (info) => (
          <Text as="p" className="flex justify-center items-center h-[40px] !text-gray-200 bg-cyan-600">
            Designation
          </Text>
        ),
        meta: { width: "243px" },
      }),
      table2ColumnHelper.accessor("rowemail", {
        cell: (info) => (
          <div className="flex flex-row justify-center">
            <div className="h-[40px] w-full border-gray-500 border-t border-b border-solid" />
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row justify-center sm:w-full">
            <input
              color="cyan_600"
              variant="fill"
              shape="square"
              type="email"
              name="email"
              placeholder="Email ID"
              className="w-full sm:w-full font-montserrat font-medium"
            />
          </div>
        ),
        meta: { width: "250px" },
      }),
      table2ColumnHelper.accessor("rowlocation", {
        cell: (info) => (
          <div className="flex flex-col items-start justify-start">
            <Button shape="square" className="sm:px-5 font-montserrat font-medium min-w-[203px] sm:min-w-full">
              {info?.getValue?.()}
            </Button>
            <Text
              as="p"
              className="mt-[-40px] ml-[203px] pt-[13px] pb-1.5 px-[35px] md:ml-5 sm:px-5 border-gray-500 border-t border-b border-r border-solid"
            >
              001
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row justify-center">
            <Button
              color="cyan_600"
              variant="fill"
              shape="square"
              className="sm:px-5 font-montserrat font-medium min-w-[203px]"
            >
              Location
            </Button>
            <Text
              as="p"
              className="flex justify-center items-center w-[136px] h-[40px] pl-2 pr-[27px] py-2.5 sm:pr-5 !text-gray-200 bg-cyan-600"
            >
              Employee id
            </Text>
          </div>
        ),
        meta: { width: "339px" },
      }),
    ];
  }, []);
  const handlenext =()=>{
    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
  } 
  return (
    <>
     
      <div className="flex flex-col h-[260px]items-center justify-center w-full pb-[209px] gap-[51px] md:pb-5 bg-white-A700" >
       
       
           
            <div className="flex flex-row md:flex-col justify-start items-start w-full mt-1 ml-[7px] gap-[33px] md:gap-5 md:ml-0" >
       
              <div className="flex flex-col items-start justify-start w-[98%] md:w-full mt-[33px] md:mt-0" >
                <div className="flex flex-row justify-start items-center gap-6">
                <div className="border border-gray-500 rounded-[10px] flex items-center">
              
                  
                <div style={{ display: "flex", alignItems: "center", border: "1px solid #ccc", borderRadius: "10px", padding: "5px", width: "250px" }}>
                <img src="images/img_search.svg" alt="Search" style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }} />
      <input type="text" placeholder="Search..." style={{ flex: "1", border: "none", outline: "none", padding: "5px" }} />
    </div>
              </div>
                  <Img src="images/img_icon_gray_700_17x19.svg" alt="icon_five" className="h-[17px]" />
                </div>
                <Text size="4xl" as="p" className="mt-[23px] !text-gray-700">
                  Select Members
                </Text>
                <div className="flex flex-row md:flex-col justify-start sm:w-full mt-6 gap-8 md:gap-5 w-[68%]">
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down"  />}
                    name="selectcategory"
                    placeholder="Select category"
                    options={dropDownOptions}
                    className="w-[32%] md:w-full gap-px border-gray-700 border border-solid"
                    
                  />
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" />}
                    name="selectcategory"
                    placeholder="Select category"
                    options={dropDownOptions}
                    className="w-[32%] md:w-full gap-px border-gray-700 border border-solid"
                  />
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" />}
                    name="selectcategory"
                    placeholder="Select category"
                    options={dropDownOptions}
                    className="w-[32%] md:w-full gap-px border-gray-700 border border-solid"
                  />
                </div>
                <div className="flex flex-row md:flex-col justify-start sm:w-full mt-8 gap-8 md:gap-5 w-[68%] mb-10">
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" />}
                    name="selectcategory"
                    placeholder="Select category"
                    options={dropDownOptions}
                    className="w-[32%] md:w-full gap-px border-gray-700 border border-solid"
                    
                  />
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" />}
                    name="selectcategory"
                    placeholder="Select category"
                    options={dropDownOptions}
                    className="w-[32%] md:w-full gap-px border-gray-700 border border-solid"
                  />
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" />}
                    name="selectcategory"
                    placeholder="Select category"
                    options={dropDownOptions}
                    className="w-[32%] md:w-full gap-px border-gray-700 border border-solid"
                  />
                </div>
                <Table />
                <Button onClick={handlenext}
                  color="cyan_600"
                  size="sm"
                  variant="fill"
                  leftIcon={<Img src="images/img_arrowright.svg" alt="arrow_right" />}
                  className="mt-8 gap-2.5 sm:px-5 w-[136px] md:w-[116px] rounded-[18px] "
                >
                  Next
                </Button>
              </div>
            </div>
          
         
          </div>
       

    </>
  );
}
