import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import defaultProfileImage from '../../assets/images/gorupplaceholder.png';
const ChatListItem = ({ chat }) => {
  const navigate = useNavigate();

  const handleChatScreen = () => {
    navigate('/ChatScreen', { state: { chat } }); 
  };
  const imageUrl =  chat.groupdata.groupprofile_img
  ? `https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${ chat.groupdata.groupprofile_img}`
  : defaultProfileImage;

  // console.log(chat.file_url)
  return (
    <div
      className="flex items-center justify-center p-4 cursor-pointer mb-2 bg-[#FFFCEE]"
      onClick={handleChatScreen}
      style={{
        width: '95%',
        border: '1px solid #c3c3c3',
        alignSelf: 'center',
        borderRadius: '12px',
        height: '60px',
      }}
    >
      <div
        className="flex-shrink-0 w-12 h-12 flex items-center justify-center text-2xl"
        style={{
          backgroundColor: '#D9D9D9',
          border: '2px solid #c3c3c3',
          borderRadius: '10px',
          display:'flex',
          justifyContent:'center',
          alignItems:'center' 
        }}
      >
       {/* <img src={ chat.groupdata.groupprofile_img === undefined ? require('../../assets/images/gorupplaceholder.png') :`https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${chat.groupdata.groupprofile_img}`} style={{ resize:'both', height:"10px"}}  /> */}
       <img
          src={imageUrl}
          alt="Profile"
          style={{ resize: "both"  }}
        />
      </div>
      <div className="ml-4 flex-1">
        <div className="font-bold text-gray-800">{chat.groupdata.groupname}</div>
        {chat.members && <div className="text-sm text-gray-600">{chat.members}</div>}
        {chat.groupdata.last_messageingroup && (
          <div className="text-sm text-gray-600">{chat.groupdata.last_messageingroup}</div>
        )}
      </div>
      <div className="ml-4 text-right">
        <div className="text-sm text-gray-600">{chat.time}</div>
        <div className="flex flex-row gap-2">
          {chat.mention && (
            <div className="text-sm text-white text-[#c3c3c3] rounded-full w-4 h-4 flex items-center justify-center">
              {chat.mention}first
            </div>
          )}
          {chat.unread && (
            <div className="text-sm text-white bg-[#c3c3c3] text-[#FFFFFF] rounded-full w-4 h-4 flex items-center justify-center">
              {chat.unread}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ChatListItem.propTypes = {
  chat: PropTypes.shape({
    groupdata: PropTypes.shape({
      groupname: PropTypes.string.isRequired,
      last_messageingroup: PropTypes.string,
    }).isRequired,
    members: PropTypes.string,
    time: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    unread: PropTypes.number,
    mention: PropTypes.number,
  }).isRequired,
};

export default ChatListItem;
