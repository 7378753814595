import React from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-[10px]",
  square: "rounded-[0px]",
};
const variants = {
  fill: {
    blue_gray_100_26: "bg-blue_gray-100_26 text-cyan-600",
    cyan_600: "bg-cyan-600 text-gray-200",
    light_green_400: "bg-light_green-400",
  },
  outline: {
    light_green_400: "border-light_green-400 border-2 border-solid text-gray-700",
    gray_500: "border-gray-500 border-t border-b border-solid text-black-900",
  },
};
const sizes = {
  lg: "h-[57px] px-1.5",
  xl: "h-[96px] px-5 text-xl",
  sm: "h-[37px] px-[22px] text-lg",
  xs: "h-[21px] px-[7px]",
  md: "h-[40px] px-[35px] text-base",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  variant = "outline",
  size = "md",
  color = "gray_500",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex items-center justify-center text-center cursor-pointer ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["round", "square"]),
  size: PropTypes.oneOf(["lg", "xl", "sm", "xs", "md"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf(["blue_gray_100_26", "cyan_600", "light_green_400", "gray_500"]),
};

export { Button };
