import React from 'react';

const TableComponent = () => {
  const tableStyle = {
    width: '90%',
    borderCollapse: 'collapse',
  };

  const thStyle = {
    backgroundColor: '#00A5CF',
    color: 'white',
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left',
  };

  const tdStyle = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left',
  };

  return (
    <table style={tableStyle}>
      <thead>
        <tr>
          <th style={thStyle}>Name</th>
        
          <th style={thStyle}>Designation</th>
          <th style={thStyle}>Location</th>

          <th style={thStyle}>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={tdStyle}>John Doe</td>
      
          <td style={tdStyle}>Manager</td>
          <td style={tdStyle}>New York</td>
          
          <td style={tdStyle}>Edit/Delete</td>
        </tr>
        <tr>
          <td style={tdStyle}>Jane Smith</td>
         
          <td style={tdStyle}>Developer</td>
          <td style={tdStyle}>San Francisco</td>

          <td style={tdStyle}>Edit/Delete</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableComponent;
