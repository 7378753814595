import React from 'react';

const GroupMemberCard = ({ members }) => {
  return (
    <div style={{ marginTop: '20px', backgroundColor: '#FFFCEE', padding: '10px', borderRadius: '10px', maxHeight: '300px', overflowY: 'auto' }}>
      {members.map((member, index) => (
        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0', borderBottom: '1px solid #ccc' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={member.usersdata?.userdata?.avatarUrl || 'default_avatar_url'} alt="User Avatar" style={{ width: 50, height: 50, borderRadius: '50%' }} />
            <span style={{ marginLeft: '10px' }}>{member.usersdata?.custom_username}</span>
          </div>
          <span>user</span>
        </div>
      ))}
    </div>
  );
};

export default GroupMemberCard;
  