import React, { useEffect, useState } from "react";

import { Button, Img, Input, Text } from "components";
import { Navigate, useNavigate } from "react-router-dom";
import PricingchartPage from "pages/Pricingchart";
import { useMediaQuery } from "@mui/material";
import { gql, useMutation } from "@apollo/client";

const INSERT_ORG_DETAIL = gql`
mutation MyMutation($name: String = "", $enquired_by: String = "", $employee_count: bigint = "") {
  insert_organisation_master(objects: {name: $name, enquired_by: $enquired_by, employee_count: $employee_count}) {
    returning {
      enquired_by
      id
    }
  }
}
`;

const EnquiryformOnePage = () => {
  const [errors, setErrors] = useState({
    enquire: '',
    name: '',
    count: '',
    // purpose: '',
  });
  
  const [data, setData] = useState({});

  const handleChange = (key, value) => {
    console.log(key, value);

    
    setData(prevData => ({
        ...prevData,
        [key]: value
    }));

    // Check if there's an existing error for the field and clear it
    if (errors[key]) {
        setErrors(prevErrors => ({
            ...prevErrors,
            [key]: null  
        }));
    }
}



  const generateLoremText= () => {
    return "Lorem ipsum dolor sit amet, consectetur adipiscing elit...";
  };

  const navigate = useNavigate();
  
  const [InsertOrgDetail] = useMutation(INSERT_ORG_DETAIL, {
    onCompleted: (data) => {
      console.log("some data updated",data.id);
      localStorage.setItem("id", data.insert_organisation_master.returning[0].id);

     
      // nextuser()
        
    },
 
    onError: (error) => {
      console.log("error updating profile : ", error);
    },
  });

  const handleSubmit =async (id) => {
    setErrors({ enquire: '', name: '', count: '', purpose: '' });

    let newErrors = {};
    if (!data.enquire) newErrors.enquire = "Full name is required";
    if (!data.name) newErrors.name = "Organisation name is required";
    if (!data.count) newErrors.count = "Number of users is required";
    // if (!data.purpose) newErrors.purpose = "Purpose is required";
  
    if (Object.keys(newErrors).length > 0) {
      // Set errors if there are any
      setErrors(newErrors);
      return; // Prevent form submission
    }
  
    try {
      const { data: insertedData } = await InsertOrgDetail({
        variables: {
          enquired_by:data["enquire"],
          name: data["name"],
          employee_count:data["count"],
          
       
        },
      });
      console.log("inserted group header info", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }

   
    // Your form submission logic
    // After form submission, navigate to the pricing page
    navigate("/pricingchart");

  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 960);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };


  return (  
    <>
      <div className="bg-white-A700 flex flex-col font-poppins items-center justify-start mx-auto p-[34px] sm:px-5 w-full">
     
     
        <div sclassName="flex flex-col md:gap-10 gap-[69px] items-center justify-start max-w-[1444px] mb-[98px] mx-auto md:px-5 md:mb-[30px]  w-full">
        {!isMobile && ( 
        <div  className="flex md:flex-col flex-row md:gap-5 items-center justify-start w-full">
           
          <div  className=" flex md:flex-1 flex-row gap-6 items-center justify-start w-[18%] md:w-full">
              <Img
                className="h-[43px] md:h-auto object-cover w-[43px]"
                src="images/img_convenelogo.png"
                alt="convenelogo"
              />
              <Text
              className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700 flex-shrink-0"
              size="txtPoppinsSemiBold25"
            >
              Convene chat
            </Text>
            </div>
            <Text
              className="md:ml-[0] ml-[556px] text-gray-700 text-lg"
              size="txtPoppinsRegular18"
            >
              Demo
            </Text>
            <Text
              className="ml-16 md:ml-[0] text-gray-700 text-lg"
              size="txtPoppinsRegular18"
            >
              Pricing
            </Text>
            <Text
              className="ml-16 md:ml-[0] text-gray-700 text-lg"
              size="txtPoppinsRegular18"
            >
              Features
            </Text>
            <a
              href="javascript:"
              className="md:ml-[0] ml-[68px] text-gray-700 text-lg"
            >
              <Text size="txtPoppinsRegular18">FAQs</Text>
            </a>
            <a
              href="/#/login"
              className="bg-cyan-600 h-[37px] justify-center mb-1.5 md:ml-[0] ml-[70px] pb-0.5 pt-[7px] sm:px-5 px-[34px] rounded-[18px] text-gray-200 text-lg w-[150px]"
            >
              <Text size="txtPoppinsRegular18Gray200">Sign In</Text>
            </a>
            
          </div>
        )}
        {isMobile && (
          <div style={{display:'flex',justifyContent:"space-between",width:"98%",alignItems:"center"}}>
          <div style= {{display:"flex",justifyContent:"space-evenly",width:"50%",gap:"10px",alignItems:"center"}}>
      
        <Img
                className="h-[43px] md:h-auto object-cover w-[43px]"
                src="images/img_convenelogo.png"
                alt="convenelogo"
              />
              <Text
              className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700 flex-shrink-0"
              size="txtPoppinsSemiBold25"
            >
              Convene chat
            </Text>
            </div>
            <Img  onClick={toggleNav}
            className=" md:h-[15px] md:ml-[0] ml-[29px] object-cover  "
            src="images/Threeline.png"
            alt="idea"
          />
        </div>
        
        )}
        {isNavOpen && (
          <div
            style={{
              position: "absolute",
              top: 60,
              right: 0, 
              width: "30%", 
              backgroundColor: "#fef7e5",
             
              height: "40%", 
              display: "flex",
              flexDirection: "column",
              
            
             
              padding: "20px",
              boxSizing: "border-box",
              
            }}
          > <Text size="txtPoppinsRegular18">Demo</Text>
          <Text size="txtPoppinsRegular18">Pricing</Text>
          <Text size="txtPoppinsRegular18">Features</Text>
          <Text size="txtPoppinsRegular18">FAQs</Text>
          <Text size="txtPoppinsRegular18">Sign up</Text>
          </div>
        )}

        <div className="flex md:flex-col flex-row md:gap-10 items-start justify-between w-[95%] md:w-full" >
      
          <div style={{ display: isMobile ? "block" : "none",width:"60%",alignItems:"center",justifyContent:"center",alignSelf:"center",color:"#00A5CF",marginTop:"15px"}}>
        <p style={{displya:"flex",width:"100%",fontSize:'22px',fontWeight:"bold",textAlign:"center"}}>Join the all in convene chat!</p>
      </div>
      {!isMobile && (
            <div className="flex flex-col gap-[30px] items-center justify-start w-2/5 md:w-full">
              <div className="flex flex-row items-end justify-between w-[92%] md:w-full">
                <div className="md:h-[151px] h-[170px] mb-[3px] relative w-[26%]">
                  <Img
                    className="absolute h-[148px] left-[0] object-cover top-[0] w-[84%]"
                    src="images/img_success.png"
                    alt="success"
                  />
                  <Text
                    className="absolute bottom-[0] right-[0] text-center text-gray-700 text-xl"
                    size="txtPoppinsRegular20"
                  >
                    Fair pricing
                  </Text>
                </div>
                <div className="flex flex-col justify-start mt-2 w-[37%]">
                  <Img
                    className="h-[140px] md:h-auto md:ml-[0] ml-[29px] object-cover w-[58%] sm:w-full"
                    src="images/img_idea.png"
                    alt="idea"
                  />
                  <Text
                    className="text-center text-gray-700 text-xl"
                    size="txtPoppinsRegular20"
                  >
                    Build community
                  </Text>
                </div>
              </div>
            
              <div className="flex flex-col gap-[33px] items-center justify-start w-full">
                <div className="flex flex-row items-center justify-between w-[98%] md:w-full">
                  <div className="md:h-[146px] h-[154px] relative w-[37%]">
                    <Img
                      className="absolute h-[146px] left-[24%] object-cover top-[0] w-[49%]"
                      src="images/img_newitem.png"
                      alt="newitem"
                    />
                    <Text
                      className="absolute bottom-[0] inset-x-[0] mx-auto text-center text-gray-700 text-xl w-max"
                      size="txtPoppinsRegular20"
                    >
                      Convene connect
                    </Text>
                  </div>
                  <div className="md:h-[137px] h-[155px] mt-1 relative w-[34%]">
                    <Img
                      className="absolute h-[133px] left-[10%] object-cover top-[0] w-[70%]"
                      src="images/img_search.png"
                      alt="search"
                    />
                    <Text
                      className="absolute bottom-[0] inset-x-[0] mx-auto text-center text-gray-700 text-xl w-max"
                      size="txtPoppinsRegular20"
                    >
                      Dynamic search
                    </Text>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <div className="h-44 md:h-[170px] relative w-[35%]">
                    <Img
                      className="absolute h-[170px] inset-[0] justify-center m-auto object-cover w-[64%]"
                      src="images/img_settings.png"
                      alt="settings"
                    />
                    <Text
                      className="absolute bottom-[0] inset-x-[0] mx-auto text-center text-gray-700 text-xl w-max"
                      size="txtPoppinsRegular20"
                    >
                      Channel building
                    </Text>
                  </div>
                  <div className="md:h-40 h-44 relative w-[38%]">
                    <Img
                      className="absolute h-40 left-[9%] object-cover top-[0] w-[73%]"
                      src="images/img_newletter.png"
                      alt="newletter"
                    />
                    <Text
                      className="absolute bottom-[0] inset-x-[0] mx-auto text-center text-gray-700 text-xl w-max"
                      size="txtPoppinsRegular20"
                    >
                      Secure messaging
                    </Text>
                 
                  </div>
                </div>
              </div>
              </div>
              )}
              <>
              {isMobile && (
                <div style={{ display: 'flex', flexDirection: 'column',   width: '100%',     }}>
                
                <div style={{ display: 'flex', flexDirection: 'row',   width: '100%',    }}>
 
   
      <div style={{display:"flex",  maxWidth: '40%',flexDirection:"column",    }}>
        <img
          style={{ height: '120px', width: '285px'  }}
          src="images/img_newletter.png"
          alt="success"
        />
        <span
          style={{  color: '#707070', fontSize: '16px', width:"100%" }}
        >
     
        </span>
      </div>
      <div style={{ maxWidth: '70%', marginLeft: '30px',alignItems:"center",alignSelf:"center" }}>
        <span
          style={{ textAlign: 'center', color: '#707070', fontSize: '14px',width:"50%" }}
        >
          {generateLoremText()}
        </span>
      
    </div>
    {/* Add similar code for other images and descriptions */}
  </div>
      
  <div style={{ display: 'flex', flexDirection: 'row',   width: '100%',    }}>
  <div style={{ maxWidth: '70%', marginLeft: '30px',alignItems:"center",alignSelf:"center" }}>
  <span
    style={{ textAlign: 'center', color: '#707070', fontSize: '14px',width:"50%" }}
  >
    {generateLoremText()}
  </span>

</div>
 
   
  <div style={{display:"flex",  maxWidth: '40%',flexDirection:"column",    }}>
    <img
      style={{ height: '120px', width: '285px'  }}
      src="images/img_idea.png"
      alt="success"
    />
    <span
      style={{  color: '#707070', fontSize: '16px', width:"100%" }}
    >
      Build Community
    </span>
  </div>
 
{/* Add similar code for other images and descriptions */}
</div>
      
<div style={{ display: 'flex', flexDirection: 'row',   width: '100%',    }}>
 
   
<div style={{display:"flex",  maxWidth: '40%',flexDirection:"column",    }}>
  <img
    style={{ height: '120px', width: '285px'  }}
    src="images/img_newitem.png"
    alt="success"
  />
  <span
    style={{  color: '#707070', fontSize: '16px', width:"100%" }}
  >
   convene connect
  </span>
</div>
<div style={{ maxWidth: '70%', marginLeft: '30px',alignItems:"center",alignSelf:"center" }}>
  <span
    style={{ textAlign: 'center', color: '#707070', fontSize: '14px',width:"50%" }}
  >
    {generateLoremText()}
  </span>

</div>
{/* Add similar code for other images and descriptions */}
</div>
      
<div style={{ display: 'flex', flexDirection: 'row',   width: '100%',    }}>
<div style={{ maxWidth: '70%', marginLeft: '30px',alignItems:"center",alignSelf:"center" }}>
<span
  style={{ textAlign: 'center', color: '#707070', fontSize: '14px',width:"50%" }}
>
  {generateLoremText()}
</span>

</div>
   
<div style={{display:"flex",  maxWidth: '40%',flexDirection:"column",    }}>
  <img
    style={{ height: '120px', width: '285px'  }}
    src="images/img_search.png"
    alt="success"
  />
  <span
    style={{  color: '#707070', fontSize: '16px', width:"100%" }}
  >
   Dyanamic search
  </span>
</div>

{/* Add similar code for other images and descriptions */}
</div>
      
<div style={{ display: 'flex', flexDirection: 'row',   width: '100%',    }}>
 
   
<div style={{display:"flex",  maxWidth: '40%',flexDirection:"column",    }}>
  <img
    style={{ height: '120px', width: '285px'  }}
    src="images/img_settings.png"
    alt="success"
  />
  <span
    style={{  color: '#707070', fontSize: '16px', width:"100%" }}
  >
Channel Building
  </span>
</div>
<div style={{ maxWidth: '70%', marginLeft: '30px',alignItems:"center",alignSelf:"center" }}>
  <span
    style={{ textAlign: 'center', color: '#707070', fontSize: '14px',width:"50%" }}
  >
    {generateLoremText()}
  </span>

</div>
{/* Add similar code for other images and descriptions */}
</div>
      
<div style={{ display: 'flex', flexDirection: 'row',   width: '100%',    }}>
 
   
<div style={{display:"flex",  maxWidth: '40%',flexDirection:"column",    }}>
  <img
    style={{ height: '120px', width: '285px'  }}
    src="images/img_success.png"
    alt="success"
  />
  <span 
    style={{  color: '#707070', fontSize: '16px', width:"100%" }}
  >
  Secure messaging
  </span>
</div>
<div style={{ maxWidth: '70%', marginLeft: '30px',alignItems:"center",alignSelf:"center" }}>
  <span
    style={{ textAlign: 'center', color: '#707070', fontSize: '14px',width:"50%" }}
  >
    {generateLoremText()}
  </span>

</div>
{/* Add similar code for other images and descriptions */}
</div>
  </div>
              )}
            </>
            
            <div className="flex flex-col gap-[25px] items-center justify-start md:mt-0 mt-[109px] w-[50%] md:w-full">
              <div className="bg-white-A700 border-2 border-gray-700 border-solid flex flex-col items-center justify-end p-7 sm:px-5 rounded-[40px] w-full">
                <div className="flex flex-col items-start justify-start mt-2 w-[93%] md:w-full">
                  <Text
                    className="text-cyan-600 text-lg"
                    size="txtPoppinsRegular18Cyan600"
                  >
                    Full Name
                  </Text>
                  <input
                  value={data.enquire}
                  onChange={(e) => handleChange('enquire', e.target.value)}
                  placeholder=""
                  className="p-1.5 w-full border border-gray-700 border-solid rounded-[9px]"
                  wrapClassName="flex h-10 mt-2 w-full "
                ></input>
                {errors.enquire && <p className="text-red-500 text-sm">{errors.enquire}</p>}
                  <Text
                    className="mt-[26px] text-cyan-600 text-lg"
                    size="txtPoppinsRegular18Cyan600"
                  >
                    Organisation Name
                  </Text>
                  <input
                  value={data.name}
                  onChange={(e) => handleChange('name', e.target.value)}
                  placeholder=""
                  className="p-1.5 w-full border border-gray-700 border-solid rounded-[9px]"
                  wrapClassName="flex h-10 mt-2 w-full "
                ></input>
                  {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                  <div className="flex flex-row md:flex-col items-start justify-between mt-[23px] w-[100%] md:w-full" >
                  <div className="flex flex-col w-[48%] ">
                    <Text
                      className="mb-0.5 text-cyan-600 text-lg"
                      size="txtPoppinsRegular18Cyan600"
                    >
                      Number of users
                    </Text>
                    <input
                  value={data.count}
                  onChange={(e) => handleChange('count', e.target.value)}
                  placeholder=""
                  className="p-1.5  border border-gray-700 border-solid rounded-[9px]"
                  wrapClassName="flex h-10 mt-2 "
                ></input>
                  {errors.count && <p className="text-red-500 text-sm">{errors.count}</p>}
                  </div>
                  <div className="flex flex-col  md:mt-5 w-[48%] ">
                    <Text
                      className="mb-0.5 text-cyan-600 text-lg"
                      size="txtPoppinsRegular18Cyan600"
                    >
                      Purpose
                    </Text>
                    <input
               
                  placeholder=""
                  className="p-1.5 w-full border border-gray-700 border-solid rounded-[9px]"
                  wrapClassName="flex h-10 mt-2  "
                ></input>
                  </div>
                </div>
                
                  <button
                  onClick={handleSubmit}
                    className="cursor-pointer h-[37px] leading-[normal] md:ml-[0] ml-[439px] mt-[30px] text-center text-lg w-[136px]"
                   
             style={{  backgroundColor: '#00A5CF', 
              border: 'none',            
                       
              borderRadius:"20px",
                           
                          
              color: 'white',             
              cursor: 'pointer',           
              outline: 'none'  }}
                    shape="round"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <Text
                className="text-base text-gray-700"
                size="txtPoppinsRegular16"
              >
                <span className="text-gray-700 font-poppins text-left font-normal">
                  Want someone from the team to connect with you?{" "}
                </span>
                <span className="text-light_green-400 font-poppins text-left font-normal">
                  Click here
                </span>
              </Text>
              </div>
              </div>
              </div>
           
              </div> 
            
              </>
  );
};

export default EnquiryformOnePage;




// import React from "react";

// import { Img, Line,  Text } from "components";
// import { Radio, TextField } from "@mui/material";
// import { useNavigate } from "react-router";
// import Box from '@mui/material/Box';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import StepContent from '@mui/material/StepContent';
// import Button from '@mui/material/Button';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';

// const BrowserloginOnePage = () => {
//   const navigate = useNavigate();

//   const handleSubmit = () => {
//     console.log("Attempting to navigate");
//     // Your form submission logic
//     // After form submission, navigate to the pricing page
//     navigate("/pricingchartdetails");
//   };

//   const [activeStep, setActiveStep] = React.useState(0);

//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleReset = () => {
//     setActiveStep(0);
//   };

//   const steps = [
//     {
//       label: 'Public Information',
//       content: (
//         <form onSubmit={handleSubmit}>
//         <div style={{ display: 'flex', alignItems: 'center',marginBottom: '20px' }}>
//         <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
//           Organisation Name:
//         </label>
//         <TextField id="organization" variant="standard" />
//       </div>
          
//       <div style={{ display: 'flex', alignItems: 'center',marginBottom: '20px'  }}>
//       <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
//         Country:
//       </label>
//       <TextField id="organization" variant="standard" />
//     </div>

//     <div style={{ display: 'flex', alignItems: 'center',marginBottom: '20px'  }}>
//     <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
//       Industry Type:
//     </label>
//     <TextField id="organization" variant="standard" />
//   </div>
    
//   <div style={{ display: 'flex', alignItems: 'center',marginBottom: '20px'  }}>
//   <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
// Official Website:
//   </label>
//   <TextField id="organization" variant="standard" />
// </div>
  
// <div style={{ display: 'flex', alignItems: 'center',marginBottom: '20px'  }}>
// <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
//   Tax no:
// </label>
// <TextField id="organization" variant="standard" />
// </div>


// <div style={{ display: 'flex', alignItems: 'center',marginBottom: '20px'  }}>
// <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
//   Employee count:
// </label>
// <TextField id="organization" variant="standard" />
// </div>

//         </form>
//       )
//     },
//     {
//       label: 'Review plan',
//       content:(
//         <div className="flex flex-row md:flex-col w-full ">
//         <div className="w-72 h-60 border-2 border-black rounded-lg p-8 text-center">
//           {/* First card content */}
//         </div>
//         <div className="flex flex-col w-full ml-8">
//           <div className="flex flex-col w-full">
//             <h2 className="text-green-600 text-xl font-bold mb-4">Plan Details</h2>
//             <div className="flex flex-row w-full">
//               <p className="w-1/4 mb-4">Renewal Date:</p>
//               <p className="mb-4">31 January 2014</p>
//             </div>
//             <div className="flex flex-row w-full">
//               <p className="w-1/4 mb-4">Number of Users:</p>
//               <p className="w-2/3 mb-4">594 users</p>
//             </div>
//             <div className="flex flex-row w-full">
//               <p className="w-1/4">Inclusive Features:</p>
//               <div className="flex flex-row">
//                 <div className="flex flex-col">
//                   <li className="mb-2">Video calling</li>
//                   <li className="mb-2">Messaging</li>
//                   <li className="mb-2">Channel Management</li>
//                 </div>
//                 <div className="flex flex-col ml-8">
//                   <li className="mb-2">Channel Building</li>
//                   <li className="mb-2">Admin Control</li>
//                   <li className="mb-2">Theme Changing</li>
//                 </div>
//               </div>
//             </div>
//             <div className="flex flex-row w-full">
//             <button className="bg-blue-500 bg-blue-700 text-white font-bold py-2 px-4 rounded-md mt-10">verify</button>

       
//           </div>
//           </div>
          
//         </div>
        
//       </div>
      
      
//       )
//     },
//     {
//       label: 'Owner login Credintials',
// content:(

//   <div className="flex flex-col items-start justify-start w-[98%] md:w-full">
//   <div className="flex md:flex-col flex-row md:gap-5 items-center justify-start w-full">
//   <Text
//   className="text-gray-700 text-xl"
//   size="txtPoppinsRegular20"
// >
//   User id
// </Text>
// <div className="md:h-[30px] h-[31px] md:ml-[0] ml-[100px] relative w-[26%] md:w-full">
//   <div className="absolute bg-blue_gray-100 h-[30px] inset-x-[0] mx-auto top-[0] w-full"></div>
//   <TextField
//   className="absolute bottom-[0] left-[2%] text-gray-700 text-xl"
//     size="txtPoppinsRegular20"
//     defaultValue="auroradivya@yahoo.com"
//     variant="standard"
//     InputProps={{
//       disableUnderline: true,
//       style: { background: "transparent" },
//     }}
//   />
// </div>
// <div className="bg-cyan-600 h-2.5 ml-6 md:ml-[0] md:mt-0 my-[19px] rounded-[50%] w-2.5"></div>
// <Text
//   className="sm:flex-1 ml-8 md:ml-[0] text-base text-cyan-600 w-[54%] sm:w-full"
//   size="txtPoppinsRegular16"
// >
//   This email will be used as recovery email address and related
//   activities such as subscription, deleting account, etc
// </Text>
// </div>
// <div className="flex sm:flex-col flex-row sm:gap-10 gap-[73px] items-end justify-start mt-[3px] w-[38%] md:w-full">
// <Text
//   className="sm:mt-0 mt-1.5 text-gray-700 text-xl"
//   size="txtPoppinsRegular20"
// >
//   Password
// </Text>
// <div className="h-8 md:h-[34px] mb-1 relative w-[59%] sm:w-full">
//   <div className="absolute bg-blue_gray-100 h-[30px] inset-x-[0] mx-auto top-[0] w-full"></div>
//   <TextField
//     className="absolute bottom-[0] left-[2%] text-gray-700 text-xl"
//     size="txtPoppinsRegular20"
//     defaultValue="gf#jooo980@01!"
//     variant="standard"
//     InputProps={{
//       disableUnderline: true,
//       style: { background: "transparent" },
//     }}
//   />
// </div>
// </div>  <Text
//     className="mt-[27px] text-gray-700 text-lg"
//     size="txtPoppinsRegular18"
//   >
//     Please log in again and change your default password.
//   </Text>
//   <Text
//     className="mt-4 text-gray-700 text-lg"
//     size="txtPoppinsRegular18"
//   >
//     <span className="text-gray-700 font-poppins text-left font-normal">
//       Click here to
//     </span>
//     <span className="text-gray-700 font-poppins text-left font-normal">
//       {" "}
//     </span>
//     <span className="text-cyan-600 font-poppins text-left font-normal">
//       Login
//     </span>
//   </Text>
// </div>


// )

//     },
//   ];

//   return (
//     <>
//       <div className="bg-white-A700 flex flex-col font-poppins items-center justify-end mx-auto p-[31px] sm:px-5 w-full">
//         <div className="flex flex-col justify-start max-w-[1444px] mt-0.5 mx-auto md:px-5 w-full">
//           <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start w-full">
//             {/* Left Side */}
//             <div className="flex md:flex-1 flex-row gap-6 items-center justify-start w-[18%] md:w-full">
//               <Img
//                 className="h-[43px] md:h-auto object-cover w-[43px]"
//                 src="images/img_convenelogo.png"
//                 alt="convenelogo"
//               />
//               <Text
//                 className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700"
//                 size="txtPoppinsSemiBold25"
//               >
//                 Convene chat
//               </Text>
//             </div>
//             {/* Nav Items */}
//             <div className="flex items-center">
//               <Text
//                 className="md:ml-[0] ml-[556px] text-gray-700 text-lg"
//                 size="txtPoppinsRegular18"
//               >
//                 Demo
//               </Text>
//               <Text
//                 className="ml-16 md:ml-[0] text-gray-700 text-lg"
//                 size="txtPoppinsRegular18"
//               >
//                 Pricing
//               </Text>
//               <Text
//                 className="ml-16 md:ml-[0] text-gray-700 text-lg"
//                 size="txtPoppinsRegular18"
//               >
//                 Features
//               </Text>
//               <a
//                 href="javascript:"
//                 className="md:ml-[0] ml-[68px] text-gray-700 text-lg"
//               >
//                 <Text size="txtPoppinsRegular18">FAQs</Text>
//               </a>
//               <a
//                 href="javascript:"
//                 className="bg-cyan-600 h-[37px] justify-center mb-1.5 md:ml-[0] ml-[70px] pb-0.5 pt-[7px] sm:px-5 px-[35px] rounded-[18px] text-gray-200 text-lg w-[136px]"
//               >
//                 <Text size="txtPoppinsRegular18Gray200">Log in</Text>
//               </a>
//             </div>
//           </div>
//           <Text
//             className="mt-[59px] text-3xl sm:text-[26px] md:text-[28px] text-cyan-600"
//             size="txtPoppinsSemiBold30"
//           >
//             Welcome to Convene chat!
//           </Text>
//           <Text
//             className="mt-2.5 text-2xl md:text-[22px] text-light_green-400 sm:text-xl"
//             size="txtPoppinsRegular24"
//           >
//             Please help us set up your organisation
//           </Text>

//           <Box sx={{}}>
//             <Stepper activeStep={activeStep} orientation="vertical">
//               {steps.map((step, index) => (
//                 <Step key={step.label}>
//                   <StepLabel
//                     optional={
//                       index === 2 ? (
//                         <Typography variant="caption">Last step</Typography>
//                       ) : null
//                     }
//                   >
//                     {step.label}
//                   </StepLabel>
//                   <StepContent>
//                     {step.content}
//                     <Box sx={{ mb: 2 }}>
//                       <div>
//                         <Button
                      
                      
//                           onClick={handleNext}
//                           sx={{ mt: 1, mr: 1,fontSize: 10 }}
//                         >
//                           {index === steps.length - 1 ? 'Finish' : 'Next'}
//                         </Button>
                        
//                       </div>
//                     </Box>
//                   </StepContent>
//                 </Step>
//               ))}
//             </Stepper>
//             {activeStep === steps.length && (
//               <Paper square elevation={0} sx={{ p: 3 }}>
//                 <Typography>All steps completed - you&apos;re finished</Typography>
//                 <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//                   Reset
//                 </Button>
//               </Paper>
//             )}
//           </Box>
//         </div>
//       </div>
//     </>
//   );
// };

// export default BrowserloginOnePage;
