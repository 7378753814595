import React, { useState } from 'react';
import LinearWithValueLabel from '../../pages/MobileScreen/ProgressBar';
import { Img, Text } from 'components';
import { useNavigate } from 'react-router';
import { TextField } from '@mui/material';

export default function Payment() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navigate = useNavigate();

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const [showLoginInfo, setShowLoginInfo] = useState(false);

   
    const accessControlNames = ['Access Control 1', 'Access Control 2', 'Access Control 3'];
    const roles = [
      { id: 1, name: 'Admin', description: 'Administrator role', accessControl: 'Full access' },
      { id: 2, name: 'User', description: 'Regular user role', accessControl: 'Limited access' },
      // Add more role data as needed
    ];
    const handleNext = (event) => {
        event.preventDefault();
        
        navigate("/userinfo");
    };
    return (
        <>
            <div className="bg-white-A700 flex flex-col font-poppins items-center justify-start mx-auto p-[34px] sm:px-5 w-full  " style={{ height: "35vh" }} >
                <div className="flex flex-col items-center justify-start max-w-[1444px] mb-5 mx-auto md:px-5 w-full">
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center',marginBottom:15 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '50%', gap: '10px', alignItems: 'center' }}>
                            <Img
                                className="h-[43px] md:h-auto object-cover w-[43px]"
                                src="images/img_convenelogo.png"
                                alt="convenelogo"
                            />
                            <Text
                                className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700 flex-shrink-0"
                                size="txtPoppinsSemiBold25"
                            >
                                Convene chat
                            </Text>
                        </div>
                        <Img
                            onClick={toggleNav}
                            className="md:h-[15px] md:ml-[0] ml-[29px] object-cover"
                            src="images/Threeline.png"
                            alt="idea"
                        />
                        {isNavOpen && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: 60,
                                    right: 0,
                                    width: "30%",
                                    background: "white",
                                    height: "40%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignContent: 'center',
                                    padding: "20px",
                                    boxSizing: "border-box",
                                }}
                            >
                                <Text size="txtPoppinsRegular18">Demo</Text>
                                <Text size="txtPoppinsRegular18">Pricing</Text>
                                <Text size="txtPoppinsRegular18">Features</Text>
                                <Text size="txtPoppinsRegular18">FAQs</Text>
                                <Text size="txtPoppinsRegular18">Sign up</Text>
                            </div>
                        )}
                    </div>
                    <div style={{ display: "flex", width: "50%", alignItems: "center", justifyContent: "center", alignSelf: "center", color: "#00A5CF",marginBottom:"30px" }}>
                        <p style={{ fontSize: '22px', fontWeight: "bold", textAlign: "center" }}>Create User profiles</p>
                    </div>
                  
                    <div style={{ display: "flex", alignItems: "left", justifyContent: "left", alignSelf: "left", width: "100%" }}>
                    <LinearWithValueLabel initialValue={50} />
                    </div>
                </div>
                <text style={{display:"flex",alignItems:"left",width:"100%",marginBottom:'20px',color:"#00A5CF"}}>create rolls</text>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem',width:"100%",alignItems:"left",marginBottom:10 }}>
                    <label htmlFor="adminName">Roll Name:</label>
                    <TextField
                        id="adminName"
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            style: {height:25, background: 'transparent', backgroundColor: "#D9D9D9", marginLeft: 5 },
                        }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem',width:"100%",alignItems:"left",marginBottom:20  }}>
                    <label htmlFor="adminEmail" style={{width:"50%"}}>Roll Description:</label>
                    <TextField
                        id="adminEmail"
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            style: {height:25, background: 'transparent', backgroundColor: "#D9D9D9", marginLeft: 5 },
                        }}
                    />
                    </div> 
                     
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%',marginBottom:"20px" }}>
    
    <div style={{ width: '40%', display: 'flex', alignItems: '', marginBottom: 20,}}>
        <h2 style={{ }}>Access Control</h2>
    </div>
        {accessControlNames.map((control, index) => (
          <div key={index} style={{ width: '50%', display: 'flex', alignItems: 'left', marginBottom: '5px' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: '#D9D9D9', marginRight: '5px' }}></div>
            <Text>{control}</Text>
          </div>
        ))}
        
      </div> 
      <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem',width:"100%" }}>
      <button
          style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              borderRadius: '25px',
              backgroundColor: "#00A5CF",
              width: '40%',
              height: '30px'
          }}
          onClick={(e) => handleNext(e)}
      >
          <img src="images/forward.png" alt="arrow" style={{ marginRight: '5px' }} />
          <Text style={{ fontSize: "12px" }}>Next</Text>
      </button>
      <button
          style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              borderRadius: '25px',
              border: '1px solid #8CB369',
              width: '40%',
              height: '30px'
          }}
      >
          <img src="images/tick.png" alt="tick" style={{ marginRight: '5px' }} />
          <Text style={{ fontSize: "12px" }}>Save Stage</Text>
      </button>
  </div> 
      
            </div>
          

        </>
    );
}
