import React, { useState } from 'react';
import { Img, Text } from "components";
import { Heading } from "components/Heading";
import { gql, useQuery } from '@apollo/client';

const FETCH_COUNT_DETAIL = gql`
query MyQuery($_eq: bigint = "", $_eq1: bigint = "") {  
    group_usermapping_aggregate(where: {group_id: {_eq: $_eq}, orgn_id: {_eq: $_eq1}}) {
      aggregate {
        count
      }
    }
}
`;

function GroupList(props) {
    const [counts, setCounts] = useState();
    
    const { loading, error, data } = useQuery(FETCH_COUNT_DETAIL, {
        variables: {
            _eq: props.group.id,
            _eq1: localStorage.getItem('id'),
        },
        onCompleted: (data) => {
            setCounts(data.group_usermapping_aggregate.aggregate.count);
        },
        onError: (error) => {
            console.error('Error fetching count details:', error);
        }
    });
    

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
      
        <div className="flex flex-col w-[75%] md:w-full gap-10 w-1/3 lg:w-1/3 xl:w-1/4 p-2">
        <div className="flex flex-row md:flex-col justify-start w-full gap-8 md:gap-5">
        
            <div    className="flex flex-row justify-start w-[100%] md:w-full">
              <div className="flex flex-col items-start justify-start w-full gap-[7px] border-orange-300 border-2 border-solid rounded-[12px]">
              <Img
                    src="images/img_rectangle_2794.png"
                    alt="image"
                    style={{ width: '100%', borderRadius: '10px 10px 0 0', objectFit: 'cover' }}
                />
                <div className="flex flex-col items-start justify-start w-[100%] md:w-full mb-3 ml-2 gap-[11px] md:ml-0">
                  <h3 className="text-lg">   {props.group?.groupname}</h3>
                  <div className="flex flex-row md:flex-col justify-start items-center gap-4">
                    <img
                      src="images/img_frame_386.png"
                      alt="image_one"
                      className="w-[52%] md:h-auto sm:w-full object-cover"
                    />
                    <p className="text-gray-400 font-normal"> {counts} Members</p>
                  </div>
                  <p className="text-sm text-gray-700 font-light">Last messaged on- </p>
                </div>
              </div>
            </div>
          
        </div>
      </div>
  
      
      
      
      
      
    );
}

export default GroupList;


