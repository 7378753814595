import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useUserEmail } from '@nhost/react';
export default function Verification() {
  const useremail = useUserEmail();
    const navigate = useNavigate();
    const [isOTPComplete, setIsOTPComplete] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    
    setIsNavOpen(!isNavOpen);
  };
  const [otp, setOTP] = useState(['', '', '', '']); 
  const generateOTP = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };
  async function sendemail(newgenratedotp) {
    try {
      const response = await fetch('https://ziuetfnxlyjlcvdiraet.functions.ap-south-1.nhost.run/v1/emailforotp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "emailid": useremail,"emailotp":newgenratedotp })
      });
  
      if (!response.ok) {
        throw new Error('Failed to send verification email');
      }
  
      console.log('Verification email sent successfully');
    } catch (error) {
      console.error('Error sending verification email:', error);
    }
  }
  const handleChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
    if (value === '' && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    } else if (index < 3 && value !== '') {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleSubmit = () => {
    console.log("Attempting to navigate");
    navigate("/Payment");
  }

  const isOtpFilled = otp.every(value => value !== '');

  return (
    <>
      <div style={{ width: "100%", display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: "column" }}>
        <text style={{ marginBottom: 10, width: "40%", fontSize: "18px", color: "#616161", marginBottom: 20 }}> OTP sent to your registered email address</text>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {otp.map((value, index) => (
            <input
              key={index}
              id={`otp-input-${index}`}
              type="text"
              maxLength="1"
              value={value}
              onChange={(e) => handleChange(index, e.target.value)}
              style={{ width: '40px', height: '40px', textAlign: 'center', fontSize: '12px', borderColor: value ? '#00A5CF' : '#C3C3C3', }}
            />
          ))}
        </div>

        {isOtpFilled && (
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px',
              borderRadius: '25px',
              backgroundColor: '#00A5CF',
              width: '150px',
              height: '40px',
              marginTop: '20px',
            }}
            onClick={handleSubmit}
          >
            <text style={{ color: "#EEEEEE", fontSize: "12px" }}>
              Proceed to Payment
            </text>
          </button>
        )}
      </div>
    </>
  );
}
