import { TextField } from '@mui/material'
import { Img, Text } from 'components'
import React, { useState } from 'react'
import Button from '@mui/material/Button';
import { useNhostClient, useSignUpEmailPassword, useUserId } from '@nhost/react';
import { gql, useQuery, useMutation } from "@apollo/client";
 
const INSERT_PROFILE_DETAIL = gql`
mutation InsertProfileDetails($phonenumber: String = "", $user_id: uuid = "") {
  insert_profile_details(objects: {phonenumber: $phonenumber, user_id: $user_id}) {
    returning {
      phonenumber
    }
  }
}
`;
// const INSERT_ORG_DETAIL = gql`
// mutation MyMutation($name: String = "") {
//   insert_organisation_master(objects: {name: $name}) {
//     returning {
//       name 
//     }
//   }   
// }
// `;

// const INSERT_USER_DETAIL = gql` 
// mutation MyMutation($defaultRole: String = "", $displayName: String = "", $email: citext = "", $passwordHash: String = "", $locale: String = "") {
//   insertUsers(objects: {defaultRole: $defaultRole, displayName: $displayName, email: $email, passwordHash: $passwordHash, locale: $locale}) {
//     returning {
//       displayName
//      id
//     }
//   }
// }
// `;
const INSERT_ORG_DETAIL = gql`
mutation MyMutation($orgn_id: bigint = "", $uuid: uuid = "") {
  insert_user_organisations(objects: {orgn_id: $orgn_id, uuid: $uuid}) {
    returning {
      orgn_id
    }
  }
}
`;
export default function CommunicationDetails(props) {
  const userid=useUserId();
  const [suctemp , setSucTemp] = useState(true);
  const [data , setData] = useState({}) ;
  const [error,setError] = useState();
  const [errors, setErrors] = useState({});
  const nhost = useNhostClient();
  const {
    signUpEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
    isError,
    error:signuperror,
  } = useSignUpEmailPassword();
 
  const handleChange = (key, value) => {
    console.log(key, value);

    // Set the data with the updated value
    setData(prevData => ({
        ...prevData,
        [key]: value
    }));

    // Check if there's an existing error for the field and clear it
    if (errors[key]) {
        setErrors(prevErrors => ({
            ...prevErrors,
            [key]: null  
        }));
    }
}


    
    const [InsertProfileDetails] = useMutation( INSERT_PROFILE_DETAIL, {
      onCompleted: (data) => {
        console.log("some data updated");
        // nextuser()
      },
      onError: (error) => {
        console.log("error updating profile : ", error);
      },
    });
    // const handlePhone= async() => {
 
    //   try {
    //           const { data: insertedData } = await InsertProfileDetails ({
    //             variables: {
    //               user_id: userid,
    //               phonenumber:data.phone
               
    //             },
    //           });
    //           console.log("instered group header info", insertedData);
    //         } catch (error) {
    //           console.error("GraphQL Mutation Error:", error.message);
    //         }
    //       }
          const Org = async () => {
            try {
              const { data: insertedData } = await InsertOrgDetail({
                variables: {
                  uuid: userid, 
                  orgn_id: localStorage.getItem('id'),
                },
              });
              console.log("inserted org info", insertedData);
            } catch (error) {
              console.error("GraphQL Mutation Error:", error.message);
            }
          };
           
            const [InsertOrgDetail] = useMutation(INSERT_ORG_DETAIL, {
              onCompleted: (data) => {
                console.log("some data updated",data.id);
                localStorage.setItem("id", data.insert_user_organisations.returning[0].orgn_id);
          
               
               
                
              },
           

              onError: (error) => {
                console.log("error updating profile : ", error);
              },
            });
           
 
    const validate = () => {
      let isValid = true;
      const newErrors = {};
 
      if (!data.email) {
        newErrors.email = "Please fill in the email field";
        isValid = false;
      }
 
      if (!data.phone || !data.orgname) {
        newErrors.phone = "Please fill in the phone number field";
        newErrors.orgname = "Please fill in the organization field";
        isValid = false;
      }
 
      if (data.passnew !== data.repass) {
        newErrors.repass = "Passwords don't match";
        isValid = false;
      }
 
      setErrors(newErrors);
      return isValid;
    }
 
    const handlenextstep=async()=>{
      signUpEmailPassword(data.email, data.passnew, {
        displayName: data.displayName,
        
   
     
      });
      Org();

      
    
      //   handlePhone();
    
      if (!validate()) {
        return;
      }
      props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
   
    
     
 
 
  
    return (
      <div>
  
      <div className="flex flex-col gap-[30px] items-start justify-start mb-7 mt-[-NaNpx] mx-auto w-[97%] z-[1]">
      <div className="flex flex-col items-center justify-start w-full">
        <div className="flex md:flex-col flex-row md:gap-10 items-start justify-between w-full">
          <div className="flex md:flex-1 flex-col items-left justify-start md:mt-0 mt-[9px] w-[80%] md:w-full">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <div style={{ display: 'flex', alignItems: 'center',flexDirection:"row" }}>
              <label htmlFor="organization" style={{ width: '230px', marginRight: '10px' }}>
              E-mail
              </label>
             
              <TextField
               id="organization" variant="standard" value={data.email} onChange={(e)=>handleChange('email',e.target.value)} />
               {errors.email && <div style={{ color: "red" }}>{errors.email}</div>}
               </div>
             
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="organization" style={{ width: '230px', marginRight: '10px' }}>
              Phone number
              </label>
              <TextField
               id="organization" variant="standard" value={data.Phone} onChange={(e)=>handleChange('phone',e.target.value)} />
               {errors.phone && <div style={{ color: "red" }}>{errors.phone}</div>}
               </div>
 
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="organization" style={{ width: '230px', marginRight: '10px' }}>
              Name of the company
              </label>
              <TextField
               id="organization" variant="standard" value={data.orgname} onChange={(e)=>handleChange('orgname',e.target.value)} />
               {errors.orgname && <div style={{ color: "red" }}>{errors.orgname}</div>}
               </div>
 
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="organization" style={{ width: '230px', marginRight: '10px' }}>
              Set new password
              </label>
              <TextField
               id="organization" variant="standard" value={data.passnew} onChange={(e)=>handleChange('passnew',e.target.value)} />
            </div>
 
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="organization" style={{ width: '230px', marginRight: '10px' }}>
              Re-enter new password
              </label>
              <TextField
               id="organization" variant="standard" value={data.repass} onChange={(e)=>handleChange('repass',e.target.value)} />
               {errors.repass && <div style={{ color: "red" }}>{errors.repass}</div>}
               </div>
 
         
          </div>
        </div>
 
       
      </div>
   
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left'}}>

    <button
        onClick={handlenextstep}
        className="cursor-pointer h-[30px] leading-[normal] md:ml-[0] text-center text-lg w-[96px]"
        style={{
            backgroundColor: '#00A5CF', 
            border: 'none',            
            borderRadius: "20px",
            color: 'white',             
            cursor: 'pointer',           
            outline: 'none',
            display: 'flex',            // Add flex display
            alignItems: 'center',       // Center items vertically
            justifyContent: 'space-evenly'    // Center content horizontally
        }}
        shape="round"
    >
        <Text style={{ fontSize: "14px", color: 'white' }}>Next</Text>
        <Img src="images/forward.png" alt="arrow" style={{  }} />
    </button>
    
</div>

  </div>
 
  </div>
  )
}