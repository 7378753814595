import React, { useState } from 'react'
import LinearWithValueLabel from './ProgressBar'
import { Img, Text } from 'components'
import { useNavigate } from 'react-router';

export default function Payment() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navigate = useNavigate();
    const toggleNav = () => {
      
      setIsNavOpen(!isNavOpen);
    };
    
    const showLoginDetails = () => {
        setShowLoginInfo(true);
    };
    const [showLoginInfo, setShowLoginInfo] = useState(false);
    const handleSubmit = () => {
      
        console.log("Attempting to navigate");
      // Your form submission logic
      // After form submission, navigate to the pricing page
       
        navigate("/Login");
     
      }
  return (
    <>
    <div className="bg-white-A700 flex flex-col font-poppins items-center justify-start mx-auto p-[34px] sm:px-5 w-full " >
    <div className="flex flex-col items-center justify-start max-w-[1444px] mb-5 mx-auto md:px-5 w-full">
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '50%', gap: '10px', alignItems: 'center' }}>
        <Img
          className="h-[43px] md:h-auto object-cover w-[43px]"
          src="images/img_convenelogo.png"
          alt="convenelogo"
        />
        <Text
          className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700 flex-shrink-0"
          size="txtPoppinsSemiBold25"
        >
          Convene chat
        </Text>
      </div>
      <Img
        onClick={toggleNav}
        className="md:h-[15px] md:ml-[0] ml-[29px] object-cover"
        src="images/Threeline.png"
        alt="idea"
      />
      {isNavOpen && (
        <div
          style={{
            position: "fixed",
            top: 60,
            right: 0, 
            width: "30%", 
            background: "white",
            height: "40%", 
             display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent:'center',
           
            padding: "20px",
            boxSizing: "border-box",
            
          }}
        > <Text size="txtPoppinsRegular18">Demo</Text>
        <Text size="txtPoppinsRegular18">Pricing</Text>
        <Text size="txtPoppinsRegular18">Features</Text>
        <Text size="txtPoppinsRegular18">FAQs</Text>
        <Text size="txtPoppinsRegular18">Sign up</Text>
        </div>
      )}
    </div>

    </div>
    <div style={{ display:"flex",width:"50%",alignItems:"center",justifyContent:"center",alignSelf:"center",color:"#00A5CF" }}>
    <p style={{fontSize:'22px',fontWeight:"bold",textAlign:"center"}}>Welcome convene chat!</p>
   
  </div>
  {!showLoginInfo && (
<div style={{marginTop:"2%",marginBottom:20}}>
  <Text 
  className="text-base text-gray-700"
  size="txtPoppinsLight12"
>
please verify your Details
</Text>
</div>
  )}
  <div style={{ display:"flex",alignItems:"left",justifyContent:"left",alignSelf:"left",width:"100%" }}>
  <LinearWithValueLabel key={showLoginInfo ? 'login' : 'verification'} initialValue={!showLoginInfo ? 75 : 100} />
  
  
  </div>
</div>

{!showLoginInfo && (
<div style={{ width:"100%",display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:"column" }}>
<h1 style={{marginBottom:10, width:"90%",fontSize:"22px",color:"#8CB369",marginBottom:20}}       onClick={showLoginDetails}>Choose Your Payment Method</h1>
</div>
)}
{showLoginInfo && (
    <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent:"center",marginBottom: '10px',flexDirection:"column",height:'500px' }}>
        <Img
        className="h-[43px] md:h-auto object-cover w-[43px] mb-3"
        src="images/Succesfull.png"
        alt="convenelogo"
      />
            <Text style={{width:"60%",textAlign:"center",color:"#8CB369"}}>Now you can login and gets started!</Text>
      
       
        <button
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                border: '1px solid #00A5CF',
                borderRadius: '25px',
             
                width: '25%',
                height: '25px',
                marginTop: '20px', // Add margin top as needed
            }}
           onClick={handleSubmit}
        >
        <text style={{color:"#00A5CF"}}>
            Login
            </text>
            
        </button>
        </div>
    </>
)} 
</>
  )
}
