import React, { useState } from "react";

import {  Img, Text, Button } from "../../components";
import { Heading } from "components/Heading";
import { useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { useUserId } from "@nhost/react";
import Header from "../../components/NewHeader/Header";

// const FETCH_USER_DETAIL = gql`
// query MyQuery($_eq: uuid = "") {
//     users(where: {id: {_eq: $_eq}}) {
//       displayName
//       email
//       avatarUrl
//     }
//   }
// `;

const FETCH_FORM_DETAIL = gql`
query MyQuery($_eq: bigint = "") {
  Form_api_data(where: {orgn_id: {_eq: $_eq}}) {
    Form_Name
    Form_api
    Form_data
    id
  }
}
  `;
export default function FormcreationPage() {
const userid=useUserId();
const [data,setData]=useState();
  const { loading:loadins, error:erros, data:userdata } = useQuery(FETCH_FORM_DETAIL, {
    variables: {
      _eq: localStorage.getItem('id'),
    },
    onError: (error) => {
      console.log('error fetching user details: ', error);
    },
    onCompleted: (data) => {
      // Data fetching is completed
      
      setData(data.Form_api_data);
      // console.log('FormData:', data.Form_api_data);
  
    },
});
  const navigate = useNavigate();

  const handleCreateNewForm = () => {
    navigate("/createform")
  };

  const handleNavigate =(fdata , formdata)=>{
    console.log("sdf",fdata)
    navigate("/formeditshower" , {state :{fdata ,formdata }})
  }

  return (
    <>
      
      <div className="flex flex-col items-start justify-center w-full pb-[213px] gap-[50px] md:pb-5 bg-white-A700">
        <div className="flex flex-col items-center justify-start w-full">
          {/* <header className="flex justify-center items-center w-full p-9 sm:p-5 border-[#652EAC] border-b-[3px] border-solid bg-[#EADAFF]" style={{backgroundColor:"#EEEEEE" ,borderBottom:"3px solid #00A5CF "}}>
            <div className="flex flex-row justify-center w-full mx-auto md:px-5 max-w-[100%]">
              <div className="flex flex-row sm:flex-col justify-between items-center w-full sm:gap-10">
                <div className="flex flex-row justify-between items-center w-auto gap-8">
                  <Img
                    src="images/img_convene_logo.png"
                    alt="convenelogo_one"
                    className="w-[60px] md:h-auto object-cover"
                  />
                  <Heading size="4xl" as="h2">
                    Convene chat
                  </Heading>
                </div>
                <div className="flex flex-row justify-between items-center w-[12%] sm:w-full">
                  <Img src="images/img_icon.svg" alt="icon_one" className="h-[49px]" />
                  <div className="flex flex-col items-center justify-start w-[44%]">
                    <div className="h-[71px] w-full relative">
                    <Img
              src={`https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${data?.avatarUrl}`}
                  alt="image"
                  className="h-[68px] w-[68px] top-0 right-0 left-0 m-auto object-cover rounded-full "
                  />
                      <Button
                        color="light_green_400"
                        size="xs"
                        variant="fill"
                        shape="round"
                        className="w-[21px] bottom-0 right-0 m-auto border-white-A700 border-[5px] border-solid absolute"
                      >
                        <Img src="images/img_group_62.svg" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header> */}
             <Header backgroundColor={"#EADAFF"} border={"3px solid #652EAC"}/>
        </div>
        <div className="flex flex-col items-center justify-start w-[full] md:w-full ml-12 md:ml-5">
          <div className="flex flex-col items-start justify-start w-full">
            <Heading as="h1" className="!text-deep_purple-600">
              Organisation Forms
            </Heading>
            <div className="flex flex-row justify-start items-start w-full mt-[29px] gap-6 mx-auto md:px-5 ">
              <Button color="cyan_600" size="lg" variant="fill" shape="round" className="w-[57px]" onClick={()=>{handleCreateNewForm()}} >
                <Img src="images/img_bi_plus.svg" />
              </Button>
              <Text size="4xl" as="p" className="mt-2 !text-gray-700">
                Create new form
              </Text>
            </div>
            <Heading size="2xl" as="h2" className="mt-[47px]">
              Created Forms
            </Heading>
            <div className="flex flex-row w-[70%]   flex-wrap mt-6 gap-20">
          {data && data.map((form, index) => (
            <div key={index} className="flex flex-col items-start justify-between h-[320px] gap-[110px] p-3 bg-gray-100 shadow-xl rounded-[7px] w-1/5" onClick={()=>handleNavigate(form.Form_data , form)} >
              <Img src="images/img_icon_cyan_600.svg" alt="form_icon" className="h-[15px] w-[15px] mr-1 self-end " />
              <Heading size="lg" as="h3" className="!text-cyan-600">
                {form.Form_Name}
              </Heading>
            </div>
          ))}
              {/* <div className="flex flex-col items-end justify-center w-[47%] gap-[110px] p-3 bg-gray-100 shadow-xl rounded-[7px]">
                <Img src="images/img_icon_cyan_600.svg" alt="icon_one" className="h-[11px] w-[11px] mt-1 mr-1" />
                <Heading size="lg" as="h4" className="!text-cyan-600">
                  Category 2 Form
                </Heading>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
