import React from 'react';
import { Dialog, DialogContent, Button } from '@mui/material';
import { gql, useMutation } from '@apollo/client';

const END_CALL_NOTIFICATION = gql`
mutation MyMutation($group_calling_status: Boolean = false, $_eq: bigint = "") {
  update_notification_master(where: {meeting_id: {_eq: $_eq}}, _set: {group_calling_status: $group_calling_status}) {
    affected_rows
  }
}
`;

const AddMemberModal = ({open , onClose , apiresponse ,name}) => {
    // console.log(apiresponse?.meetingDetails?.CustomerMeetingId)
    const [updatenotificationcutcall , { loading:noti, error:errornoti, data: notiedata }] = useMutation(END_CALL_NOTIFICATION, { 
      onError:(error)=>{
        console.log("error for the personal chat",error)
      },
      onCompleted:(data)=>{
        console.log('done') 
      }
    });
    const updatenotificationtable = async()=>{
      try {
        const { data: insertedData } = await updatenotificationcutcall({
          variables: {
            _eq:apiresponse?.meetingDetails?.CustomerMeetingId, 
          },
        });
      } catch (error) {
        console.log("error while last message updating", error);
      }
    }
    const handleEndCall = async ()=>{
      console.log('end call' , name)
      updatenotificationtable()

      try {
        const response = await fetch(`https://portalapi.convenevc.com/api/leave/${apiresponse.meetingDetails.CustomerMeetingId}/instant`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "MeetingName": name,
            "OrganizationID": 115566,
            "displayName": "displayName",
            "email": "connect@convenevc.com",
          })
        });

        if (response.ok) {
            // props.setCallmodalVisible(false)
            onClose()

          const responseData = await response.json();
} else {
          console.error('Failed to send verification email. Status:', response);
          const errorText = await response.text(); // Get error message from response
          console.error('Error message:', errorText);
        }
      } catch (error) {
        console.error('Error sending verification email:', error);
      }
      onClose()

    }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px 24px', width:200, height:10}}>
      {/* <h2 style={{ color: '#616161', fontSize: '1.25rem', fontWeight: 'bold', fontFamily: 'Poppins, sans-serif' }}>Add Member</h2> */}
      <Button onClick={onClose} style={{ minWidth: 'auto', padding: 0, zIndex: 10 }}>X</Button>
      
      </div>
      
      <div style={{ height: 500}}>
        <iframe src={apiresponse?.meetingDetails?.ModeratorUrl} style={{width:'100%' , height:'100%'}} />
        </div>
        
   
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px'}}>
        <Button className='w-full'
          onClick={()=>{handleEndCall()}} 
          style={{ backgroundColor: 'red', color: 'white', padding: '10px 20px', borderRadius: '10px' ,fontWeight:'bold' }}
        >
          End Meeting
        </Button>
      </div>
    </Dialog>
  );
};

export default AddMemberModal;
