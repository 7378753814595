import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Button, Checkbox, List, ListItem, ListItemAvatar, Avatar, ListItemText, FormControlLabel } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import defaultProfileImage from '../../assets/images/gorupplaceholder.png';

const GET_USERTOADD_PERSONALCHAT = gql`
query getorganisationuser($_eq: bigint = "") {
  user_organisations(where: {orgn_id: {_eq: $_eq}}) {
    profiledata {
      userdata {
        displayName
        avatarUrl
      }
      custom_username
    }
    uuid
  }
}`;

const CREATE_PERSONAL_CHAT = gql`
mutation insertpersonalgroup($created_user_id: uuid = "", $personal_chatid: uuid = "", $status: String = "", $type: String = "orgperonalgrp", $orgn_id: bigint = "") {
  insert_group_master(objects: {created_user_id: $created_user_id, personal_chatid: $personal_chatid, status: $status, type: $type, orgn_id: $orgn_id}) {
    returning {
      id
    }
  }
}`;

const CREATE_GROUP_FOR_ORGANISATION = gql`
mutation creategrouporguser($orgn_id: bigint = "", $groupname: String = "", $created_user_id: uuid = "", $status: String = "active", $type: String = "orgmultusrgrp") {
  insert_group_master(objects: {orgn_id: $orgn_id, groupname: $groupname, created_user_id: $created_user_id, status: $status, type: $type}) {
    returning {
      groupname
      id
    }
  }
}`;

const CREATE_USER_GROUP_MAPPING = gql`
mutation InsertOrgPersonalGrp($group_user_mappings: [group_usermapping_insert_input!]!) {
  insert_group_usermapping(objects: $group_user_mappings) {
    returning {
      group_id
      groupdata {
        groupname
        id
        groupprofile_img
        last_messageingroup
        type
        receiverdata {
          custom_username
          user_id
          first_name
        }
        senderdata {
          custom_username
          first_name
          user_id
        }
      }
    }
  }
}`;

const CreateGroupModal = (props) => {
  const { setOrgPersonalChat, orgPersonalChat, onClose, open } = props;
  const [usersToAddGroup, setUsersToAddGroup] = useState([]);
  const [checkedStates, setCheckedStates] = useState({});
  const [data, setData] = useState({});
  const [grperror, setgrpError] = useState(false);
  const currentUserId = localStorage.getItem('userid');
  const [usersForGroup, setUserForGroup] = useState({});

  const groupUserMappings = [];
  const [creategrouporg] = useMutation(CREATE_USER_GROUP_MAPPING, { 
    onError: (error) => {
      console.log("error for the personal chat", error);
    },
    onCompleted: (data) => {
      const newGroupData = data.insert_group_usermapping.returning[0];
      const updatedOrgPersonalChat = [...orgPersonalChat, newGroupData];
      setOrgPersonalChat(updatedOrgPersonalChat);
      onClose();
    }
  });

  const [creatergroupfororganisation] = useMutation(CREATE_GROUP_FOR_ORGANISATION, { 
    onError: (error) => {
      console.log("error for the personal chat", error);
    },
    onCompleted: (data) => {
      const groupId = data.insert_group_master.returning[0].id;
      for (const [currentUserId, value] of Object.entries(checkedStates)) {
        if (value === true) {
          const mapping = {
            orgn_id: localStorage.getItem('orgnid'),
            userid: currentUserId,
            status: 'active',
            type: 'orgperonalgrp',
            group_id: groupId
          };
          groupUserMappings.push(mapping);
        }
      }
      insertalluserstogroup(groupUserMappings);
    }
  });

  const insertalluserstogroup = async (datas) => {
    try {
      await creategrouporg({
        variables: {
          group_user_mappings: datas
        }
      });
    } catch (error) {
      console.error('Error inserting group user mappings:', error);
    }
  };

  const handleChange = (key, value) => {
    setData({
      ...data,
      [key]: value
    });
  };

  const { loading, error, data: groupsdata, refetch } = useQuery(GET_USERTOADD_PERSONALCHAT, {
    variables: {
      _eq: localStorage.getItem('orgnid')
    },
    onCompleted: (data) => {
      setUsersToAddGroup(data.user_organisations);
    },
    onError: (error) => {
      console.log("error for the personal chat", error);
    }
  });

  const [creategroup] = useMutation(CREATE_PERSONAL_CHAT, { 
    onError: (error) => {
      console.log("error for the personal chat", error);
    },
    onCompleted: (data) => {
      // handle completion if necessary
    }
  });

  const handleAddUser = async () => {
    if (data.groupname === undefined || data.groupname === "" || data.groupname.length === 0) {
      setgrpError(true);
    } else {
      try {
        await creatergroupfororganisation({
          variables: {
            created_user_id: currentUserId,
            groupname: data.groupname,
            status: "active",
            orgn_id: localStorage.getItem('orgnid')
          }
        });
      } catch (error) {
        console.log("error while booking event", error);
      }
    }
  };

  useEffect(() => {
    if (usersToAddGroup.length > 0) {
      const initialCheckedStates = {};
      usersToAddGroup.forEach(item => {
        initialCheckedStates[item.uuid] = false;
      });
      setCheckedStates(initialCheckedStates);
    }
  }, [usersToAddGroup]);

  const handleCheckBoxToggle = (uuid) => {
    setCheckedStates(prevState => ({
      ...prevState,
      [uuid]: !prevState[uuid]
    }));
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="xs" fullWidth>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Create New Group</span>
        <Button onClick={props.onClose} style={{ minWidth: 'auto', padding: 0 }}>X</Button>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          fullWidth
          label="Name of the group"
          variant="outlined"
          value={data.groupname}
          onChange={(e) => handleChange('groupname', e.target.value)}
          margin="dense"
        />
        <Button
          fullWidth
      
          style={{ marginTop: '10px', marginBottom: '10px', height: "60px", borderRadius: "10px"}}
          onClick={() => handleAddUser()}
        >
          Create Group
        </Button>
        <List>
          {usersToAddGroup.map((item) => (
            <ListItem key={item.uuid} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ListItemAvatar>
                <Avatar src={item?.profiledata?.userdata?.avatarUrl ? `https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${item?.profiledata?.userdata?.avatarUrl}` : defaultProfileImage} />
              </ListItemAvatar>
              <ListItemText primary={item?.profiledata?.custom_username || item?.profiledata?.userdata?.displayName} />
              <FormControlLabel 
                control={
                  <Checkbox
                    checked={checkedStates[item.uuid]}
                    onChange={() => handleCheckBoxToggle(item.uuid)}
                  />
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default CreateGroupModal;
