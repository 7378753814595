import React, { useState } from "react";
import { CloseSVG } from "../../assets/images";
import { Text, Img, Input, Button } from "../../components";
import { Heading } from "components/Heading";
import Header from "components/NewHeader/Header";
import { gql, useQuery } from "@apollo/client";
import { useUserId } from "@nhost/react";

const FETCH_NOTIFICATION_DETAIL = gql`
query MyQuery($_eq: uuid = "") {
  notification_master(where: {userid: {_eq: $_eq}}) {
    description
  }
}
`;

export default function NotificationsOnePage() {
  const [searchBarValue5, setSearchBarValue5] = React.useState("");
const userid=useUserId();
const[notification,setNotification]=useState();
  // const notifications = [
  //   {
  //     id: 1,
  //     text: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id es"
  //   },
  //   {
  //     id: 2,
  //     text: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id es n culpa qui officia deserunt mollit anim id es"
  //   },
  //   // Add more notifications as needed
  // ];
 
  const { loading:loadins, error:erros, data:userdata } = useQuery(FETCH_NOTIFICATION_DETAIL, {
    variables: {
      _eq: userid,
    },
    onError: (error) => {
      console.log('error fetching user details: ', error);
    },
    onCompleted: (data) => {
      // Data fetching is completed
      console.log(data)
      // setData(data.Form_api_data);
      // console.log('FormData:', data.Form_api_data);
  setNotification(data.notification_master)
    },
});
  return (
    <>
      <div className="flex flex-col items-center justify-start w-full pb-[49px] gap-[46px] md:pb-5 bg-white-A700">
        {/* <header className="flex justify-center items-center w-full p-9 sm:p-5 border-cyan-600 border-b-[3px] border-solid bg-gray-200_cc">
          <div className="flex flex-row justify-center w-full mx-auto md:px-5 max-w-[1427px]">
            <div className="flex flex-row sm:flex-col justify-between items-center w-full sm:gap-10">
              <div className="flex flex-row justify-between items-center w-auto gap-8">
                <Img
                  src="images/img_convene_logo.png"
                  alt="convenelogo_one"
                  className="w-[60px] md:h-auto object-cover"
                />
                <Heading size="4xl" as="h2">
                  Convene chat
                </Heading>
              </div>
              <div className="flex flex-row justify-between items-center w-[12%] sm:w-full">
                <Img src="images/img_icon.svg" alt="icon_one" className="h-[49px]" />
                <div className="flex flex-col items-center justify-start w-[44%]">
                  <div className="h-[71px] w-full relative">
                    <Img
                      src="images/img_rectangle_6.png"
                      alt="image"
                      className="h-[68px] w-[68px] top-0 right-0 left-0 m-auto object-cover absolute"
                    />
                    <Button
                      color="light_green_400"
                      size="xs"
                      variant="fill"
                      shape="round"
                      className="w-[21px] bottom-0 right-0 m-auto border-white-A700 border-[5px] border-solid absolute"
                    >
                      <Img src="images/img_group_62.svg" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header> */}
            <Header backgroundColor={"#EEEEEE"} border={"3px solid #00A5CF"}/>
        <div className="flex flex-row justify-center w-full">
          <div className="flex flex-col items-center justify-start w-full gap-[49px]">
            <div className="flex flex-row md:flex-col justify-between items-center w-full md:gap-10 md:px-5 max-w-[1427px]">
              <Heading as="h1" className="!text-cyan-600">
                Notifications
              </Heading>
              <div className="flex flex-row sm:flex-col justify-between items-center w-auto gap-[31px] sm:gap-10">
                <Text size="2xl" as="p" className="!text-light_green-400">
                  Read all
                </Text>
                <div className="border border-gray-500 rounded-[10px] flex items-center">
                  <div className="flex justify-center items-center w-[38px] h-[18px]">
                    <Img src="images/img_search.svg" alt="search" className="cursor-pointer" />
                  </div>
                  <input
                    name="search"
                    placeholder="Search"
                    className="w-full text-blue_gray-100 rounded-[10px] py-2"
                  />
                  {searchBarValue5?.length > 0 && (
                    <CloseSVG onClick={() => setSearchBarValue5("")} height={18} width={18} />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full gap-px">
              {/* {notification.map((notifications) => ( */}
                <div  className="flex flex-row justify-start w-full p-4 border-gray-700 border-b border-solid bg-blue_gray-100_26">
                  <div className="flex flex-row md:flex-col justify-start items-center w-full gap-12 mx-auto md:gap-5 md:px-5 max-w-[1192px]">
                    <Img
                      src="images/img_ellipse_98.png"
                      alt="circleimage"
                      className="h-[63px] w-[63px] md:h-auto rounded-[50%]"
                    />
                    <Text size="3xl" as="p" className="!text-gray-700">
                      {/* {notifications.text} */}
                    </Text>
                  </div>
                </div>
              {/* ))} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
