import React, { useEffect, useState } from 'react';
import { Text } from "components";
import { gql, useQuery } from '@apollo/client';
import { useUserId } from '@nhost/react';
import { useNavigate } from 'react-router-dom';

const FETCH_ORG_ID = gql`
  query MyQuery($_eq: uuid = "") {
    user_organisations(where: {uuid: {_eq: $_eq}}) {
      orgn_id
      orgnisationdata {
        name
      }
    }
  }
`;

export default function OrganisationComponent(props) {
  const [selectedOrganisation, setSelectedOrganisation] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const [organisation, setSelectOrganisation] = useState([]);
  const userid = useUserId();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 960);
    };
 
    handleResize();
 
    window.addEventListener("resize", handleResize);
 
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleSelectChange = (event) => {
    console.log(event.target.value)
    setSelectedOrganisation(event.target.value);
      localStorage.setItem('id', event.target.value);
       if (!isMobile) {
        navigate("/dashboard", { state: { activeStep: 3 } });
      } else {
        navigate("/CreateAdmin");
        
      }
  };

  const { loading, error, data: queryData } = useQuery(FETCH_ORG_ID, {
    variables: { _eq: userid },
    onCompleted: (data) => {
      console.log("orgid", data?.user_organisations[0].orgnisationdata);
      setSelectOrganisation(data?.user_organisations);
    },
  });

  return (
    <div className='w-full h-full'>
      <Text
        className="md:ml-[0] ml-[19px] mt-8 text-cyan-600 text-lg"
        size="txtPoppinsRegular18Cyan600"
      >
        Select Organisation
      </Text>
      <select
        name="organisation"
        className="pl-2 w-100 mt-3.5"
        style={{ height: 40, width: '93%', borderRadius: 10 }}
        value={selectedOrganisation}
        onChange={handleSelectChange}
      >
        <option value="">Select an organisation</option>
        {organisation.map((item) => (
          <option key={item.orgn_id} value={item.orgn_id}>
            {item?.orgnisationdata?.name}
          </option>
        ))}
      </select>
    </div>
  );
}
