
import React, { useEffect, useState } from "react";

import { Button, Img, Input, Text } from "components";
import { CloseSVG } from "../../assets/images";
import { createColumnHelper } from "@tanstack/react-table";
import { ReactTable } from "components/ReactTable";
import { Heading } from "components/Heading";
import Table1 from "./Table1"
import Table2 from "./Table2"
import { useNavigate } from "react-router";
import { gql, useQuery } from "@apollo/client";
import { useUserId } from "@nhost/react";
import Header from "../../components/NewHeader/Header";
import { useParams } from "react-router-dom";
// const FETCH_USER_DETAIL = gql`
// query MyQuery($_eq: bigint = "") {
//   user_organisations(where: { orgn_id: { _eq: $_eq } }) {
//     profiledata {
//       first_name
//       city
//       userdata {
//         defaultRole
//         email
//       }
//     }
//   }
// }
// `;

const FETCH_USER_DETAIL = gql`
query MyQuery($_eq: uuid = "") {
    users(where: {id: {_eq: $_eq}}) {
      displayName
      email
      avatarUrl
    }
  }
`;

  export default function UsermanagementOnePage(props) {
    const navigate=useNavigate();
    const { backgroundColor, borderColor } = useParams();

    const [searchBarValue1, setSearchBarValue1] = useState("");
    const [showTable1, setShowTable1] = useState(true);
    const [showTable2, setShowTable2] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [data,setData] = useState("");
const userid=useUserId();
    const handleUser=()=>{
        navigate("/Adduser")
    }

    const { loading:loadins, error:erros, data:userdata } = useQuery(FETCH_USER_DETAIL, {
      variables: {
        _eq: userid,
      },
      onError: (error) => {
        console.log('error fetching user details: ', error);
      },
      onCompleted: (data) => {
        // Data fetching is completed
        console.log('Data:', data.users);
    setData(data.users[0]); 
      },
  });
  
  
  
  return (
    <>
      <div className="flex flex-col items-center justify-start w-full pb-[46px] gap-[51px] md:pb-5 bg-white-A700">
        {/* <header className="flex justify-space between items-center w-full p-9 sm:p-4 border-[#BAC964] border-b-[3px] border-solid ">
          <div className="flex flex-row sm:flex-row justify-between items-center w-full md:w-[100%]  sm:w-[100%]  sm:gap-10">
            <div className="flex flex-row justify-between md:justify-start items-center w-auto md:w-[60%] gap-8">
              <Img
                src="images/img_convene_logo.png"
                alt="convenelogo_one"
                className="w-[60px] md:w-[40px] md:h-auto object-cover"
              />
              <Heading size="4xl" as="h2" className="md:text-lg">
                Convene chat
              </Heading>
            </div>
            <div className="flex flex-row justify-between sm:justify-evenly items-center w-[12%] sm:w-[28%] ">
              <Img
                src="images/img_icon.svg"
                alt="icon_one"
                className="h-[49px] md:h-[30px]  md:w-[30px]  "
              />
              <div className="flex flex-col items-center  w-[44%]">
                <div className=" items-center md:justify-center  w-full ">
                  <Img
                    src={`https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${data?.avatarUrl}`}
                    alt="image"
                    className="h-[68px] w-[68px] top-0 right-0 left-0 m-auto object-cover rounded-full "
                  />
                </div>
              </div>
            </div>
          </div>
        </header> */}
        <Header backgroundColor={"#F7FBE1"} border={"3px solid #BAC964"}/>
        <div className="flex flex-col items-center justify-start w-full pb-[46px] gap-[51px] md:pb-5 bg-white-A700">
          <div className="flex flex-col items-center justify-start w-[90%]">
            <div className="flex flex-row md:flex-col justify-between items-start w-full md:gap-10">
              <Heading
                as="h1"
                className="mt-px md:mt-0 !text-light_green-400_01"
                style={{ color: "#BAC964" }}
              >
                User Management
              </Heading>
              <div className="flex flex-row sm:flex-row  justify-start items-center w-[36%] md:w-full gap-6 sm:gap-5">
                <Button
                  color="light_green_400"
                  size="sm"
                  leftIcon={<Img src="images/img_icon_gray_700.svg" alt="Icon" />}
                  className="gap-2.5 min-w-[136px] rounded-[18px]"
                  onClick={handleUser}
                >
                  Add user
                </Button>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "5px",
                    width: "50%",
                  }}
                >
                  <img
                    src="images/img_search.svg"
                    alt="Search"
                    style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }}
                  />
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    className="md:w-[30%]"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ flex: "1", border: "none", outline: "none", padding: "5px" }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center w-[90%] mt-[43px]">
  <div className="flex flex-row justify-start w-full h-[40px] gap-5  rounded-tl-[10px] rounded-tr-[10px] border-gray-700 border border-solid  ">
    <div className="flex flex-row justify-between w-[20%] text-center  md:w-[90%]  md:ml-5 ">
    <div 
  className={`h-[40px] cursor-pointer w-[50%] ${showTable1 ? "bg-[#8CB369] rounded-tl-[10px]" : ""}`}
  onClick={() => {
    setShowTable1(true);
    setShowTable2(false);
  }}
>
  <Heading
    size="md"
    as="p"
    className={` text-center h-full w-full flex items-center justify-center ${showTable1 ? "text-amber-50" : ""}`}
  >
    Existing User
  </Heading>
</div>

      
<div 
  className={`h-[40px] cursor-pointer w-[50%] ${showTable2 ? "bg-[#8CB369] " : ""}`}
  onClick={() => {
    setShowTable1(false);
    setShowTable2(true);
  }}
>
  <Heading
    size="md"
    as="p"
    className={` text-center h-full w-full flex items-center justify-center ${showTable2 ? "text-amber-50" : ""}`}
  >
   New User
  </Heading>
</div>
    </div>
<div className=" top-0 right-0 h-full w-[2px] ml-10 bg-gray-700"></div>
  </div>
</div>
{showTable1 && <Table1 searchQuery={searchQuery} />}
            {showTable2 && <Table2 searchQuery={searchQuery} />}
                     </div>
            
 
        </div>
      </div>
    </>
  );
}