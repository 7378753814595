import { gql, useMutation } from '@apollo/client';
import { Img, Text } from 'components';
import React, { useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Typography } from '@mui/material';
import { useUserId } from '@nhost/react';
import { TextArea } from 'components/TextArea';


const INSERT_ROLE_DETAIL = gql`
  mutation InsertRoll($name: String = "", $access: jsonb = "") {
    insert_organisation_roles(objects: { name: $name, access: $access }) {
      returning {
        name
      }
    }
  }
`;

const SimpleDropdown = () => {
  const userid = useUserId();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [data, setData] = useState({});
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState('');
  const [accessControlNames, setAccessControlNames] = useState([
    { name: 'Access Control 1', checked: false },
    { name: 'Access Control 2', checked: false },
    { name: 'Access Control 3', checked: false }
  ]);

  const handleChange = (key, value) => {
    console.log(key, value)
    setData(
      {
        ...data,
        [key]: value
      }
    )
  };

  const [InsertRoleDetail] = useMutation(INSERT_ROLE_DETAIL, {
    onCompleted: (data) => {
      console.log("some data updated");
      // nextuser()
    },
    onError: (error) => {
      console.log("error updating profile : ", error);
    },
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  const selectedAccessControls = accessControlNames
  .filter((control) => control.checked)
  .map((control) => control.name);
  const addRole = async () => {

    if (!data.rollname || !data.description) {
      setError('Role Name and Description are required.');
      return;
    }
    try {
      const { data: insertedData } = await InsertRoleDetail({
        variables: {
          name: data.rollname,
          access: selectedAccessControls
        },
      });
      console.log("inserted group header info", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }

   

    const newRole = {
      id: roles.length + 1,
      name: data.rollname,
      description: data.description,
      accessControl: selectedAccessControls.join(', ')
    };
    setRoles([...roles, newRole]);
    setError('');

  };

  const handleAccessControlChange = (name, isChecked) => {
    setAccessControlNames((prevAccessControlNames) =>
      prevAccessControlNames.map((control) =>
        control.name === name ? { ...control, checked: isChecked } : control
      )
    );
  };

  return (
    <div style={{ flex: 1, display: "flex", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: 'column', width: "90%" }}>
        <div style={{ position: 'relative', width: '250px', marginBottom: 20 }}>
          <input type="text" placeholder='roll name' value={data.rollname} style={{ resize: 'none', marginTop: "0px" }} onChange={(e) => handleChange('rollname', e.target.value)} />
        </div>
        {error && <div style={{ color: 'red', marginBottom: 10 }}>{error}</div>}
        {isOpen && (
          <div style={{ border: '1px solid #00A5CF ', width: '200px', marginBottom: 20 }}>
            <div onClick={() => handleOptionClick('Option 1')} style={{ border: '1px solid #ccc', padding: '10px', borderTop: 'none', cursor: 'pointer' }}>Option 1</div>
            <div onClick={() => handleOptionClick('Option 2')} style={{ border: '1px solid #ccc', padding: '10px', cursor: 'pointer' }}>Option 2</div>
            <div onClick={() => handleOptionClick('Option 3')} style={{ border: '1px solid #ccc', padding: '10px', cursor: 'pointer' }}>Option 3</div>
          </div>
        )}
        <label htmlFor="description">Description</label>
        <TextArea type="text" id="description"
    className="w-[345px] h-30 mt-2 p-2 border border-gray-400 rounded mb-10"
    onChange={(e) => handleChange('description', e)}
    />
        {/* <input type="text" id="description" style={{ border: '1px solid #00A5CF ', width: '300px', marginBottom: '10px', resize: 'none', marginTop: "0px" }} onChange={(e) => handleChange('description', e.target.value)} /> */}
        <button style={{ padding: '7px', width: '100px', height: "30px", border: '1px solid #00A5CF ', color: '#616161', cursor: 'pointer', borderRadius: 20, alignItems: 'center', fontSize: 12, justifyContent: "center", marginBottom: 20 }} onClick={addRole}>Add Role</button>

        <table style={{ borderCollapse: 'collapse', width: '100%', border: '2px solid #616161', borderColor: '#616161' }}>
          <thead>
            <tr>
              <th style={{ border: '2px solid #616161', padding: '8px' }}>Role ID</th>
              <th style={{ border: '2px solid #616161', padding: '8px' }}>Role Name</th>
              <th style={{ border: '2px solid #616161', padding: '8px' }}>Role Description</th>
              <th style={{ border: '2px solid #616161', padding: '8px' }}>Access Control</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role) => (
              <tr key={role.id}>
                <td style={{ border: '2px solid #616161', padding: '8px',wordWrap: 'break-word' }}>{role.id}</td>
                <td style={{ border: '2px solid #616161', padding: '8px',wordWrap: 'break-word' }}>{role.name}</td>
                <td style={{ border: '2px solid #616161', padding: '8px',wordWrap: 'break-word' }}>{role.description}</td>
                <td style={{ border: '2px solid #616161', padding: '8px',wordWrap: 'break-word' }}>{role.accessControl}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', width: "70%", marginTop: "4%",gap:10 }}>
        <button
        // onClick={handlenextstep}
        className="cursor-pointer h-[30px] leading-[normal] md:ml-[0] text-center text-lg w-[96px]"
        style={{
            backgroundColor: '#00A5CF', 
            border: 'none',            
            borderRadius: "20px",
            color: 'white',             
            cursor: 'pointer',           
            outline: 'none',
            display: 'flex',            // Add flex display
            alignItems: 'center',       // Center items vertically
            justifyContent: 'space-evenly'    // Center content horizontally
        }}
        shape="round"
    >
        <Text style={{ fontSize: "14px", color: 'white' }}>Next</Text>
        <Img src="images/forward.png" alt="arrow" style={{  }} />
    </button>
    
   
    
    <button   className="cursor-pointer h-[30px] leading-[normal] md:ml-[0] text-center text-lg w-[116px]"
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', padding: '10px', borderRadius: '25px', border: '1px solid #8CB369',marginLeft: '5px' }}>
    <img src="images/tick.png" alt="tick" />
    <Text style={{ fontSize: "12px" }}>  Save Stage </Text>
  </button>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '60%' }}>

        <div style={{ width: '100%', display: 'flex', alignItems: 'left', marginBottom: 20, }}>
          <h2 style={{ }}>Access Control</h2>
        </div>
        <div>
          {accessControlNames.map((control, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  color="primary"
                  checked={control.checked}
                  onChange={(e) => handleAccessControlChange(control.name, e.target.checked)}
                />
              }
              label={<Typography>{control.name}</Typography>}
              style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '5px' }}
            />
          ))}
        </div>
      </div>
    </div>

  );
};

export default SimpleDropdown;
