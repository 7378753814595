import React, { useState } from 'react'

import { Img, Text } from 'components'
import { useNavigate } from 'react-router';

export default function Payment() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navigate = useNavigate();
    const toggleNav = () => {
      
      setIsNavOpen(!isNavOpen);
    };
    
    const showLoginDetails = () => {
        setShowLoginInfo(true);
    };
    const [showLoginInfo, setShowLoginInfo] = useState(false);
    const handleSubmit = () => {
      
        console.log("Attempting to navigate");
      // Your form submission logic
      // After form submission, navigate to the pricing page
       
        navigate("/Login");
     
      }
  return (
    <>
   
{!showLoginInfo && (
<div style={{ width:"100%",display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:"column" }}>
<h1 style={{marginBottom:10, width:"90%",fontSize:"22px",color:"#8CB369",marginBottom:20}}       onClick={showLoginDetails}>Choose Your Payment Method</h1>
</div>
)}
{showLoginInfo && (
    <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent:"left",marginBottom: '10px',flexDirection:"column",height:'200px',width:"50%" }}>
        <Img
        className="h-[43px] md:h-auto object-cover w-[43px] mb-3"
        src="images/Succesfull.png"
        alt="convenelogo"
      />
            <Text style={{width:"60%",textAlign:"center",color:"#8CB369"}}>Now you can login and gets started!</Text>
      
       
        <button
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                border: '1px solid #00A5CF',
                borderRadius: '25px',
             
                width: '20%',
                height: '30px',
                marginTop: '20px', // Add margin top as needed
            }}
           onClick={handleSubmit}
        >
        <text style={{color:"#00A5CF"}}>
            Login
            </text>
            
        </button>
        </div>
    </>
)} 
</>
  )
}
