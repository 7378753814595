import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useUserId } from '@nhost/react';

const FETCH_USER_DETAIL = gql`
  query MyQuery($_eq: bigint = "") {
    user_organisations(where: {orgn_id: {_eq: $_eq}}) {
      profiledata {
        first_name
        city
        userdata {
          defaultRole
          email
        }
        user_id
      }
    }
  }
`;

const INSERT_USER_DETAIL = gql`
mutation MyMutation($userid: uuid = "", $orgn_id: bigint = " ", $group_id: bigint = " ") {
  insert_group_usermapping(objects: {userid: $userid, orgn_id: $orgn_id, group_id: $group_id}) {
    returning {
      group_id
      orgn_id 
    }
  }
}`;

const TableComponent = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const userid = useUserId();
  const groupid = localStorage.getItem('groupId');
  const orgid = localStorage.getItem('id');
  const { loading, error, data } = useQuery(FETCH_USER_DETAIL, {
    variables: {
      _eq: localStorage.getItem('id'),
    },
    onError: (error) => {
      console.log('error fetching user details: ', error);
    },
    onCompleted: (data) => {
      // Data fetching is completed
      console.log('ata data:', data);
    },
    
  });
  
  const [insertUserDetail] = useMutation(INSERT_USER_DETAIL);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const alluserid = data.user_organisations.map((row) => row.profiledata.user_id);
      setSelectedUsers(alluserid);
    } else {
      setSelectedUsers([]);
    }
  };
  console.log(userid)
  const handleSelectRow = (userid) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(userid)) {
        return prevSelectedUsers.filter((id) => id !== userid); // Deselect user
      } else {
        return [...prevSelectedUsers, userid]; // Select user
      }
    });
  };

  const handleNextButtonClick = async () => {
    try {
      await Promise.all(selectedUsers.map(async (userid) => {
        await insertUserDetail({
          variables: {
            userid: userid,
            orgn_id: orgid,
            group_id: groupid,
          },
        });
      }));
      console.log('Selected users inserted successfully' );
      setSelectedUsers([]); 
      setSelectAll(false);
    } catch (error) {
      console.error('Error inserting selected users:', error);
    }
  };

  const tableStyle = {
    width: '90%',
    borderCollapse: 'collapse',
  };

  const thStyle = {
    backgroundColor: '#00A5CF',
    color: 'white',
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left',
  };

  const tdStyle = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left',
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
    
    <div
      className="w-[95%]"
      style={{
        overflowX: 'auto', 
        scrollbarWidth: 'none', 
        WebkitOverflowScrolling: 'touch',
        '&::-webkit-scrollbar': {
          height: 0, 
          width: 0, 
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent', 
        },
      }}
    >
        <table className="table-auto min-w-max">
        <thead>
          <tr>
            <th style={thStyle}>
            <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
            </th>
            <th className="text-white p-4"style={thStyle} >Name</th>
              <th className="text-white p-4" style={thStyle}>Email ID</th>
              <th className="text-white p-4" style={thStyle}>Designation</th>
              <th className="text-white p-4" style={thStyle}>Location</th>
          
          </tr>
        </thead>
        <tbody>
          {data.user_organisations.map((row) => (
            <tr key={row?.profiledata?.user_id}>
              <td style={tdStyle}>
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(row?.profiledata?.user_id)}
                  onChange={() => handleSelectRow(row?.profiledata?.user_id)}
                />
              </td>
              <td style={tdStyle}>{row.profiledata?.first_name}</td>
              <td style={tdStyle}>{row.profiledata?.userdata?.email}</td>
              <td style={tdStyle}>{row.profiledata?.userdata?.defaultRole}</td>
              <td style={tdStyle}>{row.profiledata?.city}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <button onClick={handleNextButtonClick}>Next</button>
    </>
  );
};

export default TableComponent;