import React, { useState } from "react";
import { Button, Img, Input } from "../../components";
import { SelectBox } from "components/SelectBox";
import { Heading } from "components/Heading";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useUserId } from "@nhost/react";
import Header from "components/NewHeader/Header";

const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];

const INSERT_USER_DETAIL = gql`
  mutation MyMutation(
    $defaultRole: String = ""
    $displayName: String = ""
    $email: citext = ""
    $passwordHash: String = ""
    $locale: String = ""
  ) {
    insertUsers(
      objects: {
        defaultRole: $defaultRole
        displayName: $displayName
        email: $email
        passwordHash: $passwordHash
        locale: $locale
      }
    ) {
      returning {
        displayName
        id
      }
    }
  }
`;

const INSERT_USERORG_DETAIL = gql`
  mutation MyMutation(
    $orgn_id: bigint = ""
    $uuid: uuid = ""
    $status: String = ""
  ) {
    insert_user_organisations(
      objects: { uuid: $uuid, orgn_id: $orgn_id, status: $status }
    ) {
      returning {
        status
        orgn_id
      }
    }
  }
`;

const INSERT_PROFILE_DETAIL = gql`
  mutation MyMutation(
    $first_name: String = ""
    $city: String = ""
    $organisationid: bigint = ""
    $user_id: uuid = ""
  ) {
    insert_profile_details(
      objects: {
        first_name: $first_name
        city: $city
        organisationid: $organisationid
        user_id: $user_id
      }
    ) {
      returning {
        organisationid

      }
    }
  }
`;

const FETCH_GROUP_DETAIL = gql`
  query MyQuery($_eq: bigint = "", $_eq1: String = "orgadmincreated") {
    group_master(where: { orgn_id: { _eq: $_eq }, _and: { type: { _eq: $_eq1 } } }) {
      groupname
      id
    }
  }
`;

const INSERT_GROUP_DETAIL=gql`
mutation MyMutation($group_id: bigint = "", $orgn_id: bigint = "", $type: String = "", $userid: uuid = "") {
  insert_group_usermapping(objects: {group_id: $group_id, orgn_id: $orgn_id, type: $type, userid: $userid}) {
    returning {
      orgn_id
    }
  }
}
`;


export default function AdduserPage() {
  const userid = useUserId();
  const [droapdown, setDroapdown] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [data, setData] = useState({});
  const [groupid , setGroupid]=useState("");

  const handleChange = (key, value) => {
    setData({
      ...data,
      [key]: value
    });
  };


  const [InsertUserDetail] = useMutation( INSERT_USER_DETAIL, {
    onCompleted: (data) => {
      // console.log("User ID:", data.insert_Users.returning[0].displayName); 
      console.log(data)
      handleAddOrg(data.insertUsers.returning[0].id) 
      // nextuser()
    },
    
    onError: (error) => {
      console.log("error updating profile : ", error);
    },

  });
  
const handleAdd =async  (event) => {

  // signUpEmailPassword(data.email, '123456789', {
  //   displayName: data.displayName,
  // }); 
  try {
    const { data: insertedData } = await InsertUserDetail ({
      variables: {
        displayName:data['displayName'],
        email:data['email'],
        passwordHash:'$2a$10$ZiUoY2QzSUIxP2zWFZFtweEUp/HiqAkxRJeXGHiletTk1eqsdyqT6',
        locale:'en',
        defaultRole:'user'
     
      },
    });
    console.log("instered group header info", insertedData);
  } catch (error) {
    console.error("GraphQL Mutation Error:", error.message);
  }
  // props.setActiveStep((prevActiveStep) => prevActiveStep + 1);

};


const [InsertOrgDetail] = useMutation(INSERT_USERORG_DETAIL, {
onCompleted: (data) => {
  // handleAddpRrofile(data.insertUsers.returning[0].id)
  // console.log("Organization data inserted:", data.insert_user_organisations.returning[0].orgn_id);
console.log(data)

},

onError: (error) => {
  console.log("Error inserting organization data:", error);
},
});

const handleAddOrg = async (id) => {
try {
  const { data: insertedData } = await InsertOrgDetail({
    variables: {
      orgn_id:localStorage.getItem("id"),
      uuid:id,
      status: 'active', 
    },
  });
  handleAddpRrofile(id)
  handleAddGroup (id);

  console.log("Inserted organization data:", insertedData);
} catch (error) {
  console.error("GraphQL Mutation Error:", error.message);
}
};

const [InsertProfileDetail] = useMutation(INSERT_PROFILE_DETAIL, {
onCompleted: (data) => {
  console.log("profiledata",data)
        // console.log("Profile data inserted:", data.insert_user_organisations.returning[0].organisationid);
},


onError: (error) => {
  console.log("Error inserting organization data:", error);
  
},

});

const handleAddpRrofile = async (id) => {
try {
  const { data: insertedData } = await InsertProfileDetail({
    variables: {
      organisationid:localStorage.getItem("id"),
      first_name:data["displayName"],
      city:data["city"],
      user_id:id
    },
  });
  console.log("Inserted Profile data:", insertedData);
} catch (error) {
  console.error("GraphQL Mutation Error:", error.message);
}
}; 
  const [InsertGroupDetail] = useMutation(INSERT_GROUP_DETAIL, {
    onCompleted: (data) => {
      // console.log("Organization data inserted:", data.insert_user_organisations.returning[0].orgn_id);
      console.log(data)
    },
    onError: (error) => {
      console.log("Error inserting organization data:", error);
    },
  });

  const handleAddGroup = async (id) => {
  ; // Log the selected group ID
  
    try {
      // Assuming you need to perform an operation with this group ID
      // And assuming "groupid" is defined elsewhere in your component correctly
      const { data: insertedData } = await InsertGroupDetail({
        variables: {
          orgn_id: localStorage.getItem("id"),
          userid: id, 
          group_id:localStorage.getItem('groupid') , 
        },
      });
     console.log(groupid)
      console.log("Inserted groupmapping data:", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };
  
  // const { loading, error, groupData } = useQuery(FETCH_GROUP_DETAIL, {
  //   variables: {
  //     _eq: localStorage.getItem('id'),
  //     _eq1: 'orgadmincreated'
  //   },
  //   onError: (error) => {
  //     console.log('Error fetching group details: ', error);
  //   },
  //   onCompleted: (data) => {
  //     console.log(data.group_master);
  //     if (data.group_master && data.group_master.length > 0) {
  //       console.log(data.group_master.id);
  //       const dropdownOptions = data.group_master.map((group) => ({
  //         label: group.groupname, // This is what is shown in the dropdown
  //         value: group.id // This should be group.id if you want to print the ID
  //       }));
  //       setDroapdown(dropdownOptions);
  //       setGroupid(dropdownOptions)
        
  //           }
  //   }
  // });
  const[groups,setGroup]=useState([]);
  const [data1, setData1] = useState({});
  const [selectedOrganisation, setSelectedOrganisation] = useState('');
  const [organisation, setSelectOrganisation] = useState([]);
  const handleSelectChange = (event) => {
    console.log(event.target.value)
    setSelectedOrganisation(event.target.value);
      localStorage.setItem('groupid', event.target.value);
  }
 
  const { loading, error, data:groupdata } = useQuery(FETCH_GROUP_DETAIL, {
    variables: {
      _eq: localStorage.getItem('id'),
      _eq1: 'orgadmincreated'
    },
    onError: (error) => {
      console.log('error fetching user details: ', error);
    },
    onCompleted: (data) => {
      console.log("groupname",data.group_master)
      setGroup(data.group_master)
    
    },
    
  });


  return (
    <>
      <div className="flex flex-col items-start justify-start w-full pb-[66px] gap-[47px] md:pb-5 bg-white-A700">
        <div className="flex flex-col items-center justify-start w-full">
          {/* <header className="flex justify-center items-center w-full p-9 sm:p-5 border-light_green-400_01 border-b-[3px] border-solid bg-lime-50">
            <div className="flex flex-row justify-center w-full mx-auto md:px-5 max-w-[1427px]">
              <div className="flex flex-row sm:flex-col justify-between items-center w-full sm:gap-10">
                <div className="flex flex-row justify-between items-center w-auto gap-8">
                  <Img
                    src="images/img_convene_logo.png"
                    alt="convenelogo_one"
                    className="w-[60px] md:h-auto object-cover"
                  />
                  <Heading size="4xl" as="h2">
                    Convene chat
                  </Heading>
                </div>
                <div className="flex flex-row justify-between items-center w-[12%] sm:w-full">
                                  <Img src="images/img_icon.svg" alt="icon_one" className="h-[49px]" />
                  <div className="flex flex-col items-center justify-start w-[44%]">
                    <div className="h-[71px] w-full relative">
                      <Img
                        src="images/img_rectangle_6.png"
                        alt="image"
                        className="h-[68px] w-[68px] top-0 right-0 left-0 m-auto object-cover absolute"
                      />
                      <Button
                        color="light_green_400"
                        size="xs"
                        variant="fill"
                        shape="round"
                        className="w-[21px] bottom-0 right-0 m-auto border-white-A700 border-[5px] border-solid absolute"
                      >
                        <Img src="images/img_group_62.svg" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              </header> */}
              <Header backgroundColor={"#F7FBE1"} border={"3px solid #BAC964"}/>
          
          <div className="flex flex-row justify-start mt-[51px] w-full mx-auto md:px-5 max-w-[1264px]">
            <div className="flex flex-row justify-start w-full">
              <div className="flex flex-col items-start justify-start w-full">
                <Heading as="h1" className="text-[#BAC964]">
                  Add user
                </Heading>
                <Heading size="xl" as="h2" className="mt-[31px]">
                  Personal Details
                </Heading>
                <div className="flex flex-row md:flex-col justify-start sm:w-full mt-6 gap-8 md:gap-5 w-[100%]" >
                  <input
                    color="white_A700"
                    size="sm"
                    variant="fill"
                    shape="round"
                    type="text"
                    value={data.displayName}
                    onChange={(e) => handleChange('displayName', e.target.value)}
                    placeholder="Name"
                    className="w-[32%] md:w-full md:h-auto border-gray-700 border border-solid !rounded-[22px]"
                  />
                  <input
                    color="white_A700"
                    size="sm"
                    variant="fill"
                    shape="round"
                    type="email"
                    value={data.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                    placeholder="E-mail"
                    className="w-[32%] md:w-full md:h-auto border-gray-700 border border-solid !rounded-[22px]"
                  />
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" />}
                    name="selectcategory"
                    placeholder="Gender"
                    options={dropDownOptions}
                    className="w-[32%] md:w-full gap-px border-gray-700 border border-solid"
                  />
                </div>
                <Heading size="xl" as="h3" className="mt-[31px]">
                  Office Details
                </Heading>
                <div className="flex flex-row md:flex-col justify-start sm:w-full mt-6 gap-8 md:gap-5 w-[100%]">
                  <input
                    color="white_A700"
                    size="sm"
                    variant="fill"
                    shape="round"
                    type="text"
                    name="UniqCode"
                    placeholder="UniqCode"
                    className="w-[32%] md:w-full md:h-auto border-gray-700 border border-solid !rounded-[22px]"
                  />
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" />}
                    name="selectcategory"
                    placeholder="Designation"
                    options={dropDownOptions}
                    className="w-[32%] md:w-full gap-px border-gray-700 border border-solid"
                  />
                  <input
                    color="white_A700"
                    size="sm"
                    variant="fill"
                    shape="round"
                    type="text"
                    name="Reporting manager"
                    placeholder="Reporting manager"
                    className="w-[32%] md:w-full md:h-auto border-gray-700 border border-solid !rounded-[22px]"
                  />
                </div>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" />}
                  name="selectcategory"
                  placeholder="Branch"
                  options={dropDownOptions}
                  className="w-[32%] mt-6 gap-px md:w-full border-gray-700 border border-solid"
                />
                <div className="flex flex-row justify-start w-[66%] md:w-full mt-[33px] w-[100%]">
                  <div className="flex flex-col items-start justify-start w-full gap-[22px]">
                    <Heading size="xl" as="h4">
                      Memberships
                    </Heading>
                    <div className="flex flex-row md:flex-col justify-start sm:w-full gap-8 md:gap-5 w-[100%]">
                    <select
        name="organisation"
        className="pl-2 w-100 w-[50%]  md:w-[100%] gap-px border-gray-700 border border-solid  "
        style={{ height: 40,  borderRadius: 50 , color: 'black' }}
        value={selectedOrganisation}
        onChange={handleSelectChange}
      >
        <option value="">Select Group</option>
        {groups.map((item) => (
          <option key={item.id} value={item.id} >
            {item?.groupname}
          </option>
        ))}
      </select>
                      <SelectBox
                        shape="round"
                        indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" />}
                        name="selectcategory"
                        placeholder="Assign role"
                        options={dropDownOptions}
                        className="w-[49%] md:w-full gap-px border-gray-700 border border-solid"
                      />
                    </div>
                  </div>
                </div>
                <Button
                 onClick={(e) => handleAdd(e)} 
                  color="cyan_600"
                  size="sm"
                  variant="fill"
                  className="mt-12 sm:px-5 min-w-[136px] rounded-[18px] sm:min-w-full"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
