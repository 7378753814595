

import React from "react";

import { Img, Line,  Text } from "components";
import { Radio, TextField } from "@mui/material";
import { useNavigate } from "react-router";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const BrowserloginOnePage = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    console.log("Attempting to navigate");
    // Your form submission logic
    // After form submission, navigate to the pricing page
    navigate("/login");
  };

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps = [
    {
      label: 'Public Information',
      content: (
        
        <div className="flex flex-col gap-[30px] items-start justify-start mb-7 mt-[-NaNpx] mx-auto w-[97%] z-[1]">
        <div className="flex flex-col items-center justify-start w-full">
          <div className="flex md:flex-col flex-row md:gap-10 items-start justify-between w-full">
            <div className="flex md:flex-1 flex-col items-left justify-start md:mt-0 mt-[9px] w-[40%] md:w-full">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
                    Organisation Name:
                  </label>
                  <TextField id="organization" variant="standard" />
                </div>
      
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
                    Country:
                  </label>
                  <TextField id="organization" variant="standard" />
                </div>  
      
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
                    Industry Type:
                  </label>
                  <TextField id="organization" variant="standard" />
                </div>
      
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
                    Official Website:
                  </label>
                  <TextField id="organization" variant="standard" />
                </div>
      
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
                    Tax no:
                  </label>
                  <TextField id="organization" variant="standard" />
                </div>
      
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
                    Employee count:
                  </label>
                  <TextField id="organization" variant="standard" />
                </div>
              </div>
            </div>
      
            <div className="flex md:flex-1 flex-col gap-2 justify-start w-[58%] md:w-full">
              <div className="flex flex-row gap-10 items-start justify-start mr-[69px] w-[58%] md:w-full">
                <Text
                  className="mt-0.5 text-gray-700 text-xl"
                  size="txtPoppinsRegular20"
                >
                  Logo
                </Text>
                <div className="bg-blue_gray-100 border border-gray-700 border-solid flex flex-col h-[150px] items-center justify-start p-[61px] md:px-10 sm:px-5 rounded-[20px] w-[150px]">
                  <Img
                    className="h-7 w-7"
                    src="images/img_television.svg"
                    alt="television"
                  />
                </div>
              </div>
              <Text
                className="md:ml-[0] ml-[88px] text-light_green-400 text-sm"
                size="txtPoppinsRegular14"
              >
                Upload from drive, dropbox, etc
              </Text>
            </div>
          </div>
        
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', width: "30%" }}>
        <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', borderRadius: '25px', backgroundColor: "#00A5CF", width: "22%",height:"25px" }}>
          <img src="images/forward.png" alt="arrow" style={{ marginRight: '5px' }} />
          <Text style={{ fontSize: "12px" }}> Next</Text>
        </button>
        </div>
      </div>
      
      
      ),
    },
    {
      label: 'Review plan',
      content:(
        <div className="flex flex-row md:flex-col w-full ">
        <div style={{display: 'flex', flexDirection: 'column'}}>
      <div className="w-72 h-60 border-2 border-black rounded-lg p-8 text-center">
        {/* Black Box */}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', width: "100%",marginTop:"5%" }}>
      <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', borderRadius: '25px', backgroundColor: "#00A5CF", width: "30%",height:"25px" }}>
      <img src="images/forward.png" alt="arrow" style={{ marginRight: '5px' }} />
      <Text style={{ fontSize: "12px" }}> Next</Text>
    </button>
      </div>
      </div>
        <div className="flex flex-col w-full ml-8">
          <div className="flex flex-col w-full">
            <h2 className="text-green-600 text-xl font-bold mb-4">Plan Details</h2>
            <div className="flex flex-row w-full">
              <p className="w-1/4 mb-4">Renewal Date:</p>
              <p className="mb-4">31 January 2014</p>
            </div>
            <div className="flex flex-row w-full">
              <p className="w-1/4 mb-4">Number of Users:</p>
              <p className="w-2/3 mb-4">594 users</p>
            </div>
            <div className="flex flex-row w-full">
              <p className="w-1/4">Inclusive Features:</p>
              <div className="flex flex-row">
                <div className="flex flex-col">
                  <li className="mb-2">Video calling</li>
                  <li className="mb-2">Messaging</li>
                  <li className="mb-2">Channel Management</li>
                </div>
                <div className="flex flex-col ml-8">
                  <li className="mb-2">Channel Building</li>
                  <li className="mb-2">Admin Control</li>
                  <li className="mb-2">Theme Changing</li>
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', width: "30%" }}>
            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', borderRadius: '25px', backgroundColor: "#00A5CF", width: "25%",height:"25px" }}>
              <img src="images/tick.png" alt="arrow" style={{ marginRight: '5px' }} />
              <Text style={{ fontSize: "12px" }}> Veify</Text>
            </button>
            </div>
       
          </div>
          
          </div>
          
        </div>
        
      </div>
      
      
      )
    },
    {
      label: 'Owner login Credintials',
content:(

  <div className="flex flex-col items-start justify-start w-[98%] md:w-full">
  <div className="flex md:flex-col flex-row md:gap-5 items-center justify-start w-full">
  <Text
  className="text-gray-700 text-xl"
  size="txtPoppinsRegular20"
>
  User id
</Text>
<div className="md:h-[30px] h-[31px] md:ml-[0] ml-[100px] relative w-[26%] md:w-full">
  <div className="absolute bg-blue_gray-100 h-[30px] inset-x-[0] mx-auto top-[0] w-full"></div>
  <TextField
  className="absolute bottom-[0] left-[2%] text-gray-700 text-xl"
    size="txtPoppinsRegular20"
    defaultValue="auroradivya@yahoo.com"
    variant="standard"
    InputProps={{
      disableUnderline: true,
      style: { background: "transparent" },
    }}
  />
</div>
<div className="bg-cyan-600 h-2.5 ml-6 md:ml-[0] md:mt-0 my-[19px] rounded-[50%] w-2.5"></div>
<Text
  className="sm:flex-1 ml-8 md:ml-[0] text-base text-cyan-600 w-[54%] sm:w-full"
  size="txtPoppinsRegular16"
>
  This email will be used as recovery email address and related
  activities such as subscription, deleting account, etc
</Text>
</div>
<div className="flex sm:flex-col flex-row sm:gap-10 gap-[73px] items-end justify-start mt-[3px] w-[38%] md:w-full">
<Text
  className="sm:mt-0 mt-1.5 text-gray-700 text-xl"
  size="txtPoppinsRegular20"
>
  Password
</Text>
<div className="h-8 md:h-[34px] mb-1 relative w-[59%] sm:w-full">
  <div className="absolute bg-blue_gray-100 h-[30px] inset-x-[0] mx-auto top-[0] w-full"></div>
  <TextField
    className="absolute bottom-[0] left-[2%] text-gray-700 text-xl"
    size="txtPoppinsRegular20"
    defaultValue="gf#jooo980@01!"
    variant="standard"
    InputProps={{
      disableUnderline: true,
      style: { background: "transparent" },
    }}
  />
</div>
</div>  <Text
    className="mt-[27px] text-gray-700 text-lg"
    size="txtPoppinsRegular18"
  >
    Please log in again and change your default password.
  </Text>
  <Text
    className="mt-4 text-gray-700 text-lg"
    size="txtPoppinsRegular18"
  >
    <span className="text-gray-700 font-poppins text-left font-normal">
      Click here to
    </span>
    <span className="text-gray-700 font-poppins text-left font-normal">
      {" "}
    </span>
    <span className="text-cyan-600 font-poppins text-left font-normal"   onClick={handleSubmit} >
      Login
    </span>
  </Text>
  


</div>


)

    },
  ];

  return (
    <>
      <div className="bg-white-A700 flex flex-col font-poppins items-center justify-end mx-auto p-[31px] sm:px-5 w-full">
        <div className="flex flex-col justify-start max-w-[1444px] mt-0.5 mx-auto md:px-5 w-full">
          <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start w-full">
            {/* Left Side */}
            <div className="flex md:flex-1 flex-row gap-6 items-center justify-start w-[18%] md:w-full">
              <Img
                className="h-[43px] md:h-auto object-cover w-[43px]"
                src="images/img_convenelogo.png"
                alt="convenelogo"
              />
              <Text
                className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700"
                size="txtPoppinsSemiBold25"
              >
                Convene chat
              </Text>
            </div>
            {/* Nav Items */}
            <div className="flex items-center">
              <Text
                className="md:ml-[0] ml-[556px] text-gray-700 text-lg"
                size="txtPoppinsRegular18"
              >
                Demo
              </Text>
              <Text
                className="ml-16 md:ml-[0] text-gray-700 text-lg"
                size="txtPoppinsRegular18"
              >
                Pricing
              </Text>
              <Text
                className="ml-16 md:ml-[0] text-gray-700 text-lg"
                size="txtPoppinsRegular18"
              >
                Features
              </Text>
              <a
                href="javascript:"
                className="md:ml-[0] ml-[68px] text-gray-700 text-lg"
              >
                <Text size="txtPoppinsRegular18">FAQs</Text>
              </a>
              <button 
              className="bg-cyan-600 h-[37px] justify-center mb-1.5 md:ml-[0] ml-[70px] pb-0.5 pt-[7px] sm:px-5 px-[35px] rounded-[18px] text-gray-200 text-lg w-[136px]"
              onClick={handleSubmit}
            >
              <Text size="txtPoppinsRegular18Gray200">Log in</Text>
            </button>
            
            </div>
          </div>
          <Text
            className="mt-[59px] text-3xl sm:text-[26px] md:text-[28px] text-cyan-600"
            size="txtPoppinsSemiBold30"
          >
            Welcome to Convene chat!
          </Text>
          <Text
            className="mt-2.5 text-2xl md:text-[22px] text-light_green-400 sm:text-xl"
            size="txtPoppinsRegular24"
          >
            Please help us set up your organisation
          </Text>

          <Box sx={{marginTop:3,width:"80%",alignItem:"center",justifyContent:"flex-start",alignSelf:"center"}}>

          <Stepper activeStep={activeStep} orientation="vertical" sx={[
            { ".css-1hv8oq8-MuiStepLabel-label.Mui-completed":{fontWeight:"bold",color: '#8CB369'}},
            { ".css-ahj2mt-MuiTypography-root":{fontSize:"22px"}},
            { ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {color: '#8CB369'}},
            { ".css-1hv8oq8-MuiStepLabel-label.Mui-active": {color: '#8CB369'}},
            { ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {color: '#8CB369'}}
          ]}>
          {steps.map((step, index) => (
            <Step key={step.label}> 
              <StepLabel>
                <div>
                  <Typography  >{step.label}</Typography>
                  <div>
                    {activeStep === index && step.subSteps && step.subSteps.map(subStep => (
                      <div key={subStep.subLabel}>
                        <Typography variant="subtitle1" style={{ marginTop: 5 }}>{subStep.subLabel}</Typography>
                        {subStep.content}
                      </div>
                    ))}
                  </div>
                </div>
              </StepLabel> 
              <StepContent>
                {step.content}
                <Box sx={{ mt: 2 }}>
                  <div>
                    <Button
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1, fontSize: 10 }}
                    >
                      {index === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
                    </Stepper>
          
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};




export default BrowserloginOnePage;



// <div style={{ display: 'flex', flexDirection: "column", gap: '20px' }}>
// <div style={{ display: 'flex', alignItems: 'center' }}>
//   <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
//     Organisation Name:
//   </label>
//   <TextField id="organization" variant="standard" />
// </div>

// <div style={{ display: 'flex', alignItems: 'center' }}>
//   <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
//     Country:
//   </label>
//   <TextField id="organization" variant="standard" />
// </div>

// <div style={{ display: 'flex', alignItems: 'center' }}>
//   <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
//     Industry Type:
//   </label>
//   <TextField id="organization" variant="standard" />
// </div>

// <div style={{ display: 'flex', alignItems: 'center' }}>
//   <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
//     Official Website:
//   </label>
//   <TextField id="organization" variant="standard" />
// </div>

// <div style={{ display: 'flex', alignItems: 'center' }}>
//   <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
//     Tax no:
//   </label>
//   <TextField id="organization" variant="standard" />
// </div>

// <div style={{ display: 'flex', alignItems: 'center' }}>
//   <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
//     Employee count:
//   </label>
//   <TextField id="organization" variant="standard" />
// </div>
// </div>
