import React from "react";

const sizes = {
  "3xl": "text-3xl font-semibold",
  "2xl": "text-2xl font-semibold",
  xl: "text-xl font-semibold",
  "4xl": "text-[35px] font-semibold",
  s: "text-sm font-semibold",
  md: "text-base font-semibold",
  xs: "text-xs font-bold",
  lg: "text-lg font-semibold",
};

const Heading = ({ children, className = "", size = "3xl", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-gray-700 font-poppins ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
