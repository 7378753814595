import React, { useEffect, useState } from 'react';
import ChatListItem from '../../components/GroupSetting/AdminGroup';
import PersonalGroup from '../../components/GroupSetting/PersonalGroup';
import Orgnav from '../../components/GroupSetting/Orgnav';
import CallingNotification from '../../components/Convenecall/CallingNotification'; // Import the CallingNotification component
import { gql, useQuery, useSubscription } from '@apollo/client';
import { useUserId } from '@nhost/react';
import { useParams } from 'react-router-dom';
const GET_ORGANISATION_NAMES = gql`
query MyQuery($_eq: bigint = "71") {
  organisation_master(where: {id: {_eq: $_eq}}) {
    name
    logo_file
  }
}
`;
const GET_ORGANISATION_GROUPS = gql`
query getallgroups($_eq: uuid = "", $_eq1: bigint = "", $_eq2: String = "active", $_eq3: String = "orgadmincreated") {
  group_usermapping(where: {userid: {_eq: $_eq}, _and: {orgn_id: {_eq: $_eq1}, _and: {status: {_eq: $_eq2}, _and: {groupdata: {type: {_eq: $_eq3}}}}}}) {
    group_id
    groupdata {
      groupname
      id
      orgn_id
      created_user_id
      groupprofile_img
      last_messageingroup
    }
  }
}
`;

const GET_ORGANISATION_PERSONAL_GROUPS = gql`
query getgroupsusercreated($_eq: bigint = "", $_eq1: String = "active", $_neq: [String!] = ["orgadmincreated", "Businessorgadmincreated", "businessmultgrp"], $_eq2: uuid = "") {
  group_usermapping(where: {orgn_id: {_eq: $_eq}, status: {_eq: $_eq1}, groupdata: {type: {_nin: $_neq}}, _and: {userid: {_eq: $_eq2}}}) {
    groupdata {
      groupname
      id
      groupprofile_img
      last_messageingroup
      type
      receiverdata {
        custom_username
        user_id
        first_name
      }
      senderdata {
        custom_username
        first_name
        user_id
      }
    }
  }
}
`;

const GET_CALL_NOTIFICATION = gql`
subscription MySubscription($_eq: Boolean = true, $_eq1: uuid = "") {
  notification_master(where: {group_calling_status: {_eq: $_eq}, userid: {_eq: $_eq1}}) {
    Group_calling_name
    call_url_users
    id
    userid
    meeting_id
  }
}
  `;
const ChatList = () => {
  // const userid = useUserId();
  const {id , userid} =useParams()
  useEffect(()=>{
localStorage.setItem('userid', userid);
localStorage.setItem('orgnid', Number(id));
  },[true || id])
  const [chatListData, setChatListData] = useState([]);
  const [NavData, setNavData] = useState([]);
  const [Navimg, setNavimg] = useState([]);
  const [orgPersonalChat, setOrgPersonalChat] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCallingPopupVisible, setIsCallingPopupVisible] = useState(false);
  const [calldata , setCalldata] = useState([]);
// let calldata;

  const { loading:liveloading, error:liveerror, data: live ,} = useSubscription(GET_CALL_NOTIFICATION, {
    variables: {
    
      _eq1:userid
    },
    onData: ({ data }) => {
      console.log("data is" , data.data.notification_master[0])
      // calldata=data.data.notification_master;
      setCalldata(data.data.notification_master)
      if(data.data.notification_master.length > 0){
        // setModalVisisble(true)
        setIsCallingPopupVisible(true)
      }
      else if(data.data.notification_master.length === 0 || data.data.notification_master.length  === 'undefined'){
        // setModalVisisble(false)
        setIsCallingPopupVisible(false)
      }
   
  },

    onError:(error)=>{
      console.log("error for the personal chat live",error)
    }
  });


  const { loading1, error1, data: groupsdata1  } = useQuery(GET_ORGANISATION_NAMES, {
    variables: {
      _eq: id,
      
    },
    onCompleted: (data) => {
      console.log(data,"ord name")
      setNavData(data.organisation_master[0].name);
      setNavimg(data.organisation_master[0].logo_file);

    },
    onError: (error) => {
      console.log("Error fetching groups", error);
    }
  });

  const { loading, error, data: groupsdata, refetch } = useQuery(GET_ORGANISATION_GROUPS, {
    variables: {
      _eq: userid,
      _eq1: id
    },
    onCompleted: (data) => {
      setChatListData(data?.group_usermapping || []);
    },
    onError: (error) => {
      console.log("Error fetching groups", error);
    }
  });


  const { data: personalgrp, refetch: personalrefetch } = useQuery(GET_ORGANISATION_PERSONAL_GROUPS, {
    variables: {
      _eq2: userid,
      _eq: id
    },
    onCompleted: (data) => {
      setOrgPersonalChat(data?.group_usermapping || []);
    },
    onError: (error) => {
      console.log("Error fetching personal groups", error);
    }
  });
  

  useEffect(() => {
    refetch();
    personalrefetch();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrgPersonalChat = searchQuery.trim() !== ''
    ? orgPersonalChat.filter(chat => {
        const searchText = searchQuery.toLowerCase();
        const userName = chat?.groupdata?.type === "orgusrgrp" || chat?.groupdata?.type === "orgmultusrgrp"
          ? chat?.groupdata?.groupname.toLowerCase()
          : chat?.groupdata?.senderdata?.custom_username.toLowerCase();
        return userName.includes(searchText);
      })
    : orgPersonalChat;
    console.log(filteredOrgPersonalChat ,"personal chat data")

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden mt-5">
      <Orgnav
        setOrgPersonalChat={setOrgPersonalChat}
        orgPersonalChat={orgPersonalChat}
        handleSearchChange={handleSearchChange}
        name={NavData}
        icon={Navimg}  
          
         
      />
      <div className='flex flex-col mt-5 items-center justify-center'>
        {chatListData.map((chat, index) => (
          <ChatListItem key={index} chat={chat} />
        ))}
      </div>
      <div className='flex flex-col mt-5 items-center justify-center'>
        {filteredOrgPersonalChat.map((personalchat, index) => (
          <PersonalGroup key={index} personalchat={personalchat} />
        ))}
      </div>
      {/* <h2 onClick={() => setIsCallingPopupVisible(true)} style={{cursor: 'pointer'}}>Open Calling Popup</h2> */}
      {isCallingPopupVisible && (
        <CallingNotification 
          modalVisible={isCallingPopupVisible} 
          setModalVisible={setIsCallingPopupVisible} 
          live={{ 
            notification_master: [{
              id: "some-id",
              Group_calling_name: "Group Call",
              meeting_id: "meeting-id",
              call_url_users: "https://example.com/call"
            }]
          }}
          calldata={calldata}
        />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
      )}
    </div>
  );
};

export default ChatList;
