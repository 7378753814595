import { gql, useMutation } from '@apollo/client';
import { Checkbox, TextField } from '@mui/material'
import { useSignUpEmailPassword, useUserId } from '@nhost/react';
import { Img, Text } from 'components';
import React, { useState } from 'react'


const UPDATE_ROLE_DETAIL = gql`
mutation updateuserrole($_eq: uuid = "", $defaultRole: String = "") {
  updateUsers(where: {id: {_eq: $_eq}}, _set: {defaultRole: $defaultRole}) {
    returning {
      defaultRole
    }
  }
}
`;
export default function Createadmin(props) {
  const userid=useUserId();
  const [data , setData] = useState({}) ;
    const [isAdmin, setIsAdmin] = React.useState(false);
    const {
      signUpEmailPassword,
      isLoading,
      isSuccess,
      needsEmailVerification,
      isError,
      error:signuperror,
    } = useSignUpEmailPassword();
     
    const handleChange = (key , value)=>{
      console.log(key,value)
      setData(
        {
       ...data,
          [key]: value
        }
      )
      }
      const [UpdateRoleDetail] = useMutation( UPDATE_ROLE_DETAIL, {
        onCompleted: (data) => {
          console.log("some data updated");
          // nextuser()
        },
        onError: (error) => {
          console.log("error updating profile : ", error);
        },
      });
    const handleCheckboxChange = async (e) => {
      if (e && e.target) {
      setIsAdmin(e.target.checked);
      try {
        const { data: insertedData } = await UpdateRoleDetail ({
          variables: {
           _eq: userid,
            defaultRole:'admin'
         
          },
        });
        console.log("instered group header info", insertedData);
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    }
    };
  
    const handleNext =  (event) => {
      if (!isAdmin && (!data.displayName || !data.email)) {
        alert('Please fill in the admin name and email, or check the checkbox.');
        return;
      }
      if (isAdmin) {
        handleCheckboxChange();
      } else {
        signUpEmailPassword(data.email, '123456789', {
          displayName: data.displayName,
        }); 
      }
      
    
      
     
      
      
   if(isSuccess){
    console.log("entered");
    
  }
  if(isError){
    console.log("error");
    
  }
  event.preventDefault();
      props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };


   
  return (
    <form  style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
   <div style={{display: 'flex', flexDirection: 'Row',alignItems:"center"}}>
   <Checkbox
   checked={isAdmin}
   onChange={(e) => handleCheckboxChange(e)}  
   color="primary"
 />
 <text>I am the admin for this organisation</text>
   </div>
   {!isAdmin&& (
    <>
    <div style={{ display: 'flex', alignItems: 'center' }}>
    
    <label htmlFor="currentPassword" style={{width:"10%"}}> Admin Name</label>
    <TextField
    style= {{ background: 'transparent', backgroundColor:"#D9D9D9",marginLeft:"30px"}}

        className="text-gray-700 text-xl w-[30%]"
        size="txtPoppinsRegular20"
      value={data.displayName}
        variant="standard"
        onChange={(e)=>handleChange('displayName',e.target.value)}
        InputProps={{
          disableUnderline: true,
          style: { background: 'transparent',backgroundCol:"#D9D9D9",marginLeft:5 },
        }}
      />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <label htmlFor="currentPassword" style={{width:"10%"}}> Admin email</label>
    <TextField
    style= {{ background: 'transparent', backgroundColor:"#D9D9D9",marginLeft:"30px"  }}

        className="text-gray-700 text-xl w-[30%]"
        size="txtPoppinsRegular20"
       value={data.email}
       onChange={(e)=>handleChange('email',e.target.value)}
        variant="standard"
        InputProps={{
          disableUnderline: true,
          style: { background: 'transparent',backgroundCol:"#D9D9D9",marginLeft:5 },
        }}
      />
      <button
   onClick={(e) => handleNext(e)}
        className="cursor-pointer h-[30px] leading-[normal] md:ml-[0] text-center text-lg w-[96px] ml-5"
        style={{
            backgroundColor: '#8CB369', 
            border: 'none',            
            borderRadius: "20px",
            color: 'white',             
            cursor: 'pointer',           
            outline: 'none',
            display: 'flex',            // Add flex display
            alignItems: 'center',       // Center items vertically
            justifyContent: 'space-evenly'    // Center content horizontally
        }}
        shape="round"
    >
        <Text style={{ fontSize: "14px", color: 'white' }}>Send Invite</Text>
       
    </button>
      
    </div>
    </>
    )}
     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left',gap:10,marginTop:10}}>

  <button
   onClick={(e) => handleNext(e)}
        className="cursor-pointer h-[30px] leading-[normal] md:ml-[0] text-center text-lg w-[96px] "
        style={{
            backgroundColor: '#00A5CF', 
            border: 'none',            
            borderRadius: "20px",
            color: 'white',             
            cursor: 'pointer',           
            outline: 'none',
            display: 'flex',            // Add flex display
            alignItems: 'center',       // Center items vertically
            justifyContent: 'space-evenly'    // Center content horizontally
        }}
        shape="round"
    >
        <Text style={{ fontSize: "14px", color: 'white' }}>Next</Text>
        <Img src="images/forward.png" alt="arrow" style={{  }} />
    </button>
  <button   className="cursor-pointer h-[30px] leading-[normal] md:ml-[0] text-center text-lg w-[116px]"
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', padding: '10px', borderRadius: '25px', border: '1px solid #8CB369',marginLeft: '5px' }}>
    <img src="images/tick.png" alt="tick" style={{  }} />
    <Text style={{ fontSize: "12px" }}>  Save Stage </Text>
  </button>
</div>
 
    </form>
  )
}
