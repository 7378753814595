import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Button, Checkbox } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';

const GET_USERTOADD_PERSONALCHAT = gql`
query MyQuery($orgnId: bigint!, $status: String!, $excludeUserIds: [uuid!]) {
  user_organisations(where: { orgn_id: { _eq: $orgnId }, status: { _eq: $status }, uuid: { _nin: $excludeUserIds } }) {
    uuid
    profiledata {
      custom_username
      first_name
      userdata {
        avatarUrl
      }
    }
  }
}`;

const ADD_USER_TO_GROUP = gql`
mutation MyMutation($objects: [group_usermapping_insert_input!]!) {
  insert_group_usermapping(objects: $objects) {
    returning {
      id
    }
  }
}`;

const AddMemberModal = ({ open, onClose, potentialMembers, onAddMember, groupid, orgnid, groupUsers }) => {
  const groupUserIds = groupUsers.map(user => user.userid);
  const [usersToAddGroup, setUsersToAddGroup] = useState([]);
  const [checkedStates, setCheckedStates] = useState({});
  const groupUserMappings = [];

  const { loading, error, data: groupsdata, refetch } = useQuery(GET_USERTOADD_PERSONALCHAT, {
    variables: {
      orgnId: orgnid,
      status: "active",
      excludeUserIds: groupUserIds
    },
    onCompleted: (data) => {
      setUsersToAddGroup(data.user_organisations);
    },
    onError: (error) => {
      console.log("error for the personal chat", error);
    }
  });
  console.log(usersToAddGroup,"add member")

  const [addusertogroup, { loading: inserting, error: errorinserting, data: insertedata }] = useMutation(ADD_USER_TO_GROUP, {
    onError: (error) => {
      console.log("error for the personal chat", error);
    },
    onCompleted: (data) => {
      onClose(); // Close the modal when the mutation completes
    }
  });

  const handleAddUser = async () => {
    for (const [id, value] of Object.entries(checkedStates)) {
      if (value === true) {
        const mapping = {
          orgn_id: orgnid,
          group_id: groupid,
          status: 'active',
          userid: id
        };
        groupUserMappings.push(mapping);
      }
    }

    try {
      await addusertogroup({
        variables: {
          objects: groupUserMappings
        },
      });
    } catch (error) {
      console.log("error while adding user to group", error);
    }
  };

  useEffect(() => {
    if (usersToAddGroup.length > 0) {
      const initialCheckedStates = {};
      usersToAddGroup.forEach(item => {
        initialCheckedStates[item.uuid] = false;
      });
      setCheckedStates(initialCheckedStates);
    }
  }, [usersToAddGroup]);

  const handleCheckBoxToggle = (uuid) => {
    setCheckedStates(prevState => ({
      ...prevState,
      [uuid]: !prevState[uuid]
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px 24px' }}>
        <h2 style={{ color: '#616161', fontSize: '1.25rem', fontWeight: 'bold', fontFamily: 'Poppins, sans-serif' }}>Add Member</h2>
        <Button onClick={onClose} style={{ minWidth: 'auto', padding: 0, zIndex: 10 }}>X</Button>
      </div>
      <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
        {usersToAddGroup.map((member, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px 24px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={ member?.profiledata?.  avatarUrl=== undefined ? require('../../assets/images/gorupplaceholder.png') : `https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${member?.profiledata?.avatarUrl}`} style={{ resize:'both',height:"20px",width:"20px"}}  />
              <span style={{ marginLeft: '10px' }}>{member?.profiledata?.custom_username || member?.profiledata?.first_name}</span>
            </div>
            <Checkbox
              checked={checkedStates[member.uuid]}
              onClick={() => handleCheckBoxToggle(member.uuid)}
              color="primary"
            />
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <Button
          onClick={handleAddUser}
          style={{ backgroundColor: '#00A5CF', color: 'white', padding: '10px 20px', borderRadius: '10px' }}
        >
          Add Member
        </Button>
      </div>
    </Dialog>
  );
};

export default AddMemberModal;
