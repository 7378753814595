

import React, { useState } from "react";

import { Button, Img, Input, Text } from "components";

import { Heading } from "components/Heading";
import { gql, useMutation, useQuery } from "@apollo/client";
import Header from "../../components/NewHeader/Header";
import { useUserId } from "@nhost/react";
const FETCH_GROUP_DETAIL = gql`
query MyQuery($_eq: bigint = "") {
  organisation_master(where: {id: {_eq: $_eq}}) {
    name
    employee_count
    location
  
  }
}
`;
const Update_ORG_DETAIL = gql`
mutation MyMutation($_eq: bigint = "", $name: String = "", $employee_count: bigint = "", $location: String = "") {
  update_organisation_master(where: {id: {_eq: $_eq}}, _set: {name: $name, employee_count: $employee_count, location: $location}) {
    returning {
      name
    }
  }
}
`;  
const OrganizationchannelmanagementpagePage = () => {
 const userid=useUserId();
  const [changesMade, setChangesMade] = useState(false);
  
  // const handleChange = (field, value) => {
  //   setFormData(prev => ({ ...prev, [field]: value }));
  //   setChangesMade(true);
  // };
    const { loading, error, data } = useQuery(FETCH_GROUP_DETAIL, {
      variables: {
        _eq: localStorage.getItem('id'),
      },
      onError: (error) => {
        console.log('Error fetching group details: ', error);
      },
    });
  
    // Print the fetched data on console
    console.log("Fetched organization profile data:", data);

  const [isEditable, setIsEditable] = useState(false);

  const toggleEdit = () => {
    setIsEditable(!isEditable);
    if (!isEditable) {
      // When turning on edit mode, reset the changes detection
      setChangesMade(false);
    }
  };

  const [UpdateOrgDetail] = useMutation(Update_ORG_DETAIL, {
    onCompleted: (data) => {
    
    },
    onError: (error) => {
      console.log("Error updating profile data:", error);
    },
  });
  const handleSave = async () => {
    try {
      await UpdateOrgDetail({
        variables: {
          _eq:userid ,
          name:data["name"],
          employee_count:data["employee_count"],
          location:data["location"]
        },
      });
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };
  return (
    <>
  
    <div className="flex flex-col items-center justify-start w-full pb-[53px] gap-[50px] md:pb-5 bg-white-A700">
      {/* <header className="flex justify-center items-center w-full p-9 sm:p-5 border-[#BD3246] border-b-[3px] border-solid bg-[#FFECDA]">
        <div className="flex flex-row justify-center w-full mx-auto md:px-5 max-w-[100%]">
          <div className="flex flex-row sm:flex-col justify-between items-center w-full sm:gap-10">
            <div className="flex flex-row justify-between items-center w-auto gap-8">
              <Img
                src="images/img_convene_logo.png"
                alt="convenelogo_one"
                className="w-[60px] md:h-auto object-cover"
              />
              <Heading size="4xl" as="h2">
                Convene chat
              </Heading>
            </div>
            <div className="flex flex-row justify-between items-center w-[12%] sm:w-full">
              <Img src="images/img_icon.svg" alt="icon_one" className="h-[49px]" />
              <div className="flex flex-col items-center justify-start w-[44%]">
                <div className="h-[71px] w-full relative">
                  <Img
                    src="images/img_rectangle_6.png"
                    alt="image"
                    className="h-[68px] w-[68px] top-0 right-0 left-0 m-auto object-cover absolute"
                  />
                  <Button 
                    color="light_green_400"
                    size="xs"
                    variant="fill"
                    shape="round"
                    className="w-[21px] bottom-0 right-0 m-auto border-white-A700 border-[5px] border-solid absolute"
                  >
                    <Img src="images/img_group_62.svg" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>"" */}
     <Header backgroundColor={"#FFECDA"} border={"3px solid #BD3246"}/>
      <div className="flex flex-row justify-center w-full md:px-5 max-w-[1427px] h-full" >
        <div className="flex flex-col items-start justify-start w-full">
          <div className="flex flex-row sm:flex-col justify-between items-start w-full sm:gap-10">
            <Heading as="h1" className="!text-red-700">
              Organisation Profile
            </Heading>
            <div className="rounded-[18px] items-center justify-center min w-[110px]" style={{border :"2px solid #8CB369"}}>
            <button
              className="   px-6 py-2 flex items-center justify-center gap-2.5 "
              onClick={toggleEdit} 
          >
              <Img src="images/img_icon_gray_700_11x11.svg" alt="Icon" />
              {isEditable ? 'Cancel' : 'Edit'}
            </button>
            {isEditable && changesMade && (
            <button   >
              Save
            </button>
          )}
          </div>
          
          </div>
          <div className="flex flex-row justify-start w-[92%] mt-[55px] h-full poisition-fixed  md:w-full " >
            <div className="flex flex-row md:flex-col justify-start items-center w-full  h-full  md:gap-5 ">
              <div className="flex flex-col items-center justify-start h-full w-[32%] md:w-full "style={{border:'2px solid black'}}>
                <Heading size="2xl" as="h2">
                  Organisation name :
                </Heading>
                <Heading size="2xl" as="h3" className="mt-8">
                  Organisation address :
                </Heading>
                <Heading size="2xl" as="h3" className="mt-8">
                  Employee count :
                </Heading>
                <Heading size="2xl" as="h3" className="mt-7">
                  Plan name :
                </Heading>
                <Heading size="2xl" as="h3" className="mt-8">
                  Start date :
                </Heading>
              </div>
              <div className="flex flex-col items-start justify-start w-[66%] md:w-full  h-full relative" style={{border:'2px solid black'}}>
              {isEditable ? (
                    <Input value={data?.organisation_master[0]?.name} />
                  ) : (
                    <Text size="2xl" as="p" className=" !text-gray-700">
                      {data?.organisation_master[0]?.name}
                    </Text>
                  )}
                {isEditable ? (
                  <Input defaultValue={data?.organisation_master[0]?.location}/>
                ) : (
                  <Text size="2xl" as="p" className=" mt-8 !text-gray-700">
                       {data?.organisation_master[0]?.location}
                  </Text>
                )}
                {isEditable ? (
                  <Input defaultValue={data?.organisation_master[0]?.employee_count}/>
                ) : (
                  <Text size="2xl" as="p" className=" mt-8 !text-gray-700">
                       {data?.organisation_master[0]?.employee_count}
                  </Text>
                )}
                <Text size="2xl" as="p" className=" mt-16 !text-gray-700">
                  Premium plan
                </Text>
                <div className="flex flex-row sm:flex-col justify-between items-center w-full sm:gap-10">
                  <Text size="2xl" as="p" className="mt-8 !text-gray-700">
                    15/5/24
                  </Text>
                  <div className="flex flex-row sm:flex-col justify-between items-center w-auto mt-8 sm:gap-10">
                    <Heading size="2xl" as="h4">
                      End date :
                    </Heading>
                    <Text size="2xl" as="p" className="!text-gray-700">
                      1/11/23
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Heading as="h3" className="mt-[63px] !text-red-700">
            Plan Details
          </Heading>
          <div className="flex flex-row md:flex-col justify-between items-center w-[51%] md:w-full mt-[58px] md:gap-10">
            <Heading size="2xl" as="h4">
              Billing Id :
            </Heading>
            <Text size="2xl" as="p" className="!text-gray-700">
              dummyemail@gmail.com
            </Text>
          </div>
          <div className="flex flex-row justify-between w-[16%] md:w-full mt-[22px]">
            <Img src="images/img_icon_gray_700_36x32.svg" alt="icon_three" className="h-[36px] mb-0.5" />
            <Heading size="2xl" as="h4">
              Change Plan
            </Heading>
          </div>
        </div>
      </div>
      <Button >
        save
      </Button>
    </div>
  </>
);  
}


export default OrganizationchannelmanagementpagePage;