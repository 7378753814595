import React, { useEffect, useState } from 'react'
import { Img, Line, List, Text } from "components";
import ProgressBar from './ProgressBar';
import LinearWithValueLabel from './ProgressBar';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router';
export default function CommunicationDetails() {
  const navigate = useNavigate();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 960);
      };
  
      handleResize();
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    const handleSubmit = () => {
      
      console.log("Attempting to navigate");
    // Your form submission logic
    // After form submission, navigate to the pricing page
     
      navigate("/Verification");
   
    }
  const toggleNav = () => {
    
    setIsNavOpen(!isNavOpen);
  };
  return (
    <div className="bg-white-A700 flex flex-col font-poppins items-center justify-start mx-auto p-[34px] sm:px-5 w-full " >
    <div className="flex flex-col items-center justify-start max-w-[1444px] mb-5 mx-auto md:px-5 w-full">
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '50%', gap: '10px', alignItems: 'center' }}>
        <Img
          className="h-[43px] md:h-auto object-cover w-[43px]"
          src="images/img_convenelogo.png"
          alt="convenelogo"
        />
        <Text
          className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700 flex-shrink-0"
          size="txtPoppinsSemiBold25"
        >
          Convene chat
        </Text>
      </div>
      <Img
        onClick={toggleNav}
        className="md:h-[15px] md:ml-[0] ml-[29px] object-cover"
        src="images/Threeline.png"
        alt="idea"
      />
      {isNavOpen && (
        <div
          style={{
            position: "fixed",
            top: 60,
            right: 0, 
            width: "30%", 
            background: "white",
            height: "40%", 
             display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent:'center',
           
            padding: "20px",
            boxSizing: "border-box",
            
          }}
        > <Text size="txtPoppinsRegular18">Demo</Text>
        <Text size="txtPoppinsRegular18">Pricing</Text>
        <Text size="txtPoppinsRegular18">Features</Text>
        <Text size="txtPoppinsRegular18">FAQs</Text>
        <Text size="txtPoppinsRegular18">Sign up</Text>
        </div>
      )}
    </div>

    </div>
    <div style={{ display:"flex",width:"50%",alignItems:"center",justifyContent:"center",alignSelf:"center",color:"#00A5CF" }}>
    <p style={{fontSize:'22px',fontWeight:"bold",textAlign:"center"}}>Welcome convene chat!</p>
   
  </div>
<div style={{marginTop:"2%",marginBottom:20}}>
  <Text 
  className="text-base text-gray-700"
  size="txtPoppinsLight12"
>
please Enter Details
</Text>
</div>
<div style={{ display:"flex",alignItems:"left",justifyContent:"left",alignSelf:"left",width:"100%" }}>
<LinearWithValueLabel initialValue={25} />

</div>
<div className="flex md:flex-1 flex-col items-left justify-start md:mt-5 mt-[9px] w-[60%] md:w-full">
<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem',marginBottom:10 }}>
  <div style={{ display: 'flex',  flexDirection: 'column' }}>
    <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
      Organisation Name:
    </label>
    <TextField
    style={{ 
      background: 'transparent',
      backgroundColor: '#D9D9D9',
      width: '300px', // Set the width as needed
      height: '30px', // Set the height as needed
      justifyContent: 'center'
    }}
    className="text-gray-700 text-xl"
    size="txtPoppinsRegular14"
    defaultValue="gf#jooo980@01!"
    variant="standard"
    InputProps={{
      disableUnderline: true,
      style: { background: 'transparent', marginLeft: 5 ,alignItems: 'center',justifyContent: 'center',},
    }}
  />
    
  </div>

  <div style={{ display: 'flex',  flexDirection: 'column' }}>
    <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
      Country:
    </label>
    <TextField
    style={{ 
      background: 'transparent',
      backgroundColor: '#D9D9D9',
      width: '300px', // Set the width as needed
      height: '30px', // Set the height as needed
      justifyContent: 'center'
    }}
    className="text-gray-700 text-xl"
    size="txtPoppinsRegular14"
    defaultValue="gf#jooo980@01!"
    variant="standard"
    InputProps={{
      disableUnderline: true,
      style: { background: 'transparent', marginLeft: 5 ,alignItems: 'center',justifyContent: 'center',},
    }}
  />
  </div>

  <div style={{ display: 'flex',  flexDirection: 'column' }}>
    <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
      Industry Type:
    </label>
    <TextField
    style={{ 
      background: 'transparent',
      backgroundColor: '#D9D9D9',
      width: '300px', // Set the width as needed
      height: '30px', // Set the height as needed
      justifyContent: 'center'
    }}
    className="text-gray-700 text-xl"
    size="txtPoppinsRegular14"
    defaultValue="gf#jooo980@01!"
    variant="standard"
    InputProps={{
      disableUnderline: true,
      style: { background: 'transparent', marginLeft: 5 ,alignItems: 'center',justifyContent: 'center',},
    }}
  />
  </div>

  <div style={{ display: 'flex',  flexDirection: 'column'}}>
    <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
      Official Website:
    </label>
    <TextField
    style={{ 
      background: 'transparent',
      backgroundColor: '#D9D9D9',
      width: '300px', // Set the width as needed
      height: '30px', // Set the height as needed
      justifyContent: 'center'
    }}
    className="text-gray-700 text-xl"
    size="txtPoppinsRegular14"
    defaultValue="gf#jooo980@01!"
    variant="standard"
    InputProps={{
      disableUnderline: true,
      style: { background: 'transparent', marginLeft: 5 ,alignItems: 'center',justifyContent: 'center',},
    }}
  />
  </div>

  <div style={{ display: 'flex',  flexDirection: 'column' }}>
    <label htmlFor="organization" style={{ width: '170px', marginRight: '10px' }}>
      Tax no:
    </label>
    <TextField
    style={{ 
      background: 'transparent',
      backgroundColor: '#D9D9D9',
      width: '300px', // Set the width as needed
      height: '30px', // Set the height as needed
      justifyContent: 'center'
    }}
    className="text-gray-700 text-xl"
    size="txtPoppinsRegular14"
    defaultValue="gf#jooo980@01!"
    variant="standard"
    InputProps={{
      disableUnderline: true,
      style: { background: 'transparent', marginLeft: 5 ,alignItems: 'center',justifyContent: 'center',},
    }}
  />
  </div>  
  </div></div>
  <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', borderRadius: '25px', backgroundColor: "#00A5CF", width: "30%",height:"40px" }} onClick={handleSubmit}   >
  <Text style={{ fontSize: "12px",marginRight: '5px',color:"#EEEEEE" }}> Next</Text>
  
  <img src="images/forward.png" alt="arrow" style={{marginLeft:"5PX"  }} />
 
</button>
    </div>  
  );
}

