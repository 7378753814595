import { Img, Text } from 'components';
import React, { useState } from 'react';
import Uploadreport from './Uploadreport';
import { TextField } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useSignUpEmailPassword, useUserId } from '@nhost/react';

const INSERT_USER_DETAIL = gql`
mutation MyMutation($defaultRole: String = "", $displayName: String = "", $email: citext = "", $passwordHash: String = "", $locale: String = "") {
  insertUsers(objects: {defaultRole: $defaultRole, displayName: $displayName, email: $email, passwordHash: $passwordHash, locale: $locale}) {
    returning {
      displayName
     id
    }
  }
}
`;

const INSERT_USERORG_DETAIL = gql`
mutation MyMutation($orgn_id: bigint = "", $uuid: uuid = "", $status: String = "") {
  insert_user_organisations(objects: {uuid: $uuid, orgn_id: $orgn_id, status: $status}) {
    returning {
      status
      orgn_id
    }
  }
}
`;
const INSERT_PROFILE_DETAIL = gql`
mutation MyMutation($first_name: String = "", $city: String = "", $organisationid: bigint = "", $user_id: uuid = "") {
  insert_profile_details(objects: {first_name: $first_name, city: $city, organisationid: $organisationid, user_id: $user_id}) {
    returning {
      organisationid
    }
  }
}

`;

export default function Uploadinfo(props) {
  const userid=useUserId();
  const [isUploaded, setIsUploaded] = useState(false);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [data , setData] = useState({}) ;
  const handleSeeReport = () => {
    setIsTableVisible(true);
  };

  // const {
  //   signUpEmailPassword,
  //   isLoading,
  //   isSuccess,
  //   needsEmailVerification,
  //   isError,
  //   error:signuperror,
  // } = useSignUpEmailPassword();
   
    
  const handleChange = (key , value)=>{
    console.log(key,value)
    setData(
      {
     ...data,
        [key]: value
      }
    )
    }

   

     const [InsertUserDetail] = useMutation( INSERT_USER_DETAIL, {
        onCompleted: (data) => {
          console.log("User ID:", data.insertUsers.returning[0].id); 
          
          handleAddOrg(data.insertUsers.returning[0].id) 
          // nextuser()
        },
        
        onError: (error) => {
          console.log("error updating profile : ", error);
        },

      });
      
    const handleAdd =async  (event) => {
      if (!data.displayName || !data.email) {
        alert('Please fill in the admin name and email, or check the checkbox.');
        return;
      }
      // signUpEmailPassword(data.email, '123456789', {
      //   displayName: data.displayName,
      // }); 
      try {
        const { data: insertedData } = await InsertUserDetail ({
          variables: {
            displayName:data['displayName'],
            email:data['email'],
            passwordHash:'$2a$10$ZiUoY2QzSUIxP2zWFZFtweEUp/HiqAkxRJeXGHiletTk1eqsdyqT6',
            locale:'en',
            defaultRole:'user'
         
          },
        });
        console.log("instered group header info", insertedData);
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
      // props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    
  };
 

  const [InsertOrgDetail] = useMutation(INSERT_USERORG_DETAIL, {
    onCompleted: (data) => {
      // handleAddpRrofile(data.insertUsers.returning[0].id)
      console.log("Organization data inserted:", data.insert_user_organisations.returning[0].orgn_id);
    },
    onError: (error) => {
      console.log("Error inserting organization data:", error);
    },
  });
  
  const handleAddOrg = async (id) => {
    try {
      const { data: insertedData } = await InsertOrgDetail({
        variables: {
          orgn_id:localStorage.getItem("id"),
          uuid:id,
          status: 'active', 
        },
      });
      handleAddpRrofile(id)

      console.log("Inserted organization data:", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };
  
  const [InsertProfileDetail] = useMutation(INSERT_PROFILE_DETAIL, {
    onCompleted: (data) => {
      
            console.log("Profile data inserted:", data.insert_user_organisations.returning[0].organisationid);
    },
    

    onError: (error) => {
      console.log("Error inserting organization data:", error);
      
    },

  });
  
  const handleAddpRrofile = async (id) => {
    try {
      const { data: insertedData } = await InsertProfileDetail({
        variables: {
          organisationid:localStorage.getItem("id"),
          first_name:data["displayName"],
          city:data["city"],
          user_id:id
        },
      });
      console.log("Inserted Profile data:", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  }; 
      
  //  if(isSuccess){
  //   console.log("entered");
    
  // }
  // if(isError){
  //   console.log("error");
    
  // }
  // event.preventDefault();
    
  
const handleNext=()=>{
  props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
}
  const handleUpload = () => {
    // Perform the upload logic here
    // For demonstration purposes, setting isUploaded to true after 2 seconds
    setTimeout(() => {
      setIsUploaded(true);
    }, 2000);
  };
  

  return ( <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
    <div style={{ width: '70%', display: 'flex', flexDirection: 'row' }}>
   
       
          <div style={{ width: 100, height: 30, backgroundColor: '#00A5CF', borderRadius: 14, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Img src="images/Download.png" alt="newitem" style={{ height: '13px', marginRight: '5px' }} />
            <Text style={{ fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#FFFFFF' }}>Download</Text>
          </div>
          <div style={{ width: 100, height: 30, backgroundColor: '#00A5CF', borderRadius: 14, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: '2%' }} onClick={handleUpload}>
            <Img src="images/Upload.png" alt="newitem" style={{ height: '13px', marginRight: '5px' }} />
            <Text style={{ fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#FFFFFF' }}>Upload</Text>
          </div>
          <Text style={{ marginLeft: '5%', color: '#00A5CF' }}>
            <li>Upload user information in given templates only</li>
          </Text>
          </div>
          <div style={{ display: 'flex', alignItems: 'center',marginTop:'3%', }}>
    
          <label htmlFor="currentPassword" style={{width:"13%"}}> Users Name:</label>
          <TextField
          style= {{ background: 'transparent', backgroundColor:"#D9D9D9",marginBottom: "10px" }}
      
              className="text-gray-700 text-xl"
              size="txtPoppinsRegular20"
            value={data.displayName}
              variant="standard"
              onChange={(e)=>handleChange('displayName',e.target.value)}
              InputProps={{
                disableUnderline: true,
                style: { background: 'transparent',backgroundCol:"#D9D9D9",marginLeft:5 },
              }}
            />
            </div>
            <div style={{ display: 'flex', alignItems: 'center',marginBottom: "20px" }}>
            <label htmlFor="currentPassword" style={{width:"13%"}}>Users email:</label>
          <TextField
          style= {{ background: 'transparent', backgroundColor:"#D9D9D9"}}
      
              className="text-gray-700 text-xl"
              size="txtPoppinsRegular20"
             value={data.email}
             onChange={(e)=>handleChange('email',e.target.value)}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: { background: 'transparent',backgroundCol:"#D9D9D9",marginLeft:5 },
              }}
            />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', width: "30%" }}>
            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', borderRadius: '25px', backgroundColor: "#00A5CF", width: "25%",height:"25px" }} onClick={(e) => handleAdd(e)}  >
           
            <Text style={{ fontSize: "12px" }}> Add user</Text>
          </button>
           
            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', borderRadius: '25px', backgroundColor: "#00A5CF", width: "25%",height:"25px",marginLeft:"10px" }} onClick={(e) => handleNext(e)}  >
            <img src="images/forward.png" alt="arrow" style={{ marginRight: '5px' }} />
            <Text style={{ fontSize: "12px" }}> Next</Text>
          </button>
          </div>
 
          {/*  {!isUploaded && (
  )}
      {isUploaded && (
        <div style={{  }}>
          <Uploadreport />
        </div>
      )}
      */}
    </div>
  );
}
