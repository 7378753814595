import { Img, Text } from 'components';
import React, { useState } from 'react';
import UserinfoHeader from '../../components/Header/UserinfoHeader';
import Report from './Report';

export default function Uploadinfo() {
    const [isUploaded, setIsUploaded] = useState(false);
    const [isTableVisible, setIsTableVisible] = useState(false);
  
    const handleSeeReport = () => {
      setIsTableVisible(true);
    };
  
    const handleUpload = () => {
      // Perform the upload logic here
      // For demonstration purposes, setting isUploaded to true after 2 seconds
      setTimeout(() => {
        setIsUploaded(true);
      }, 2000);
    };
  return (
    <>
    <UserinfoHeader />
    {!isUploaded && (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Text style={{display:"flex",alignItems:"left",width:"90%",marginBottom:'25px',color:"#00A5CF",fontSize:"22px",fontWeight:"bold"}}>Upload User Data</Text>
      
        <div style={{ width: '80%', display: 'flex', flexDirection: 'row',justifyContent:"space-evenly",alignItems:'left',marginBottom:10 }}>
          <div style={{ width: '40%', height: '35px', backgroundColor: '#00A5CF', borderRadius: '25px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }} onClick={handleUpload}>
            <Img src="images/Download.png" alt="Download" style={{ height: '13px', marginRight: '5px' }} />
            <Text style={{ fontSize: '12px', color: '#FFFFFF' }}>Download</Text>
          </div>
          <button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px',
                                borderRadius: '25px',
                                border: '1px solid #8CB369',
                                width: '40%',
                                height: '35px',
                                marginBottom:10
                            }}
                        >
                            <img src="images/tick.png" alt="tick" style={{ marginRight: '5px' }} />
                            <Text style={{ fontSize: "12px" }}>Save Stage</Text>
                        </button>
         
        </div>
        <Text style={{ fontSize: '14px', color: '#00A5CF', textAlign: 'left',width:"60%" }}>
        Upload user information in given templates only
      </Text>
      </div>
    )}
      {isUploaded && (
        <div style={{  }}>
          <Report />
        </div>
      )}
    </>
  );
}