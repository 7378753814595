

import React from "react";

import { Img, Line,  Text } from "components";
import { Radio, TextField } from "@mui/material";
import { useNavigate } from "react-router";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Roleaccess from'./Roleaccess';
import Uploadinfo from'./Uploadinfo';


const   SystemStepper = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    console.log("Attempting to navigate");
    // Your form submission logic
    // After form submission, navigate to the pricing page
    navigate("/login");
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [data, setData] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps = [
    {
      label: 'Role and Access Control',
      content:(
        <Roleaccess
        data={data}
        setData={setData}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    
      )
    },
        
      
    {
      label: 'Upload Usser Information',
      content:(
        <Uploadinfo
        data={data}
        setData={setData}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />)
    },
    {
      label: 'Owner login Credintials',

    },
  ];

  return (
    <>
      
       
            
          <Box sx={{}}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 2 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    {step.content}
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                      
                      
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1,fontSize: 10 }}
                        >
                          {index === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                        
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )}
          </Box>
       
    </>
  );
};

export default SystemStepper;
