import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const FORM_UPDATE_API = gql`
mutation MyMutation($Form_data: jsonb = "", $_eq: bigint = "") {
    update_Form_api_data(where: {id: {_eq: $_eq}}, _set: {Form_data: $Form_data}) {
      affected_rows
    }
  }
  
`;

export default function Formshower() {
  const [data, setData] = useState({});
  const location = useLocation();
  const [formArray, setFormArray] = useState(location.state.fdata);
  const [editMode, setEditMode] = useState(null);
  console.log(location.state.fdata,'fdata')
  const navigate = useNavigate();
//   console.log(location.state.formdata.Form_Name)
  const handleChange = (key, value) => {
    if (key === "radio") {
      const newRadioState = {};
      newRadioState[key] = value;
      setData({ ...data, ...newRadioState });
    } else {
      setData({ ...data, [key]: value });
    }
  }
  const [updateForm] = useMutation(FORM_UPDATE_API, {
    onCompleted: (data) => {
      // console.log("User final ID:", data);
      alert("Done!");
      window.location.reload();
      navigate(-1);
    
    },
    onError: (error) => {
      console.log("error updating profile : ", error);
    },
  });

  const handleEditChange = (index, key, value) => {
    const newArray = [...formArray];
    newArray[index][key] = value;
    setFormArray(newArray);
  }

  const handleImagepicker = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
      let file = event.target.files[0];
      console.log("this is first image : ", file);
    };
    input.click();
  }

  const toggleEditMode = (index) => {
    setEditMode(editMode === index ? null : index);
  }

//   console.log(data);

    const handleSubmitData = async () => {
        try {
          const { data: insertedData } = await updateForm({
            variables: {
                _eq: location.state.formdata.id,
                Form_data: formArray,
              
            },
          });
     
          console.log("inserted user info", insertedData);
        } catch (error) {
          console.error("GraphQL Mutation Error:", error.message);
        }
      }


  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <p style={{fontSize:23 , color:'#00A5CF' , fontWeight:600}}>{location.state.formdata.Form_Name}</p>
      {formArray?.map((item, index) => (
        <div key={index} style={{ width: '90%', display: 'flex', flexDirection: 'column', marginTop: 10, border: '2px solid #D9D9D9', borderRadius: 10, boxShadow: 'unset', padding: 10 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <p style={{ color: '#00A5CF', fontSize: 17, margin: 8 }}>{item.label}</p>
            <button onClick={() => toggleEditMode(index)}>Edit</button>
          </div>
          {editMode === index ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <input
                type="text"
                placeholder="Label"
                value={item.label}
                onChange={(e) => handleEditChange(index, 'label', e.target.value)}
                style={{ marginBottom: 10 }}
              />
              <input
                type="text"
                placeholder="Placeholder"
                value={item.placeholder}
                onChange={(e) => handleEditChange(index, 'placeholder', e.target.value)}
                style={{ marginBottom: 10 }}
              />
              <input
                type="text"
                placeholder="Value Data"
                value={item.valuedata}
                onChange={(e) => handleEditChange(index, 'valuedata', e.target.value)}
                style={{ marginBottom: 10 }}
              />
            </div>
          ) : (
            <>
              {item.type === "textfield" && (
                <input
                  type="text"
                  style={{ width: '90%', height: 50, color: 'black', borderRadius: 5, backgroundColor: '#EBEBEB', marginBottom: 10 }}
                  placeholder={item.placeholder}
                  onChange={(e) => handleChange(item.valuedata, e.target.value)}
                  value={data[item.valuedata]}
                />
              )}
              {item.type === "image" && (
                <div style={{ width: '90%', height: 50, color: 'black', borderRadius: 5, backgroundColor: '#EBEBEB', marginBottom: 10, display: 'flex', alignItems: 'center' }} onClick={handleImagepicker}>
                  <div style={{ width: '40%', height: '70%', backgroundColor: 'grey', marginLeft: 10, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: '#FFFFFF' }}>Choose File</p>
                  </div>
                </div>
              )}
              {item.type === "date" && (
                <input
                  type="date"
                  style={{ width: '90%', height: 50, color: 'black', borderRadius: 5, backgroundColor: '#EBEBEB', marginBottom: 10 }}
                  onChange={(e) => handleChange(item.valuedata, e.target.value)}
                  value={data[item.valuedata]}
                />
              )}
              {item.type === "radio" && (
                <div style={{ flexDirection: 'column', marginBottom: 10, width: '90%' }}>
                  {item.options.map((option, optionIndex) => (
                    <label key={optionIndex} style={{ display: 'flex', alignItems: 'center', marginVertical: 10 }}>
                      <input
                        type="radio"
                        name={item.valuedata}
                        value={option.label}
                        checked={data[item.valuedata] === option.label}
                        onChange={() => handleChange(item.valuedata, option.label)}
                        style={{ marginRight: 10 }}
                      />
                      <span style={{ color: '#616161' }}>{option.label}</span>
                    </label>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      ))}
      <div style={{ width: '40%', height: 40, alignSelf: 'flex-end', marginRight: 20, borderRadius: 20, borderWidth: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5, marginBottom: 5, borderColor: '#00A5CF', backgroundColor: 'white' }}
      onClick={()=>{handleSubmitData()}}
      >
        <p style={{ color: '#00A5CF', fontWeight: 700 }}>Submit</p>
      </div>
    </div>
  );
}
