import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import { useFileUpload, useNhostClient, useUserDisplayName } from '@nhost/react';
import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useParams,  } from 'react-router-dom';
import ConveneOutgoing from '../Convenecall/ConveneOutgoing'

// GraphQL queries

const GET_USER_GROUPS = gql`
query MyQuery(
  $_eq: uuid = "", 
  $_eq1: uuid = "", 
  $_eq2: String = "personalpersonal"
) {
  group_master(
    where: {
      _or: [
        {created_user_id: {_eq: $_eq}, personal_chatid: {_eq: $_eq1}, _and: {type: {_eq: $_eq2}}},
        {created_user_id: {_eq: $_eq1}, personal_chatid: {_eq: $_eq}, _and: {type: {_eq: $_eq2}}}
      ]
    }
  ) {
    id
    status
    created_user_id
    receiverdata {
      custom_username
      first_name
      userdata {
        avatarUrl
      }
    }
    senderdata {
      custom_username
      first_name
      userdata {
        avatarUrl
      }
    }
  }
}

  `;

const GET_ALL_CHAT_MEESAGES = gql`
query MyQuery($_eq: bigint = "", $_eq1: uuid = "") {
  chat_seen_message(where: {group_id: {_eq: $_eq}, _and: [{_or: [{delivered: {_eq: $_eq1}}, {seen: {_eq: $_eq1}}]}]}) {
    message_id
    id
    chat_message {
      message
      file_url
      status
      type
      sent_id
      created_at
      group_id
      senderdata {
        avatarUrl
        displayName
      }
    }
  }
}

  `;
  const GET_GROUPIMAGE = gql`
  query getgroupimage($_eq: bigint = "") {
    group_master(where: {id: {_eq: $_eq}}) {
      groupprofile_img
    }
  }
  `;

  const GET_LIVE_CHAT_MEESAGES = gql`
  subscription livequery($_eq: bigint = "", $_eq1: uuid = "", $_gt: bigint = "") {
    chat_seen_message(where: {group_id: {_eq: $_eq}, _and: [{_or: [{delivered: {_eq: $_eq1}}, {seen: {_eq: $_eq1}}]}], message_id: {_gt: $_gt}}) {
      message_id
      id
      chat_message {
        message
        file_url
        status
        type
        sent_id
        created_at
        group_id
        senderdata {
          avatarUrl
          displayName
        }
      }
    }
  }
  
  `;
  const INSERT_MESSAGE = gql`
  mutation insertmessage($group_id: bigint = "", $sent_id: uuid = "", $message: String = "", $type: String = "", $status: String = "active") {
    insert_chat_messages(objects: {group_id: $group_id, sent_id: $sent_id, message: $message, type: $type, status: $status}) {
      returning {
        message
        status
        type
        id
      }
    }
  }
  `;
  const INSERT_CONTENT = gql`
  mutation insertimageinchat($group_id: bigint = "", $file_url: String = "", $sent_id: uuid = "", $status: String = "type", $type: String = "content") {
    insert_chat_messages(objects: {group_id: $group_id, file_url: $file_url, sent_id: $sent_id, status: $status, type: $type}) {
      returning {
        created_at
        file_url
        group_id
        id
      }
    }
  }
  `;
  const UPDATE_LAST_MESSAGE = gql`
  mutation insertlastmessage($last_messageingroup: String = "", $_eq: bigint = "") {
    update_group_master(where: {id: {_eq: $_eq}}, _set: {last_messageingroup: $last_messageingroup}) {
      returning {
        last_messageingroup
      }
    }
  }
  
  `;
  const GROUP_MEMBERS = gql`
  query getallgrpusr($_eq: bigint = "") {
    group_usermapping(where: {group_id: {_eq: $_eq}}) {
      userid
      usersdata {
        custom_username
        first_name
      }
    }
  }
  `;
  const INSERT_MESSAGE_FOR_CHAT = gql`
  mutation MyMutation($chat_seen_messages: [chat_seen_message_insert_input!]!) {
    insert_chat_seen_message(objects: $chat_seen_messages) {
      returning {
        id
        delivered
      }
    }
  }
`;
const GET_GROUP_FORMS = gql`
query GetFormName($_eq: bigint = "", $_eq1: bigint = "") {
  Form_api_data(where: {orgn_id: {_eq: $_eq}, _and: {group_id: {_eq: $_eq1}}}) {
    Form_Name
    id
  }
}
`;

const DELETE_MESSAGE_FROM_CHAT = gql`
mutation MyMutation($_eq: bigint = "", $deleted: uuid = "", $delivered: uuid = null, $seen: uuid = null) {
  update_chat_seen_message(where: {id: {_eq: $_eq}}, _set: {deleted: $deleted, delivered: $delivered, seen: $seen}) {
    returning {
      id
    }
  }
}
`;
const GROUP_CALLING_NOTIFICATIONMASTER = gql`
mutation MyMutation($objects: [notification_master_insert_input!]!) {
  insert_notification_master(objects: $objects) {
    returning {
      group_calling_status
    }
  }
}
`;


// ExternalChatScreen component
const ExternalChatScreen = (route) => {
  const scrollViewRef = useRef();
  const location = useLocation();
  const { chat } = location.state || {};
  const {intiater , receiver } = useParams()
  // console.log(intiater ,receiver)
  // const navigation = useNavigation();
  const [isModalVisible, setModalVisible] = useState(false);

  // const userid=useUserId();
  const currentUserId =intiater;
  const nhost = useNhostClient();
  const [lastMessageId , setLasteMessageId] = useState(0)
  const [allmessagesuserid , setAllMessagesUserid] = useState({})
  const [callmodalvisible , setCallmodalVisible] = useState(false)
  const [apiresponse , setApiResponse] = useState({})
  const [messagePosition, setMessagePosition] = useState({ x: 0, y: 0 });
  const [count , setCount] = useState(0);
  const [groupdata , setgroupdata] = useState([])
// let count = 0
  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    progress,
    id: cover_img_path,
    bucketId,
    isError,
    error
  } = useFileUpload();
// Nhost.auth.refressession()
// use this if we get error as istrusted false
  const [currenttime, setCurrentTime]=useState('')
  // if (__DEV__) {  // Adds messages only in a dev environment
  //   loadDevMessages();
  //   // console.log("inside dev" ,loadDevMessages(),loadErrorMessages())
  //   loadErrorMessages();
  // }
// console.log("groupid", route.params.groupdata.groupdata.id)

  useEffect(()=>{
    const currentDateTime = new Date(); // This will give you the current date and time
    const current_time = currentDateTime.toISOString();
    // console.log("current time" , current_time)
    setCurrentTime(current_time)
  },[route])
  // console.log(isUploaded , isError , error , cover_img_path)
  const { loading:getusergp, error:getusergper, data: getusergpgroupsdata ,refetch:getusergprefetch} = useQuery(GET_USER_GROUPS, {
    variables: {
      _eq:intiater,
      _eq1:receiver
    },
    onCompleted:(data)=>{
      console.log("data for chat groups ",data.group_master);
      setgroupdata(data.group_master)
    },
    onError:(error)=>{
      console.log("error for the personal chat",error)
    }
  });

  // const imagevalue= require("../images/wallpaper4.jpg");
  console.log(groupdata[0]?.id)
  const [messages, setMessages] = useState([]);
  // console.log(chat.groupdata , currentUserId)
  const { loading, error:er, data: groupsdata ,refetch} = useQuery(GET_ALL_CHAT_MEESAGES, {
    variables: {
      _eq:groupdata[0]?.id,
      _eq1:currentUserId 
    },
    onCompleted:(data)=>{
      // setChatListData(data.group_usermapping)
      // console.log("data for chat groups ",data.chat_seen_message);
      setMessages(data?.chat_seen_message)
    },
    onError:(error)=>{
      console.log("error for the personal chat",error)
    }
  });
  // let imageid = '';
  

  // const [gotImageId , setGotImageId]=useState('')
  // const {  data: profileimage , refetch:getimageagian} = useQuery(GET_GROUPIMAGE, {
  //   variables: {
  //     _eq:route.params.groupdata.groupdata.id,
  //     // _eq1:route?.params?.orgnisation?.orgn_id
  //   },
  //   onCompleted:(data)=>{
  //     // setChatListData(data.group_usermapping)
  //     // setMessages(data.chat_messages)
  //     setGotImageId(data.group_master[0].groupprofile_img);
  //     // console.log("data for chat groups ",data.group_master[0].groupprofile_img);
  //   },
  //   onError:(error)=>{
  //     console.log("error for the personal chat",error)
  //   }
  // });
  // console.log(lastMessageId)
  // console.log(chat.group_id)
  useEffect(() => {
    if (messages && messages.length > 0) {
      // Set the last message initially
      // console.log("okay",messages[messages.length - 1].message_id)
      // setLastMessage(messages[messages.length - 1]);
      // console.log(messages[messages.length - 1].id)
      setLasteMessageId(messages[messages.length - 1].message_id)
    }
  }, [count || messages]);
  const { loading:liveloading, error:liveerror, data: live ,} = useSubscription(GET_LIVE_CHAT_MEESAGES, {
    variables: {
      _eq:groupdata[0]?.id,
      _gt:lastMessageId,
      _eq1:currentUserId 
    },
    onData: ( {data} ) => {
      console.log(lastMessageId ,"lastmessage id")
      console.log("data is" , data)
      if (data && data?.data?.chat_seen_message) {
        const newMessages = data?.data?.chat_seen_message;
        // Merge new messages with existing ones
        console.log(data?.data?.chat_seen_message[0]?.message_id ," lastest message data ")
        if(data?.data?.chat_seen_message[0]?.message_id !== undefined){
          console.log("when not undefined")
          setLasteMessageId(data?.data?.chat_seen_message[0]?.message_id)
        }
        // setLasteMessageId
        setMessages(prevMessages => [...prevMessages, ...newMessages]);
        // unsubscribe();
        // console.log("New messages received:", newMessages);
      } else {
        console.log("Received invalid subscription data:", data);
      }
  },

    onError:(error)=>{
      console.log("error for the personal chat live",error)
    }
  });

 
  const [insertmessage , { loading:inserting, error:errorinserting, data: insertedata }] = useMutation(INSERT_MESSAGE, { 
    onError:(error)=>{
      // console.log("error for the personal chat",error)
    },
    onCompleted:(data)=>{
      // console.log("message data",data.insert_chat_messages.returning[0].id)
      setCount(count+1)
      // count = count+ 1;
      console.log(count)
      setLasteMessageId(data.insert_chat_messages.returning[0].id)
      insertChatSeenMessages(data.insert_chat_messages.returning[0].id)
      handleendlastmessage({type:"text"})
    }
  });

  const [deletemessage , { loading:delet, error:errordelet, data: deletedata }] = useMutation(DELETE_MESSAGE_FROM_CHAT, { 
    onError:(error)=>{
      console.log("error for the personal chat",error)
    },
    onCompleted:(data)=>{
      // console.log(data.update_chat_seen_message.returning)
      setModalVisible(false)
      setMessages(messages.filter(msg => msg.id !== selectedMessageid));
      
     
    }
  });

  const [insernoti , { loading:noti, error:errornoti, data: notiedata }] = useMutation(GROUP_CALLING_NOTIFICATIONMASTER, { 
    onError:(error)=>{
      console.log("error for the personal chat",error)
    },
    onCompleted:(data)=>{
      console.log('done') 
    }
  });

  const [updatelastmessage , {  error:errorinsertinglastmessage}] = useMutation(UPDATE_LAST_MESSAGE, { 
    onError:(error)=>{
      console.log("error for the personal chat",error)
    },
    onCompleted:(data)=>{
      // console.log("last message updated")
    }
  });
  const [insertChatSeenMessage, { data }] = useMutation(INSERT_MESSAGE_FOR_CHAT);

  const [insertcontent] = useMutation(INSERT_CONTENT, { 
    onError:(error)=>{
      console.log("error for the personal chat",error)
    },
    onCompleted:(data)=>{
      // console.log("content inserted data ",data.insert_chat_messages.returning[0].id)
      insertChatSeenMessages(data.insert_chat_messages.returning[0].id)

      handleendlastmessage({type:"image"})
    }
  });
 
  const [chatListData , setChatListData] = useState([])
  // const [modalVisible, setModalVisible] = useState(false);

  const {  data: groupMembersData} = useQuery(GROUP_MEMBERS, {
    variables: {
      _eq:groupdata[0]?.id,
      _eq1:localStorage.getItem("orgnid")
    },
    onCompleted:(data)=>{
      // console.log("users data",data.group_usermapping , chat.groupname)
      const Allusersid = data.group_usermapping.map(member => {
        // Check if the member's user ID is different from the current user's ID
        if (member["currentUserId "] !== currentUserId ) {
          return {
            Group_calling_name: chat.groupdata.groupname,
            userid: member["userid"],
            call_group_id: groupdata[0]?.id,
            group_calling_status: true,
            // Assuming you need to associate each message with a user
          };
        }
        // If the user ID matches, return null to exclude it from the array
        return null;
      }).filter(Boolean); // Filter out null values
      console.log(Allusersid)  
      setAllMessagesUserid(Allusersid);
      setChatListData(data.group_usermapping)
     
    },
    onError:(error)=>{
      console.log("error for the personal chat",error)
    }
  });
  // console.log(route?.params?.groupdata?.groupdata?.id)
  // const { name } = route.params || { name };
  const [newMessage, setNewMessage] = useState('');
  const [selectedMessage, setSelectedMessage] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const insertChatSeenMessages = (id) => {
    // console.log(id,"message")
    // if (!groupMembersData || !groupMembersData.group_usermapping) return;
      // console.log(groupMembersData.group_usermapping)
      const chatSeenMessages = [
        {
          message_id: id,
          delivered: intiater,
          group_id: groupdata[0].id
        },
        {
          message_id: id,
          delivered: receiver,
          group_id: groupdata[0].id
        }
      ];
  // console.log("all users mapped",chatSeenMessages)
    // Execute the mutation
    insertChatSeenMessage({
      variables: { chat_seen_messages: chatSeenMessages },
    }).then(() => {
      console.log("Chat seen messages inserted successfully.");
    }).catch((error) => {
      console.log("Error inserting chat seen messages:", error);
    });
  };
  const selectImage = async () => {
    // settempImage(true)
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
        let file = event.target.files[0]
      console.log("this is first image : ", file)

      
  await upload({ file })
    };
    input.click();
 
  }
  let countp=false;
  if(isUploaded && countp==false && cover_img_path != undefined){
    // handleContent()
    countp=true;
  }

  useEffect(()=>{
    const handleContent =async () => {
      if(cover_img_path != undefined || cover_img_path != null){
       console.log("image id" , cover_img_path)

    try {
        const { data: insertedData } = await insertcontent({
          variables: {
            group_id: groupdata[0]?.id,
            sent_id: currentUserId,
            file_url: cover_img_path,
            type:"content",
          },
        });
      } catch (error) {
        console.log("error while booking event", error);
      }
    }
    
      // setMessages([...messages, newMessageObj]);
      setNewMessage('');
   
      // Scroll to the bottom after adding a new message
      scrollViewRef?.current?.scrollToEnd({ animated: true });
    };
    handleContent();
  },[isUploaded && countp && !(cover_img_path == undefined)])

//  console.log(selectedMessage)
  const formatTimestamp = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${period}`;
  };
 
  const handleSend =async () => {
    if (newMessage.trim() === '') return;
    
    try {
      const { data: insertedData } = await insertmessage({
        variables: {
          group_id: groupdata[0]?.id,
          sent_id: currentUserId ,
          message: newMessage,
          type:"text",
        },
      });
    } catch (error) {
      console.log("error while booking event", error);
    }
  
    // setMessages([...messages, newMessageObj]);
    setNewMessage('');

    // scrollViewRef.current.scrollToEnd({ animated: true });
  };
  const [selectedMessageid ,setSelectedMessageid] = useState(0)

  const handleendlastmessage =async (type) => {
    // console.log(type.type,'response')
    try {
      const { data: insertedData } = await updatelastmessage({
        variables: {
          _eq: groupdata[0]?.id, 
          last_messageingroup:type.type=="image"?"image": newMessage,
        },
      });
    } catch (error) {
      console.log("error while last message updating", error);
    }
 
  };
 
  const handleDeleteMessage = async() => {
    console.log(selectedMessageid)
    try {
      const { data: deletedata } = await deletemessage({
        variables: {
          _eq: selectedMessageid,
          deleted: currentUserId ,
         
        },
      });
    } catch (error) {
      console.log("error while booking event", error);
    }
  };

  const handleInsertnotification =async (objectsToInsert) => {
    console.log(objectsToInsert,'response')
    try {
      const { data: insertedData } = await insernoti({
        variables: {
          objects:objectsToInsert, 
        },
      });
    } catch (error) {
      console.log("error while last message updating", error);
    }
 
  };
 
  const handleOptionPress = (option) => {
    if (option === 'reply') {
      // Handle reply
    } else if (option === 'forward') {
      // Handle forward
    } else if (option === 'delete') {
      setMessages(messages.filter((msg) => msg.id !== selectedMessage.id));
    }
 
    setSelectedMessage(null);
  };
 
  // useEffect(() => {
   
  //        refetch();
  //        getimageagian(); // Assuming this is another refetch call
  //       // Other necessary operations after refetching data
  // getformsagain();
     
  
    // Cleanup function
   
  // }, [true]); 

  //   useFocusEffect(
  //     useCallback(() => {
  //       // This function will be called when the screen comes into focus
  //       refetch();
  //       getimageagian(); 
  // getformsagain();

  //       // reobserve();
  //       // console.log("usecallback is called ");
  //     }, [true])
  //   )
 
  // const handleSelectWallpaper = (selectedWallpaper) => {
  //   setBackgroundImage(selectedWallpaper);
  // };
 
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
    const habdletogglePopup = (event) => {
    // const { pageX, pageY, locationX, locationY, width, height } = event?.nativeEvent;
  
    setPopupVisible(!isPopupVisible);
  };
  const handleOptionPress1 = (option) => {
    setPopupVisible(!isPopupVisible);
    console.log(`Option pressed: ${option}`);
  };
  const handleHeaderPress = () => {

    // navigation.navigate('groupSetting',{ name ,imageid : route?.params?.imageid,type:'group',groupid:route?.params?.groupdata?.group_id ,group2id:route?.params?.groupdata?.groupdata?.id, orgnid :route?.params?.orgnid});
    // console.log(name);
  };
 const handleMessageClick =(item,event , message)=>{
  console.log(item)
  setSelectedMessageid(item.id)
  // console.log(message);
  setSelectedMessage(message);

  // setModalPosition({ x: event.nativeEvent.pageX, y: event.nativeEvent.pageY });
  setModalVisible(true);
 }
 const [formArrya , setFormArray] = useState([])
 
 const {  data: organisationcount , refetch:getformsagain } = useQuery(GET_GROUP_FORMS, {
  variables: {
    _eq:localStorage.getItem('orgnid'),
    _eq1:groupdata[0]?.id,
  },
  onCompleted:(data)=>{
    console.log("data form got ",data.Form_api_data.length);
    setFormArray(data.Form_api_data)
    // setOrgdata([...orgdata,...data.user_organisations])
  },
  onError:(error)=>{
    console.log(error ,'yes')
  }
});
const handleShowForm = (item) =>{
  // console.log(item.id)
  // navigation.navigate("Form" , {Formid : item.id , groupid:route?.params?.groupdata?.group_id})

}

// const handleCall = async ()=>

const displayname = useUserDisplayName()

const handleCall =async()=>{
  setModalVisible(true)
console.log("calling ",allmessagesuserid)

try {
  const response = await fetch('https://portalapi.convenevc.com/api/join/instant', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "MeetingName": chat.groupdata.groupname,
      "OrganizationID": 115566,
      "displayName": "abc",
      "email": "connect@convenevc.com",
    })
  });

  // Check if response is okay (status 200)
  if (response.ok) {
    const responseData = await response.json(); // Parse response JSON
    console.log('Response from API:', responseData);
    // const updatedUserIds = allmessagesuserid.map(user => ({
    //   ...user, // Spread the existing properties of the user object
    //   call_url_users: responseData.meetingDetails.MemberUrl // Add the link property with your desired value
    // }));
    const objectsToInsert = allmessagesuserid.map(user => ({
      Group_calling_name: user.Group_calling_name,
      call_group_id: user.call_group_id,
      group_calling_status: user.group_calling_status,
      call_url_users: responseData.meetingDetails.MemberUrl,
      userid: user.userid,
      seen:'pp',
      meeting_id: responseData.meetingDetails.CustomerMeetingId

    }));
    console.log('Response from API:', objectsToInsert);
    handleInsertnotification(objectsToInsert)
    setApiResponse(responseData)
    setCallmodalVisible(true)
  } else {
    console.error('Failed to send verification email. Status:', response.status);
    const errorText = await response.text(); // Get error message from response
    console.error('Error message:', errorText);
  }
} catch (error) {
  console.error('Error sending verification email:', error);
}
}


const handleclsoemodal = ()=>{
  setModalVisible(false)
}
// console.log(groupdata[0].created_user_id === intiater)
  return (
    <div className="flex flex-col h-screen bg-[#c3c3c3]">
      <div
        className="flex items-center justify-center p-4 mt-4 cursor-pointer mb-2 bg-[#FFFCEE]"
        style={{
          width: '100%',
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'row',
          height: '70px',
        }}
      >
        <div className='flex w-10'>
          <img 
            className="h-[20px] md:h-auto object-cover w-[20px]"
            src="images/BackArrow.png"
            alt="Back Arrow"
          />
        </div>
        <div
          className="flex-shrink-0 w-12 h-12 flex items-center justify-center text-2xl"
          style={{
            backgroundColor: '#D9D9D9',
            border: '2px solid #c3c3c3',
            borderRadius: '10px',
          }}
        >
                     <img src={ `https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${groupdata[0]?.created_user_id === intiater ? groupdata[0]?.receiverdata?.userdata?.avatarUrl : groupdata[0]?.senderdata?.userdata?.avatarUrl}`} style={{ resize:'both'}}  />

        </div>
        <div className="ml-4 flex-1" >
          <div className="font-bold text-gray-800">{groupdata[0]?.created_user_id === intiater ? groupdata[0]?.receiverdata?.custom_username : groupdata[0]?.senderdata?.custom_username}</div>
          <div className="text-sm text-gray-600">Message</div>
        </div>
        <div className="ml-4 text-right">
          <div className="flex flex-row gap-5">
            <div className="text-sm text-white rounded-full w-4 h-4 flex items-center justify-center">
              {/* <img
                className="h-[43px] md:h-auto object-cover w-[43px]"
                src="images/calling.png"
                alt="Call"
                onClick={()=>handleCall()}
              /> */}
            </div>
            <div className="text-sm text-white text-[#c3c3c3] rounded-full w-4 h-4 flex items-center justify-center">
              {/* <img
                className="h-[43px] md:h-auto object-cover w-[43px]"
                src="images/Forms.png"
                alt="Forms"
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-grow overflow-auto p-4">
        {/* Display Messages */}
        {messages.map((message) => (
          <div
            key={message.message_id}
            className={`flex ${message.chat_message.sent_id === currentUserId ? 'justify-end' : 'justify-start'} mt-2`}
          >
            {
              message?.chat_message?.type === 'text' &&
              
            <div
              className={`p-2 max-w-[75%] text-black ${
                message.chat_message.sent_id === currentUserId ? 'bg-[#FFFFFF]' : 'bg-[#FFFFFF] text-white'
              }`}
              style={{
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: message.chat_message.sent_id === currentUserId ? '0px' : '12px',
                marginRight: message.chat_message.sent_id === currentUserId ? '0' : '5px',
                marginLeft: message.chat_message.sent_id === currentUserId ? '5px' : '0',
              }}
            >
              {message.chat_message.message}
            </div>
            }
            {
             message?.chat_message?.type === 'content' &&
              
             <div
               className={`p-2 max-w-[75%] text-black ${
                 message.chat_message.sent_id === currentUserId ? 'bg-[#FFFFFF]' : 'bg-[#FFFFFF] text-white'
               }`}
               style={{
                 borderTopLeftRadius: '12px',
                 borderTopRightRadius: '12px',
                 borderBottomLeftRadius: '12px',
                 borderBottomRightRadius: message.chat_message.sent_id === currentUserId ? '0px' : '12px',
                 marginRight: message.chat_message.sent_id === currentUserId ? '0' : '5px',
                 marginLeft: message.chat_message.sent_id === currentUserId ? '5px' : '0',
               }}
             >
              <img src={ `https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${message.chat_message.file_url}`} style={{ resize:'both'}}  />
             </div> 
            }
          </div>
        ))}
      </div>
      <div className="flex items-center justify-between p-4 bg-[#FFFFFF]">
        <input
          type="text"
          placeholder="Type your message..."
          className="bg-[#F6EDE4] rounded-lg p-2 w-[75%] border-none mr-4"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <div className="flex gap-5 mr-4">
          <div className="text-sm text-white rounded-full flex items-center justify-center cursor-pointer">
            <img
              className="h-[20px] md:h-auto object-cover w-[20px]"
              src="images/Add.png"
              alt="Add"
              onClick={selectImage}
            />
          </div>
          <div className="text-sm text-white text-[#c3c3c3] rounded-full flex items-center justify-center cursor-pointer">
            <img
              className="h-[25px] md:h-auto object-cover w-[25px]"
              onClick={handleSend}
              src="images/Send.png"
              alt="Send"
            />
          </div>
        </div>
        {
          isModalVisible && 
          <ConveneOutgoing open={isModalVisible} onClose={handleclsoemodal} apiresponse={apiresponse} name={chat?.groupdata?.groupname} />
        }
      </div>
    </div>
  );
};

export default ExternalChatScreen;
