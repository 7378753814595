  import React, { useEffect, useState } from 'react';
  import { gql, useMutation, useQuery } from '@apollo/client';
  import { useUserId } from '@nhost/react';
  import { Img } from 'components'; // Assuming Img is an image component

  const FETCH_USER_DETAIL = gql`
  query MyQuery($_eq: bigint = "") {
    user_organisations(where: {orgn_id: {_eq: $_eq}}) {
      profiledata {
        first_name
        city
        userdata {
          defaultRole
          email
        }
        user_id
      }
    }
  }
`;

const UPDATE_PROFILE_DETAIL = gql`
  mutation MyMutation($_eq: uuid = "", $first_name: String = "") {
    update_profile_details(where: {user_id: {_eq: $_eq}}, _set: {first_name: $first_name}) {
      returning {
        first_name
      }
    }
  }
`;

const UPDATE_USERS_DETAIL = gql`
mutation MyMutation($_eq: uuid = "", $email: citext = "", $defaultRole: String = "") {
  updateUsers(where: {id: {_eq: $_eq}}, _set: {email: $email, defaultRole: $defaultRole}) {
    returning {
      email
      defaultRole
    }
  }
}`;

const DELETE_USERS_DETAIL = gql`  
mutation MyMutation($uuid: uuid_comparison_exp = {}, $_eq: bigint = "") {
  delete_user_organisations(where: {uuid: $uuid, orgn_id: {_eq: $_eq}}) {
    returning {
      id
    }
  }
}`;

const TableComponent = ({ searchQuery }) => {
  const [editableRowId, setEditableRowId] = useState(null);
  const [editedUserData, setEditedUserData] = useState({});
  const [editedUserData2, setEditedUserData2] = useState({});
  const [updatedFirstName, setUpdatedFirstName] = useState(''); 
  const [updatedEmail, setUpdatedEmail] = useState(''); 
  const [updatedDefaultRole, setUpdatedDefaultRole] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const userid = useUserId();
  const orgid = localStorage.getItem('id');
  
  const { loading, error, data, refetch } = useQuery(FETCH_USER_DETAIL, {
    variables: {
      _eq: localStorage.getItem('id'),
    },
    onError: (error) => {
      console.log('Error fetching user details: ', error);
    },
    onCompleted: (data) => {
      // Data fetching is completed
      console.log('fetch data:', data.user_organisations);
    },
  });
  useEffect(() => {
    if (!loading && !error && data && data.user_organisations) {
      const filtered = data.user_organisations.filter((item) =>
        item.profiledata?.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.profiledata?.userdata?.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [loading, error, data, searchQuery]);
  
  const handleSearch = (query) => {
    if (data && data.user_organisations) {
      setFilteredData(
        data.user_organisations.filter((item) =>
          item.profiledata.first_name.toLowerCase().includes(query.toLowerCase()) ||
          item.profiledata.userdata.email.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery]);

  const [UpdateProfileDetail] = useMutation(UPDATE_PROFILE_DETAIL, {
    onCompleted: (data) => {
      console.log("Profile data updated:", data.update_profile_details.returning[0].first_name);
      setUpdatedFirstName(data.update_profile_details?.returning[0]?.first_name);   
      setEditableRowId(null);
    },
    onError: (error) => {
      console.log("Error updating profile data:", error);
    },
  });

 

  const [UpdateUsersDetail] = useMutation(UPDATE_USERS_DETAIL, {
    onCompleted: (data) => {
      console.log("Email and default role updated:", data.updateUsers.returning);
      setUpdatedEmail(data.update_Users.returning[0].email);
      setUpdatedDefaultRole(data.update_Users.returning[0].defaultRole);

    },
    onError: (error) => {
      console.log("Error updating profile data:", error);
    },
  });
  const [DeleteUserOrganisation] = useMutation(DELETE_USERS_DETAIL, {
    onCompleted: (data) => {
    
      console.log(data)
      refetch();
    },
    onError: (error) => {
      console.error("Error deleting user organisation:", error);
    },
  });

  useEffect(() => {
    // After the mutation is successful, you can refetch the data to update the table
    if (!loading && !error) {
      refetch();
    }
  }, [updatedFirstName]); 



  const handleFirstname = async () => {
    try {
      await UpdateProfileDetail({
        variables: {
          _eq: editableRowId,
          first_name: editedUserData["first_name"],
        },
      });
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };

  const handleEmail = async () => {
    try {
      await UpdateUsersDetail({
        variables: {
          _eq: editableRowId,
        email: editedUserData2["email"],
        defaultRole:editedUserData2["defaultRole"],
        },
      });
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };


  const handleDeleteClick = (row) => {
    const uuid = row.profiledata.user_id; 
    if (!uuid) {
      console.error("UUID not found in row data");
      return;
    }
    try {
      DeleteUserOrganisation({
        variables: {
          uuid: { _eq: uuid }, 
          _eq: orgid,
        },
      });
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };  
  const handleEditClick = (row) => {
    setEditableRowId(row.profiledata.user_id);
    setEditedUserData(row.profiledata);
    setEditedUserData2(row.profiledata.userdata);
  };
  const handleSaveClick = () => {
    // Implement logic to save edited data (optional)
    console.log('Saving edited data:', editedUserData.user_id);
    setEditableRowId(null); 
    setEditedUserData({});
    setEditedUserData2({});
    handleFirstname();
    handleEmail();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setEditedUserData2((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const tableStyle = {
    width: '90%',
    borderCollapse: 'collapse',
  };

  const thStyle = {
    backgroundColor: '#00A5CF',
    color: 'white',
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left',
  };

  const tdStyle = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left',
  };

  if (loading) return <p>Loading...</p>;
if (error) return <p>Error: {error.message}</p>;
if (!data || !data.user_organisations) return <p>No data found</p>; 

      return (
      
    <div
      className="w-[90%]"
      style={{
        overflowX: 'auto', 
        scrollbarWidth: 'none', 
        WebkitOverflowScrolling: 'touch',
        '&::-webkit-scrollbar': {
          height: 0, 
          width: 0, 
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent', 
        },
      }}
    >
        <table className="table-auto min-w-max">
          <thead>
            <tr>
              <th className="text-white p-4"style={thStyle} >Name</th>
              <th className="text-white p-4" style={thStyle}>Email ID</th>
              <th className="text-white p-4" style={thStyle}>Designation</th>
              <th className="text-white p-4" style={thStyle}>Location</th>
              <th className="text-white p-4" style={thStyle}>Operation</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index}>
                <td className="border border-black p-4">
                  {editableRowId === row?.profiledata?.user_id ? (
                    <input
                      className="w-full"
                      type="text"
                      name="first_name"
                      value={editedUserData?.first_name || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    row?.profiledata?.first_name || ''
                  )}
                </td>
                <td className="border border-black p-4">
                  {editableRowId === row?.profiledata?.user_id ? (
                    <input
                      className="w-full"
                      type="text"
                      name="email"
                      value={editedUserData2?.email || ''}
                      onChange={handleInputChange2}
                    />
                  ) : (
                    row?.profiledata?.userdata?.email || ''
                  )}
                </td>
                <td className="border border-black p-4">
                  {editableRowId === row?.profiledata?.user_id ? (
                    <input
                      className="w-full"
                      type="text"
                      name="defaultRole"
                      value={editedUserData2?.defaultRole || ''}
                      onChange={handleInputChange2}
                    />
                  ) : (
                    row?.profiledata?.userdata?.defaultRole || ''
                  )}
                </td>
                <td className="border border-black p-4">
                  {editableRowId === row?.profiledata?.user_id ? (
                    <input
                      className="w-full"
                      type="text"
                      name="city"
                      value={editedUserData?.city || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    row?.profiledata?.city || ''
                  )}
                </td>
                <td className="border border-black p-4">
                  {editableRowId === row?.profiledata?.user_id ? (
                    <button className="  text-white font-bold py-2 px-4 rounded" onClick={handleSaveClick}>Save</button>
                  ) : (
                    <div className="flex">
                      <Img src="images/img_EditIcon.png" alt="Edit" className="w-[20px] md:h-auto object-cover cursor-pointer" onClick={() => handleEditClick(row)} />
                      <Img src="images/img_Delete.svg" alt="Delete" className="w-[20px] md:h-auto object-cover cursor-pointer" onClick={() => handleDeleteClick(row)} />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
  export default TableComponent;