import React from "react";

import { Button, Text, Img} from "../../components";
import { Heading } from "components/Heading";

export default function CreategroupThreePage() {
  return (
    <>
      
      <div className="flex flex-col items-start justify-start w-full pb-[34px] gap-[51px] sm:pb-5 bg-white-A700">
        
        <div className="flex flex-col items-center justify-start w-[21%] md:w-full ml-12 md:ml-5">
        
              <Text size="4xl" as="p" className="!text-light_green-400">
                Group settings
              </Text>
            </div>
            <div className="flex flex-row justify-between items-center w-[55%] md:w-full mt-1 ml-[9px] md:ml-0">
              
              <Button color="cyan_600" size="sm" variant="fill" className="sm:px-5 min-w-[136px] rounded-[18px]">
                Create
              </Button>
            </div>
          </div>
        
      
    </>
  );
}
