import { Img, Text } from 'components';
import { Heading } from 'components/Heading';
import React from 'react';

const UserRequestCard = ({  }) => {
    const userRequests = [
        { name: 'User 1', organization: 'Org 1', },
        { name: 'User 2', organization: 'Org 2',  },
        // Add more user requests as needed
      ];
    return (
      <div className="flex flex-col items-start justify-start w-[37%] md:w-full gap-[22px] p-[18px] bg-gray-50 shadow-xs rounded-md" >
        <Heading size="2xl" as="h4" className="ml-[5px] md:ml-0 tracking-[0.24px]">
          User requests
        </Heading>
        <div className="flex flex-col w-[93%] mb-3 ml-[5px] gap-[31px] md:ml-0">
          {/* Map over userRequests array */}
          {userRequests.map((request, index) => (
            <div key={index} className="flex flex-row justify-between items-start w-full">
              <div className="flex flex-col items-start justify-start gap-1">
                <Heading size="xl" as="h5" className="tracking-[0.20px]">
                  {request.name}
                </Heading>
                <Text size="xl" as="p" className="!text-gray-400 tracking-[0.18px]">
                  {request.organization}
                </Text>
              </div>
              <Img src={'images/img_group_416.svg'} alt={request.altText} className="h-[17px] mt-5" />
            </div>
          ))}
          {/* End of map function */}
        </div>
      </div>
    );
  }
  export default UserRequestCard;