import React, { useState } from "react";
import { Img, Text } from "components";
import { useNavigate } from "react-router";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import CreategroupOne from './CreategroupOne';
import CreategroupTwo from './CreategroupTwo';
import CreategroupThree from './CreategroupThree';

import { Heading } from "components/Heading";
import Header from "components/NewHeader/Header";




const VerticalLinearStepper = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    // console.log("Attempting to navigate");
    // Your form submission logic
    // After form submission, navigate to the pricing page
    // navigate("/login");
  };
  const [data, setData] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  

  const steps = [
    {
      label: 'Configuration',
      content: (
        <CreategroupOne
          data={data}
          setData={setData}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
        label: 'Member Configuration',
        content: (
          <CreategroupTwo
            data={data}
            setData={setData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        ),
      },
      {
        label: 'Member Configuration',
        content: (
          <CreategroupThree
            data={data}
            setData={setData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        ),
      },
  ];

  return (
    <>
    <div className="flex flex-col items-center justify-start w-full gap-[50px] bg-white-A700">
    {/* <header className="flex justify-center items-center w-full p-9 sm:p-5 border-orange-300 border-b-[3px] border-solid bg-orange-50">
      <div className="flex flex-row justify-center w-full mx-auto md:px-5 max-w-[1427px]">
        <div className="flex flex-row sm:flex-col justify-between items-center w-full sm:gap-10">
          <div className="flex flex-row justify-between items-center w-auto gap-8">
            <Img
              src="images/img_convene_logo.png"
              alt="convenelogo_one"
              className="w-[60px] md:h-auto object-cover"
            />
            <Heading size="4xl" as="h2">
              Convene chat
            </Heading>
          </div>
          <div className="flex flex-row justify-between items-center w-[12%] sm:w-full">
            <Img src="images/img_icon.svg" alt="icon_one" className="h-[49px]" />
            <div className="flex flex-col items-center justify-start w-[44%]">
              <div className="h-[71px] w-full relative">
                <Img
                  src="images/img_rectangle_6.png"
                  alt="image"
                  className="h-[68px] w-[68px] top-0 right-0 left-0 m-auto object-cover absolute"
                />
                <Button
                  color="light_green_400"
                  size="xs"
                  variant="fill"
                  shape="round"
                  className="w-[21px] bottom-0 right-0 m-auto border-white-A700 border-[5px] border-solid absolute"
                >
                  <Img src="images/img_group_62.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header> */}
    <Header backgroundColor={"#FFF8D6"} border={"3px solid #c3c3c3"}/>
    <div className="flex flex-col items-start justify-start w-[80%]">
            <Heading as="h1" className="!text-orange-300">
              Create new group
            </Heading>
            </div>
          <Box sx={{marginTop:1,width:"80%",alignItem:"center",justifyContent:"flex-start",alignSelf:"center"}}>

          <Stepper activeStep={activeStep} orientation="vertical" sx={[
            { ".css-1hv8oq8-MuiStepLabel-label.Mui-completed":{fontWeight:"bold",color: '#8CB369'}},
            { ".css-ahj2mt-MuiTypography-root":{fontSize:"22px"}},
            { ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {color: '#8CB369'}},
            { ".css-1hv8oq8-MuiStepLabel-label.Mui-active": {color: '#8CB369'}},
            { ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {color: '#8CB369'}}
          ]}>
          {steps.map((step, index) => (
            <Step key={step.label}> 
              <StepLabel>
                <div>
                  <Typography  >{step.label}</Typography>
                  <div>
                    {activeStep === index && step.subSteps && step.subSteps.map(subStep => (
                      <div key={subStep.subLabel}>
                        <Typography variant="subtitle1" style={{ marginTop: 5 }}>{subStep.subLabel}</Typography>
                        {subStep.content}
                      </div>
                    ))}
                  </div>
                </div>
              </StepLabel>
              <StepContent>
                {step.content}
                <Box sx={{ mt: 2 }}>
                  <div>
                  {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', width: "30%" }}>
    <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', borderRadius: '25px', backgroundColor: "#00A5CF", width: "22%",height:"25px" }}  onClick={handleNext}>
      <Img src="images/forward.png" alt="arrow" style={{ marginRight: '5px' }} />
      <Text style={{ fontSize: "12px" , color:'white' }}> {index === steps.length - 1 ? "Finish" : "Next"}</Text>
    </button>
    </div>  */}
                   
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
                    </Stepper>
          
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )}
          </Box>
        </div>
     
    </>
  );
};

export default VerticalLinearStepper;
