import React, { useState } from "react";

import {  Button, Img,  Input } from "../../components";
import { Radio } from "components/Radio";
import { TextArea } from "components/TextArea";
import { SelectBox } from "components/SelectBox";
import { Heading } from "components/Heading";
import { gql, useMutation } from "@apollo/client";

const INSERT_GROUP_DETAIL = gql`
mutation MyMutation($orgn_id: bigint = "", $groupname: String = "", $description: String = "", $type: String = "") {
  insert_group_master(objects: {orgn_id: $orgn_id, groupname: $groupname, description: $description, type: $type}) {
    returning {
      orgn_id
      groupname
      type
      id
    }
  }
}

`;



const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];

export default function CreategroupOnePage(props) {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const handleChange = (key, value) => {
    console.log(key, value);

    // Set the data with the updated value
    setData(prevData => ({
        ...prevData,
        [key]: value
    }));

    // Check if there's an existing error for the field and clear it
    if (errors[key]) {
        setErrors(prevErrors => ({
            ...prevErrors,
            [key]: null  
        }));
    }
}

  const [InsertGroupDetail] = useMutation(INSERT_GROUP_DETAIL, {
    onCompleted: (data) => {
      console.log("some data updated");
      // nextuser()
    },
    onError: (error) => {
      console.log("error updating profile : ", error);
    },
  });


  const validate = () => {
    const newErrors = {};

    if (!data.name) {
      newErrors.name = "Please enter a name";
    }

    if (!data.desc) {
      newErrors.desc = "Please enter a description";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };
  const handlenext = async()=>{
    if (!validate()) {
      return; // Don't proceed if there are validation errors
    }
    try {
      const { data: insertedData } = await InsertGroupDetail({
        variables: {
          orgn_id:localStorage.getItem("id"),
          groupname: data["name"],
          description:data["desc"],
          type:"orgadmincreated"
       
        },

      });
      localStorage.setItem("groupId", insertedData.insert_group_master.returning[0].id);
      console.log("inserted group header info", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }

    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }
  return (
    <>
     
      <div className="flex flex-col items-start justify-start w-full pb-[105px] gap-[51px] md:pb-5 bg-white-A700">
       
        <div className="flex flex-row justify-start w-[78%] md:w-full ml-1 md:ml-5">
       
            <div className="h-[260px] w-full mt-[10px] relative">
       
              <div className="flex flex-row justify-center w-full top-0 right-0 left-0 m-auto absolute">
                <div className="flex flex-row md:flex-row justify-start items-start w-full gap-8 md:gap-5">
                  <div className="flex flex-col items-start justify-start w-[51%] md:w-full gap-[0px]">
                   
                    <div className="flex flex-col items-start justify-start ml-[4px] w-[60%] md:w-[90%]  md:ml-0">
                    <input
                  value={data.name}
                  onChange={(e) => handleChange('name', e.target.value)}
                  placeholder="name"
                  className="p-3 w-full border border-gray-700 border-solid rounded-[20px]"
                  wrapClassName="flex h-10 mt-2 w-full "
                / >
                   {errors.name && <div className="text-red-500">{errors.name}</div>}
                      <SelectBox
                        shape="round"
                        indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" />}
                        name="selectcategory"
                        placeholder="Select category"
                        options={dropDownOptions}
                        className="w-full mt-4 gap-px border-gray-700 border border-solid"
                      />
                      <SelectBox
                        shape="round"
                        indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" />}
                        name="selectcategory"
                        placeholder="Group visibility"
                        options={dropDownOptions}
                        className="w-full mt-4 gap-px border-gray-700 border border-solid"
                      />
                       <textarea
  value={data.desc}
  onChange={(e) => handleChange('desc', e.target.value)}

  className="w-[100%]  md:w-full  md:mt-5 sm:pb-0 sm:pr-5 text-gray-400 border-gray-700 border border-solid h-[137px] md:h-[67px] rounded-[12px] p-3 mt-5 text-lg"
/>
{errors.desc && <div className="text-red-500">{errors.desc}</div>}      
                    </div>
                    <Button onClick={handlenext}
                        color="cyan_600"
                        size="sm"
                        variant="fill"
                        leftIcon={<Img src="images/img_arrowright.svg" alt="arrow_right" />}
                        className="mt-6 gap-2.5 sm:px-5 w-[136px] md:w-[116px] rounded-[18px] "
                      >
                        Next
                      </Button>
                  </div>
                  
                 
                </div>
              </div>
            </div>
            
          </div>
        </div>
     
    </>
  );
}
