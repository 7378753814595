import React, { useEffect, useState } from "react";

import { Img, Line, List, Text } from "components";
import { useNavigate } from "react-router";

const PricingchartPage = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    console.log("Attempting to navigate");
  // Your form submission logic
  // After form submission, navigate to the pricing page
  if (!isMobile) {
    navigate("/pricingchartdetails");
  } else {
    navigate("/CommunicationDetails"); 
    
  }
  };
  const [isMobile, setIsMobile] = useState(false);
  const [openFeature, setOpenFeature] = useState(null);
  const [openFeature1, setOpenFeature1] = useState(null);
  const [openFeature2, setOpenFeature2] = useState(null);
  const handleFeatureClick1 = (index) => {
    setOpenFeature1(!openFeature1);
  };
  const handleFeatureClick2 = (index) => {
    setOpenFeature2(!openFeature2);
  };


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 960);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <>
      <div className="bg-white-A700 flex flex-col font-poppins items-center justify-start mx-auto p-[34px] sm:px-5 w-full">
        <div className="flex flex-col items-center justify-start max-w-[1444px] mb-5 mx-auto md:px-5 w-full">
         {!isMobile&&(
        <div className="flex md:flex-col flex-row md:gap-5 items-center justify-start w-full">
            <div className="flex md:flex-1 flex-row gap-6 items-center justify-start w-[18%] md:w-full">
              <Img
                className="h-[43px] md:h-auto object-cover w-[43px]"
                src="images/img_convenelogo.png"
                alt="convenelogo"
              />
              <Text
                className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700 flex-shrink-0"
                size="txtPoppinsSemiBold25"
              >
                Convene chat
              </Text>
            </div>
            <Text
              className="md:ml-[0] ml-[556px] text-gray-700 text-lg"
              size="txtPoppinsRegular18"
            >
              Demo
            </Text>
            <Text
              className="ml-16 md:ml-[0] text-gray-700 text-lg"
              size="txtPoppinsRegular18"
            >
              Pricing
            </Text>
            <Text
              className="ml-16 md:ml-[0] text-gray-700 text-lg"
              size="txtPoppinsRegular18"
            >
              Features
            </Text>
            <a
              href="javascript:"
              className="md:ml-[0] ml-[68px] text-gray-700 text-lg"
            >
              <Text size="txtPoppinsRegular18">FAQs</Text>
            </a>
            <a
            href="javascript:"
            className="bg-cyan-600 h-[37px] justify-center mb-1.5 md:ml-[0] ml-[70px] pb-0.5  sm:px-5 px-[34px] rounded-[18px] text-gray-200 text-lg w-[190px]"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Text size="txtPoppinsRegular18Gray200" style={{ margin: "auto" }}>Sign up</Text>
          </a>

          </div>
         )}
         {isMobile && (
          <div style={{display:'flex',justifyContent:"space-between",width:"100%",alignItems:"center"}}>
          <div style= {{display:"flex",justifyContent:"space-evenly",width:"50%",gap:"10px",alignItems:"center"}}>
      
        <Img
                className="h-[43px] md:h-auto object-cover w-[43px]"
                src="images/img_convenelogo.png"
                alt="convenelogo"
              />
              <Text
              className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700 flex-shrink-0"
              size="txtPoppinsSemiBold25"
            >
              Convene chat
            </Text>
            </div>
            <Img  onClick={toggleNav}
            className=" md:h-[15px] md:ml-[0] ml-[29px] object-cover  "
            src="images/Threeline.png"
            alt="idea"
          />
        </div>
        
        )}
        {isNavOpen && (
          <div
            style={{
              position: "fixed",
              top: 60,
              right: 0, 
              width: "30%", 
              background: "white",
              height: "40%", 
               display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent:'center',
             
              padding: "20px",
              boxSizing: "border-box",
              
            }}
          > <Text size="txtPoppinsRegular18">Demo</Text>
          <Text size="txtPoppinsRegular18">Pricing</Text>
          <Text size="txtPoppinsRegular18">Features</Text>
          <Text size="txtPoppinsRegular18">FAQs</Text>
          <Text size="txtPoppinsRegular18">Sign up</Text>
          </div>
        )}
         {!isMobile &&(
          <div className="flex md:flex-col flex-row md:gap-10 items-center justify-between mt-[26px] w-[94%] md:w-full">
            <div className="md:h-28 h-[122px] relative w-[7%] md:w-full">
              <Img
                className="absolute h-28 left-[0] object-cover top-[0] w-[91%]"
                src="images/img_success.png"
                alt="success"
              />
              <Text
                className="absolute bottom-[0] right-[0] text-center text-gray-700 text-sm"
                size="txtPoppinsRegular14"
              >
                Fair pricing
              </Text>
            </div>
            <div className="md:h-[106px] h-[111px] relative w-[9%] md:w-full">
              <Img
                className="absolute h-[106px] left-[14%] object-cover top-[0] w-[63%]"
                src="images/img_idea.png"
                alt="idea"
              />
              <Text
                className="absolute bottom-[0] inset-x-[0] mx-auto text-center text-gray-700 text-sm w-max"
                size="txtPoppinsRegular14"
              >
                Build community
              </Text>
            </div>
            <div className="h-[123px] relative w-[10%] md:w-full">
              <Img
                className="absolute h-[123px] inset-[0] justify-center m-auto object-cover w-[58%]"
                src="images/img_newitem.png"
                alt="newitem"
              />
              <Text
                className="absolute bottom-[2%] inset-x-[0] mx-auto text-center text-gray-700 text-sm w-max"
                size="txtPoppinsRegular14"
              >
                Convene connect
              </Text>
            </div>
            <div className="md:h-[101px] h-[114px] relative w-[114px]">
              <Img
                className="absolute h-[101px] left-[9%] object-cover top-[0] w-[76%]"
                src="images/img_search.png"
                alt="search"
              />
              <Text
                className="absolute bottom-[0] inset-x-[0] mx-auto text-center text-gray-700 text-sm w-max"
                size="txtPoppinsRegular14"
              >
                Dynamic search
              </Text>
            </div>
            <div className="h-[124px] md:mt-0 mt-[3px] relative w-[9%] md:w-full">
              <Img
                className="absolute h-[124px] inset-y-[0] left-[14%] my-auto object-cover w-[67%]"
                src="images/img_settings.png"
                alt="settings"
              />
              <Text
                className="absolute bottom-[3%] inset-x-[0] mx-auto text-center text-gray-700 text-sm w-max"
                size="txtPoppinsRegular14"
              >
                Channel building
              </Text>
            </div>
            <div className="md:h-[119px] h-[121px] relative w-[10%] md:w-full">
              <Img
                className="absolute h-[119px] inset-y-[0] left-[5%] my-auto object-cover w-[78%]"
                src="images/img_newletter.png"
                alt="newletter"
              />
              <Text
                className="absolute bottom-[0] inset-x-[0] mx-auto text-center text-gray-700 text-sm w-max"
                size="txtPoppinsRegular14"
              >
                Secure messaging
              </Text>
            </div>
          </div>
          )}
          <Text
            className="mt-12 text-3xl sm:text-[26px] md:text-[28px] text-center text-gray-700"
            size="txtPoppinsSemiBold30"
          >
            Pricing plans
          </Text>
          <div className={`flex md:flex-col flex-row ${isMobile ? 'gap-1' : 'gap-10'} items-center justify-center mt-[29px] w-[57%] md:w-full`}>
            <div className="bg-gray-200 border border-gray-700 border-solid flex flex-col items-center justify-start md:mt-10 my-7 p-[9px] rounded-[25px] w-[29%] md:w-full">
              <Text
                className="mt-5 text-center text-cyan-600 text-lg"
                size="txtPoppinsSemiBold18"
              >
                Standard pack
              </Text>
              <Text
                className="text-[10px] text-center text-gray-700"
                size="txtPoppinsLight10"
              >
                Upto 300 users
              </Text>
              <Line className="bg-blue_gray-100 h-px mt-3.5 w-[95%]" />
              <div className="flex flex-col items-center justify-start mt-[29px] w-[52%] md:w-full">
                <div className="flex flex-row gap-[7px] items-start justify-start w-full">
                  <Text
                    className="mt-[42px] text-center text-gray-700 text-xs"
                    size="txtPoppinsLight12"
                  >
                    Rs.
                  </Text>
                  <Text
                    className="sm:text-[40px] md:text-[46px] text-[50px] text-center text-light_green-400"
                    size="txtPoppinsSemiBold50"
                  >
                    199
                  </Text>
                </div>
                <Text
                  className="text-[8px] text-center text-gray-700"
                  size="txtPoppinsLight8"
                >
                  Per month
                </Text>
              </div>
              <button
              className="bg-cyan-600 cursor-pointer h-6   mb-[7px] mt-12  px-[19px] rounded-[12px] text-gray-200 text-xs w-[90px] hover:bg-cyan-700 flex items-center justify-center"
              
            >
              Buy now
            </button>
            <Text  onClick={handleSubmit} 
            className="text-base text-gray-700"
            size="txtPoppinsLight16"
          >Explore for more Details
          </Text>
           </div>
          
           {isMobile && openFeature && (
            <div className="flex flex-col items-start justify-start mb-10  ">

            <ul className="border border-solid border-[#00A5CF] p-6 border-[3px] rounded-[25px]">
            <Text
                      className="text-2xl md:text-[22px] text-cyan-600 sm:text-xl"
                      size="txtPoppinsSemiBold24"
                    >
                      Features
                    </Text>
                <li className="list-disc">
                  <Text
                    className="text-base text-gray-700"
                    size="txtPoppinsLight16"
                  >
                    Audio calling
                  </Text>
                </li>
                <li className="list-disc">
                  <Text
                    className="text-base text-gray-700"
                    size="txtPoppinsLight16"
                  >
                    Convene connect
                  </Text>
                </li>
                <li className="list-disc">
                  <Text
                    className="text-base text-gray-700"
                    size="txtPoppinsLight16"
                  >
                    600 user limit
                  </Text>
                </li>
                <li className="list-disc">
                  <Text
                    className="text-base text-gray-700"
                    size="txtPoppinsLight16"
                  >
                    Listing up to 10 organizations
                  </Text>
                </li>
                <li className="list-disc">
                  <Text
                    className="text-base text-gray-700"
                    size="txtPoppinsLight16"
                  >
                    Feature name
                  </Text>
                </li>
                <li className="list-disc">
                  <Text
                    className="text-base text-gray-700"
                    size="txtPoppinsLight16"
                  >
                    Feature name
                  </Text>
                </li>
                <button
                className="bg-cyan-600 cursor-pointer h-6  mt-5 px-[19px] rounded-[12px] text-gray-200 text-xs w-[90px] hover:bg-cyan-700 flex items-center justify-center self-center"
                
              >
                Buy now
              </button>
              </ul>
            </div>
          )} 
           <List
              className="sm:flex-col flex-row gap-10 grid sm:grid-cols-1 grid-cols-2 w-[67%] md:w-full"
              orientation="horizontal"
            >
              <div className="bg-white-A700_01 border-[3px] border-cyan-600 border-solid flex flex-col items-center justify-end p-6 sm:px-5 rounded-[25px] w-full">
                <Text
                  className="mt-[9px] text-2xl md:text-[22px] text-center text-cyan-600 sm:text-xl"
                  size="txtPoppinsSemiBold24"
                >
                  Premium pack
                </Text>
                <Text
                  className="text-base text-center text-gray-700"
                  size="txtPoppinsLight16"
                >
                  Upto 600 users
                </Text>
                <Line className="bg-blue_gray-100 h-px mt-[13px] w-[88%]" />
                <div className="flex flex-col items-center justify-start mt-[21px] w-[74%] md:w-full">
                  <div className="flex flex-row items-start justify-evenly w-full">
                    <Text
                      className="mt-[61px] text-base text-center text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      Rs.
                    </Text>
                    <Text
                      className="md:text-5xl text-[70px] text-center text-light_green-400"
                      size="txtPoppinsSemiBold70"
                    >
                      499
                    </Text>
                  </div>
                  <Text
                    className="text-[10px] text-center text-gray-700"
                    size="txtPoppinsLight10"
                  >
                    Per month
                  </Text>
                </div>
                <button
                className="bg-cyan-600 cursor-pointer h-6 justify-center mb-[7px] mt-12  px-[19px] rounded-[12px] text-gray-200 text-xs w-[90px] hover:bg-cyan-700"
                
              >
                Buy now
              </button>
              <Text  onClick={handleFeatureClick1} 
              className="text-base text-gray-700"
              size="txtPoppinsLight16"
            >Explore for more Details
            </Text>
             </div>
            
             {isMobile && openFeature1 && (
              <div className="flex flex-col items-start justify-start mb-10  ">
  
              <ul className="border border-solid border-[#00A5CF] p-6 border-[3px] rounded-[25px]">
              <Text
                        className="text-2xl md:text-[22px] text-cyan-600 sm:text-xl"
                        size="txtPoppinsSemiBold24"
                      >
                        Features
                      </Text>
                  <li className="list-disc">
                    <Text
                      className="text-base text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      Audio calling
                    </Text>
                  </li>
                  <li className="list-disc">
                    <Text
                      className="text-base text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      Convene connect
                    </Text>
                  </li>
                  <li className="list-disc">
                    <Text
                      className="text-base text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      600 user limit
                    </Text>
                  </li>
                  <li className="list-disc">
                    <Text
                      className="text-base text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      Listing up to 10 organizations
                    </Text>
                  </li>
                  <li className="list-disc">
                    <Text
                      className="text-base text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      Feature name
                    </Text>
                  </li>
                  <li className="list-disc">
                    <Text
                      className="text-base text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      Feature name
                    </Text>
                  </li>
                  <button
                  className="bg-cyan-600 cursor-pointer h-6 justify-center mt-5  px-[19px] rounded-[12px] text-gray-200 text-xs w-[90px] hover:bg-cyan-700"
                  
                >
                  Buy now
                </button>
                </ul>
               
              </div>
            )} 

              <div className="bg-gray-200 border border-gray-700 border-solid flex flex-col items-center justify-start p-[9px] rounded-[25px] w-full">
                <Text
                  className="mt-[19px] text-center text-cyan-600 text-lg"
                  size="txtPoppinsSemiBold18"
                >
                  Platinum pack
                </Text>
                <Text
                  className="text-[10px] text-center text-gray-700"
                  size="txtPoppinsLight10"
                >
                  Upto 900 users
                </Text>
                <Line className="bg-blue_gray-100 h-px mt-3.5 w-[95%]" />
                <div className="flex flex-col items-center justify-start mt-[30px] w-[56%] md:w-full">
                  <div className="flex flex-row gap-[7px] items-start justify-start w-full">
                    <Text
                      className="mt-[42px] text-center text-gray-700 text-xs"
                      size="txtPoppinsLight12"
                    >
                      Rs.
                    </Text>
                    <Text
                      className="sm:text-[40px] md:text-[46px] text-[50px] text-center text-light_green-400"
                      size="txtPoppinsSemiBold50"
                    >
                      899
                    </Text>
                  </div>
                  <Text
                    className="text-[8px] text-center text-gray-700"
                    size="txtPoppinsLight8"
                  >
                    Per month
                  </Text>
                </div>
                <button
                className="bg-cyan-600 cursor-pointer h-6 justify-center mb-[7px] mt-12  px-[19px] rounded-[12px] text-gray-200 text-xs w-[90px] hover:bg-cyan-700"
                onClick={handleSubmit}
              >
                Buy now
              </button>
              <Text  onClick={handleFeatureClick2} 
              className="text-base text-gray-700"
              size="txtPoppinsLight16"
            >Explore for more Details
            </Text>
             </div>
            
             {isMobile && openFeature2 && (
              <div className="flex flex-col items-start justify-start mb-10  ">
  
              <ul className="border border-solid border-[#00A5CF] p-6 border-[3px] rounded-[25px]">
              <Text
                        className="text-2xl md:text-[22px] text-cyan-600 sm:text-xl"
                        size="txtPoppinsSemiBold24"
                      >
                        Features
                      </Text>
                  <li className="list-disc">
                    <Text
                      className="text-base text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      Audio calling
                    </Text>
                  </li>
                  <li className="list-disc">
                    <Text
                      className="text-base text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      Convene connect
                    </Text>
                  </li>
                  <li className="list-disc">
                    <Text
                      className="text-base text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      600 user limit
                    </Text>
                  </li>
                  <li className="list-disc">
                    <Text
                      className="text-base text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      Listing up to 10 organizations
                    </Text>
                  </li>
                  <li className="list-disc">
                    <Text
                      className="text-base text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      Feature name
                    </Text>
                  </li>
                  <li className="list-disc">
                    <Text
                      className="text-base text-gray-700"
                      size="txtPoppinsLight16"
                    >
                      Feature name
                    </Text>
                  </li>
                  <button
                  className="bg-cyan-600 cursor-pointer h-6 justify-center mt-5  px-[19px] rounded-[12px] text-gray-200 text-xs w-[90px] hover:bg-cyan-700"
                  
                >
                  Buy now
                </button>
                </ul>
              </div>
            )} 
            </List>
          </div>
          <Text
            className="mt-[7px] text-[22px] text-center sm:text-lg text-light_green-400 md:text-xl"
            size="txtPoppinsSemiBold22"
          >
            Recommended
          </Text>
          <Text
            className="mt-7 text-base text-gray-700"
            size="txtPoppinsRegular16"
          >
            <span className="text-gray-700 font-poppins text-left font-normal">
              Want to make your own custom plan?{" "}
            </span>
            <span className="text-light_green-400 font-poppins text-left font-normal">
              Click here
            </span>
          </Text>
        </div>
      </div>
    </>
  );
};

export default PricingchartPage;
