import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
// import ModalComponent from './ModalComponent'; // Assume you have a modal component for adding members
import { Button, Card, CardContent, Typography, Avatar, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import GroupMemberCard  from "../../components/GroupSetting/GroupMemberCard"
import AddMember from "../../components/GroupSetting/AddMember"
const UPDATE_GROUP_IMAGE = gql`
  mutation updategroupimage($groupprofile_img: String = "", $_eq: bigint = "") {
    update_group_master(where: {id: {_eq: $_eq}}, _set: {groupprofile_img: $groupprofile_img}) {
      returning {
        groupprofile_img
        id
      }
    }
  }
`;

const EXIT_USER_GROUP = gql`
mutation MyMutation($_eq: bigint = "", $_eq1: bigint = "", $_eq2: uuid = "") {
  delete_group_usermapping(where: {orgn_id: {_eq: $_eq}, _and: {group_id: {_eq: $_eq1}, userid: {_eq: $_eq2}}}) {
    affected_rows
  }
}
`;

const CLEAR_CHAT = gql`
  mutation MyMutation($delivered: uuid = null, $deleted: uuid = "", $_eq: uuid = "", $_eq1: bigint = "") {
    update_chat_seen_message(where: {delivered: {_eq: $_eq}, _and: {group_id: {_eq: $_eq1}}}, _set: {delivered: $delivered, deleted: $deleted}) {
      affected_rows
    }
  }
`;

const GET_GROUP_USERS = gql`
  query MyQuery($_eq: bigint = "", $_eq2: String = "active") {
    group_usermapping(where: {group_id: {_eq: $_eq}, status: {_eq: $_eq2}}) {
      userid
      usersdata {
        custom_username
        first_name
        userdata {
          avatarUrl
        }
      }
    }
  }
`;

// const ADD_USER_TO_GROUP = gql`
// mutation MyMutation($objects: [group_usermapping_insert_input!]!) {
//     insert_group_usermapping(objects: $objects) {
//       returning {
//         id
//       }
//     }
//   }
// `;

const GroupSetting = ({ groupid, orgnid, name, imageid, type,}) => {
    const location = useLocation();
    const { chat } = location.state || {};
    const [modalVisible, setModalVisible] = useState(false);
  const [groupUsers, setgroupUsers] = useState([]);
  const [coverImgPath, setCoverImgPath] = useState(imageid);
  const currentUserId = localStorage.getItem('userid');
  const [updateGroupImage] = useMutation(UPDATE_GROUP_IMAGE);
  // const [deleteUserGroupMapping] = useMutation(EXIT_USER_GROUP);

  const [usersToAddGroup, setUsersToAddGroup] = useState([]);
  const [checkedStates, setCheckedStates] = useState({});
  const groupUserMappings = [];
 
 
  const navigate = useNavigate();

  
//   const handleImagePress = () => {
//     navigation.navigate('ImageScreen', { 
//         imageid: route.params.imageid,
//         isUploaded: isUploaded,
//         cover_img_path: cover_img_path
//     });
// };

  // console.log(chat,"passaed chat data")

  // const handleGroupImageUpload = async (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     // Assume you have an upload function that returns the uploaded image path
  //     const uploadedPath = 'uploaded_image_path'; // replace with actual upload logic
  //     setCoverImgPath(uploadedPath);
  //   }
  // };

  // useEffect(() => {
  //   if (coverImgPath) {
  //     updateGroupImage({ variables: { _eq: groupid, groupprofile_img: coverImgPath } })
  //       .then(() => {
  //         refetch();
  //       });
  //   }
  // }, [coverImgPath, groupid, updateGroupImage, refetch]);

  const handleExitGroup=async()=>{
    console.log(localStorage.getItem('orgnid') ,chat.group_id?chat.group_id:chat.groupdata.id ,currentUserId )
    try {
      const { data: updatedata } = await deleteusergroupmapping({
        variables: {
          _eq: localStorage.getItem('orgnid'),
          _eq1: chat.group_id?chat.group_id:chat.groupdata.id,
          _eq2: currentUserId
        },
      });
    } catch (error) {
      console.log("error while booking event", error);
    }
    // navigation.navigate("tabs",{"orgnid":route?.params?.orgnid });
  }

  const [clearchat , { loading:clearloading, error:clearerror, data: cleardata }] = useMutation(CLEAR_CHAT, { 
    onError:(error)=>{
      console.log("error for the personal chat",error)
    },
    onCompleted:(data)=>{

    // navigation.goBack();
//   navigation.navigate("tabs",{"orgnid":route?.params?.orgnid });

    }
  });

  const [deleteusergroupmapping , { loading:inserting, error:errorinserting, data: insertedata }] = useMutation(EXIT_USER_GROUP, { 
    onError:(error)=>{
      console.log("error for the personal chat",error)
    },
    onCompleted:(data)=>{

      navigate(-2);
    }
  });

  const handleClearChat = async ()=>{
    // console.log(object)
    try {
      const { data: updatedata } = await clearchat({
        variables: {
          _eq: currentUserId ,
          _eq1: chat.group_id,
deleted:currentUserId
          // _eq2: userid,
        },
      });
    } catch (error) {
      console.log("error while booking event", error);
    }
  }
  

  const potentialMembers = [
    { avatarUrl: 'url1', custom_username: 'User1', role: 'Admin' },
    { avatarUrl: 'url2', custom_username: 'User2', role: 'Member' },
    // Add more members as needed
  ];
  const {  data: organisationcount , refetch } = useQuery(GET_GROUP_USERS, {
    variables: {
      _eq:chat.group_id,
      _eq1:localStorage.getItem('orgnid'),
    },
    onCompleted:(data)=>{
      // console.log("data user group mapping  ",data.group_usermapping);
      setgroupUsers(data.group_usermapping)
      // setOrgdata([...orgdata,...data.user_organisations])
    },
    onError:(error)=>{
      console.log(error ,'yes')
    }
  });
console.log(groupUsers,"efwefwf")


const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

//   const [addusertogroup , { loading:inserting1, error:errorinserting1, data: insertedata1 }] = useMutation(ADD_USER_TO_GROUP, { 
//     onError:(error)=>{
//       console.log("error for the personal chat",error)
//     },
//     onCompleted:(data)=>{
//     //   handleendlastmessage({type:"text"})
//     // props.setModalVisible(!props.modalVisible)
//     // navigation.goBack(); 
//     // console.log("first entry",data)
//     console.log(data,"fdcdfwdwfdtwdfwtd")
//     }
//   });
//   const handleAddUser = async()=>{
//           for (const [id, value] of Object.entries(checkedStates)) {
//     // If the value is true, add the ID to the object
//     console.log(id ,value)
//     if (value === true) {
//       const mapping = {
//         orgn_id: localStorage.getItem('orgnid'),
//         group_id: 13

// ,
//   status: 'active',
//   userid: id // Assuming a constant type for all mappings
//         // group_id: data.insert_group_master.returning[0].id
//       };
//       groupUserMappings.push(mapping);         
//     }
//   }
//   console.log("pressed" , groupUserMappings)

//     // if(data.groupname === undefined || data.groupname === "" || data.groupname.length === 0)
//     // {
//     //   //Alert.alert("Please enter the group name")
//     //   setgrpError(true)
//     // }
//     //   else{

//         try {
//           const { data: insertedData } = await addusertogroup({
//             variables: {
//                 objects:groupUserMappings
//               // type:"text",
//             },
//           });
//         } catch (error) {
//           console.log("error while booking event", error);
//         }
//     //   }
 
// }

// useEffect(()=>{
//   refetch();
//   // reobserve();
// },[props])
// console.log(usersToAddGroup)
// onPress={()=>{handleAddUser(item)}}
useEffect(() => {
  if (usersToAddGroup.length > 0) {
    const initialCheckedStates = {};
    usersToAddGroup.forEach(item => {
      initialCheckedStates[item.uuid] = false;
    });
    setCheckedStates(initialCheckedStates);
  }
}, [usersToAddGroup]);

  return (
    <div style={{ padding: '20px' }}>
      <Button onClick={() => navigate(-1)}>Back</Button>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '3px' }}>
        <div style={{ border: '1px solid #EF962D', padding: '10px', borderRadius: '10px' }}>
        <img src={chat?.groupdata?.groupprofile_img === null || chat?.groupdata?.groupprofile_img  === undefined ? require('../../assets/images/gorupplaceholder.png') : `https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${chat?.groupdata?.groupprofile_img}`} style={{ resize:'both',heigth:"100px",width:"100px"}}  />
        </div>
        <h2 style={{ textAlign: 'center' }} className='mb-5 mt-2 text-2xl text-bold'>{chat?.groupdata?.groupname}</h2>
      </div>
      <div className='mb-4' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#FFFCEE', padding: '18px', gap: '10px', borderRadius: '10px', height: '51px' }}>
      <div className='w-50' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
        <img
          className="md:h-auto"
          src="images/Gallery.png"
          alt="Gallery"
          style={{ marginRight: '10px' }}
        />
        <h3 className='text-[#616161] text-center' style={{ margin: 5 }}>Shared Media</h3>
      </div>
      <img
        className="h-[10px] w-[10px]"
        src="images/ForwardArrow.png"
        alt="Forward Arrow"
      />
    </div>

<div  className='mb-5' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: '#FFFCEE', padding: '18px', gap: "10px", borderRadius: '10px' }} >
  <div className='w-30' style={{ display: "flex", alignItems: "center" }} >
    <img
      className="md:h-auto"
      src="images/Wallpaper.png"
      alt="Gallery"
      style={{ marginRight: '10px' }}
    />
    <h3 className='text-[#616161]'>Change Wallpaper</h3>
  </div>
  <img
    className="h-[10px] w-[10px]"
    src="images/ForwardArrow.png"
    alt="Forward Arrow"
  />
</div>

      <h1 className='text-[#616161] text-lg mb-3'>Group Members</h1>
      <div  className='mb-5' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: '#FFFCEE', padding: '18px', gap: "10px", borderRadius: '10px' }}
      >
  <div className='w-30' style={{ display: "flex", alignItems: "center" }}  onClick={handleOpenModal} >
    {/* <img
      className="md:h-auto"
      src="images/Gallery.png"
      alt="Gallery"
      style={{ marginRight: '10px' }}
    /> */}
    <h3 className='text-[#616161]'> + Add member</h3>
    {/* <AddMember
        open={modalVisible}
        onClose={handleCloseModal}
        potentialMembers={potentialMembers}
        // onAddMember={handleAddMember}
      /> */}
  </div>
  
</div>
<GroupMemberCard members={groupUsers} />
      
<div style={{ marginTop: '20px', backgroundColor: '#FFFCEE', padding: '10px', borderRadius: '10px' }}>
  <div style={{ borderBottom: '1px solid #ccc', paddingBottom: '7px' }} className='mb-3 text-[#616161]'>
    <button onClick={handleClearChat} style={{ width: '100%', textAlign: 'left', background: 'none', border: 'none', padding: 0 }}>Clear Chat</button>
  </div>
  <div style={{ borderBottom: '1px solid #ccc', paddingBottom: '7px' }} className=' mb-3 text-[#BD3246]'>
    <button onClick={handleExitGroup} style={{ width: '100%', textAlign: 'left', background: 'none', border: 'none', padding: 0}}>Exit Group</button>
  </div>
  <div>
    <button style={{ width: '100%', textAlign: 'left', background: 'none', border: 'none', padding: 0, }} className='text-[#BD3246]'>Report {name}</button>
  </div>
</div>

     {modalVisible && <AddMember setModalVisible={setModalVisible} groupUsers={groupUsers} open={modalVisible}
        onClose={handleCloseModal} groupid={chat.group_id} orgnid={localStorage.getItem('orgnid')} />} 
    </div>
  );
};

export default GroupSetting;


// import React from 'react';

// const ModalComponent = ({ setModalVisible, groupUsers, groupid, orgnid }) => {
//   return (
//     <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
//       <h2>Add Members</h2>
//       {/* Add members logic here */}
//       <button onClick={() => setModalVisible(false)}>Close</button>
//     </div>
//   );
// };

// // export default ModalComponent;
// {/* <button onClick={() => setModalVisible(true)}>Add Member</button>
//       <div style={{ marginTop: '20px', backgroundColor: '#FFFCEE', padding: '10px', borderRadius: '10px' }}>
//         {groupUsers.map(user => (
//           <div key={user.userid} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0' }}>
//             <div style={{ display: 'flex', alignItems: 'center' }}>
//               <img src={user.usersdata.userdata.avatarUrl || 'default_avatar_url'} alt="User Avatar" style={{ width: 40, height: 40, borderRadius: '50%' }} />
//               <span style={{ marginLeft: '10px' }}>{user.usersdata.custom_username}</span>
//             </div>
//             <span>user</span>
//           </div>
//         ))}
//       </div> */}