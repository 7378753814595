import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
const GET_FORM_DATA = gql`
query GetFormdat($_eq: bigint = "", $_eq1: String = "active") {
  Form_api_data(where: {id: {_eq: $_eq}, _and: {status: {_eq: $_eq1}}}) {
    Form_data
    Form_api
    Form_Name
  }
}

`;
export default function Formshower() {
  const [data , setData]= useState({})

    const location = useLocation();
    const navigate = useNavigate();
  const [formName , setFormName] = useState('')
  const [formArray , setformArray] =useState([])
  const [formApi , setformApi] =useState([])


    const {  data: organisationcount } = useQuery(GET_FORM_DATA, {
        variables: {
          _eq:location.state.form.id
        },
        onCompleted:(data)=>{
          console.log(data, 'data')
          setFormName(data?.Form_api_data?.[0]?.Form_Name)
          setformArray(data?.Form_api_data?.[0]?.Form_data)
          setformApi(data?.Form_api_data?.[0]?.Form_api)
        },
        onError:(error)=>{
          console.log(error ,'yes')
        }
      });
      const handleChange = (key , value) =>{
        console.log(key ,value)
        if (key === "radio") {
          // Create a new object to hold the updated radio button state
          const newRadioState = {};
          // Iterate through the options to update the selected option
        //   item.options.forEach((option) => {
        //     // If the current option label matches the selected value, set its value to true
        //     newRadioState[option.label] = option.label === value;
        //   });
          // Update the radio button state in the main data state
          setData({ ...data, [key]: newRadioState });
        } else {
          // For other types of input fields, update the state normally
          setData({
            ...data,
            [key]: value,
          });
        }
      }
    
      const handleSendDataToApi =async ()=>{
        // console.log(userdata);
        const alldata = {
          ...data,
        //   "project_id":userdata[0]?.ticket_projectid,
        //   "raised_user_id":userdata[0]?.ticket_userid,
        //   "raised_organisation_id":5,
        //   "raisedto_organisation":5,
        //   "assigned_by":userdata[0]?.ticket_userid,
        //   "assigned_to":userdata[0]?.ticket_userid
        }
        console.log(alldata);
        try {
          const response = await fetch(`${formApi.ipdomain}/${formApi.endpoint}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(alldata)
          });
    
          if (response.ok) {
            console.log('Data sent successfully!');
            navigate(-1)
            // You can perform any actions here after successfully sending data
          } else {
            // console.error('Failed to send data:', response);
          }
        } catch (error) {
          console.error('Error sending data:', error);
        }
      }
const handleImagepicker =()=>{
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
        let file = event.target.files[0]
      console.log("this is first image : ", file)

      
//   await upload({ file })
    };
    input.click();
}
console.log(data)
  return (
    <div style={{width:'100%', height:'100%',  display:'flex' , justifyContent:'center' , alignItems:'center',flexDirection:'column'}} >
        
            {
                formArray?.map((item, index) => (
                    <div style={{width:'90%' , height:'100%' , display:'flex', justifyContent:'center',marginTop:10, alignItems:'center',border:'2px solid #D9D9D9',flexDirection:'column',borderRadius:10 ,boxShadow:'unset'}}>
                        <div style={{width:'90%' , height:40 }} >
                            <p style={{color:'#00A5CF' , fontSize:17, margin:8}}  >{item?.label}</p>
                            </div>
                            {
              item.type === "textfield" &&
                            <input
          type="text"
          style={{width:'90%' ,height:50 , color:'black' , borderRadius:5,backgroundColor:'#EBEBEB', marginBottom:10}}
              placeholder={item?.placeholder}
              placeholderTextColor="#C3C3C3"
              onChange={(e) => handleChange(item.valuedata , e.target.value)}
              value={data[item?.valuedata]}
        />
}

{
              item.type === "image" && 
              <div style={{width:'90%' ,height:50 , color:'black' , borderRadius:5,backgroundColor:'#EBEBEB', marginBottom:10 ,display:'flex', alignItems:'center'}} onClick={()=>handleImagepicker()} >
                <div style={{width:'40%' ,height:'70%', backgroundColor:'grey',marginLeft:10,borderRadius:10, display:'flex' , justifyContent:'center' , alignItems:'center'}} ><p style={{color:'#FFFFFF'}} >Choose File</p></div>
              </div>

}
{
              item.type === "date" && 
              <input
                type="date"
                style={{ width: '90%', height: 50, color: 'black', borderRadius: 5, backgroundColor: '#EBEBEB', marginBottom: 10 }}
                onChange={(e) => handleChange(item.valuedata, e.target.value)}
                value={data[item?.valuedata]}
              />
}
       
{
              item.type === "radio" &&
              <div style={{ flexDirection: 'column', marginBottom: 10, width: '90%' }}>
                  {item.options.map((option, optionIndex) => (
                  <label key={optionIndex} style={{ display: 'flex', alignItems: 'center', marginVertical: 10 }}>
                    <input
                      type="radio"
                      name={item.valuedata}
                      value={option.label}
                      checked={data[item.valuedata] === option.label}
                      onChange={() => handleChange(item.valuedata, option.label)}
                      style={{ marginRight: 10 }}
                    />
                    <span style={{ color: '#616161' }}>{option.label}</span>
                  </label>
                ))}
              </div>
            }
                    </div>

                ))
        }
        <div style={{width:'40%' ,height:40, alignSelf:'flex-end',marginRight:20,borderRadius:20 , borderWidth:1 , display:'flex' , justifyContent:'center' , alignItems:'center' , marginTop:5,marginBottom:5 , borderColor:'#00A5CF' , backgroundColor:'white'}} onClick={()=>handleSendDataToApi()}>
        <p style={{color:'#00A5CF', fontWeight:700}}>Submit</p>
        </div>
      
    </div>
  )
}
