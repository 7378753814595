import { gql, useQuery } from '@apollo/client';
import { useUserId } from '@nhost/react';
import { Button, Img } from 'components';
import { Heading } from 'components/Heading';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const FETCH_USER_DETAIL = gql`
query MyQuery($_eq: uuid = "") {
    users(where: {id: {_eq: $_eq}}) {
      displayName
      email
      avatarUrl
    }
  }
`;

const Header = (props) => {
  // const {signou}
  const navigate=useNavigate();
  
const userid=useUserId();
    const[data,setData]=useState();
    const { loading:loadins, error:erros, data:userdata } = useQuery(FETCH_USER_DETAIL, {
        variables: {
          _eq: userid,
        },
        onError: (error) => {
          console.log('error fetching user details: ', error);
        },
        onCompleted: (data) => {
          // Data fetching is completed
          console.log('Data:', data.users);
      setData(data.users[0]); 
        },
    });
    
  const handleNotification =() => {
    navigate("/Notification")
  }
  const handleProfile =()=>{
    navigate("/Profile")

  }


  return (
    <header className="flex justify-center items-center w-full p-9 sm:p-5   " style={{backgroundColor:props.backgroundColor,borderBottom:props.border}}>
      <div className="flex flex-row justify-center w-full mx-auto md:px-5 max-w-[100%]">
        <div className="flex flex-row sm:flex-col justify-between items-center w-full sm:gap-10">
          <div className="flex flex-row justify-between items-center w-auto gap-8">
            <Img
              src="images/img_convene_logo.png"
              alt="convenelogo_one"
              className="w-[60px] md:h-auto object-cover"
            />
            <Heading size="4xl" as="h2">
              Convene chat
            </Heading>
          </div>    
          <div className="flex flex-row justify-between sm:justify-evenly items-center w-[12%] sm:w-[28%] ">
                  <Img src="images/img_icon.svg" alt="icon_one" className="h-[49px] md:h-[30px]  md:w-[30px]  " onClick={handleNotification} />
                  <div className="flex flex-col items-center  w-[44%]">
                    <div className=" items-center md:justify-center  w-full ">
                    <Img
              src={`https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${data?.avatarUrl}`}
                  alt="image" onClick={handleProfile}
                  className="h-[68px] w-[68px] top-0 right-0 left-0 m-auto object-cover rounded-full "

                      />
                      {/* <Button 
                        color="light_green_400"
                        size="xs"
                        variant="fill"
                        shape="round"
                        className="w-[21px] bottom-0 right-0 m-auto border-white-A700 border-[5px] border-solid absolute"
                      >
                        <Img src="images/img_group_62.svg" />
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </header> 
  );
}

export default Header;
