import React, { useEffect, useState } from "react";
 
import { Sidebar } from "react-pro-sidebar";
 
import { Button, Img, Input, Text } from "components";
import { useNavigate } from "react-router";
import { useSignInEmailPassword, useUserId } from "@nhost/react";
import { gql, useQuery } from "@apollo/client";
import OrganisationComponent from "./OrganisationComponent";
 
const FETCH_ORG_ID = gql`
  query MyQuery($_eq: uuid = "") {
    user_organisations(where: {uuid: {_eq: $_eq}}) {
      orgn_id
    }
  }
`;
 
 
const Login = () => {
  const [data , setData] = useState({}) ;
  const [suctemp , setSucTemp] = useState(true);
  const [errors, setErrors] = useState({});
  const [orgDropdown, setOrganisationDropdown] = useState(false);
  const userid=useUserId();
  const {
    signInEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
    isError,
    error:signuperror,
  } = useSignInEmailPassword();
 
  const handleChange = (key , value)=>{
   
    setData(
      {
     ...data,
        [key]: value
      }
    )
    }
 
 
 
 
    const validate = () => {
    
      setErrors({});
    
      let isValid = true;
      const newErrors = {};
    
      if (!data.email) {
        newErrors.email = "Please fill in the email field";
        isValid = false;
      }
    
      if (!data.passnew) {
        newErrors.passnew = "Please fill in the password field";
        isValid = false;
      }
    
      // Update the errors state with the new errors
      setErrors(newErrors);
    
      return isValid;
    };
  const handleSubmit = () => {

    if (!validate()) {
      return;   
    }
    console.log("Attempting to navigate"); 
    signInEmailPassword(data.email, data.passnew, {
      displayName: data.displayName,
     
   
    });
 
    
    // Your form submission logic
    // After form submission, navigate to the pricing page
    
    }; 
    let temp = true;
    if(isSuccess && suctemp && temp){
      
      // console.log("entered");
      // console.log("Query Data:", userid);
      setSucTemp(false);
      setOrganisationDropdown(true)
 
    }
    
    // console.log(isSuccess , isError , signuperror )
  return (
    <>
    <div className="bg-white-A700 flex flex-col font-poppins items-center justify-start mx-auto p-[34px] sm:px-5 w-full">
    <div className="flex flex-col md:gap-10 gap-[69px] items-center justify-start max-w-[1444px] mb-[98px] mx-auto md:px-5 w-full">
      <div className="flex md:flex-col flex-row md:gap-5 items-center justify-start w-full">
        <div className="flex md:flex-1 flex-row gap-6 items-center justify-start w-[18%] md:w-full">
          <Img
            className="h-[43px] md:h-auto object-cover w-[43px]"
            src="images/img_convenelogo.png"
            alt="convenelogo"
          />
          <Text
          className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700 flex-shrink-0"
          size="txtPoppinsSemiBold25"
        >
          Convene chat
        </Text>
        </div>
           
            <Text
              className="md:ml-[0] ml-[556px] text-gray-700 text-lg"
              size="txtPoppinsRegular18"
            >
              Demo
            </Text>
            <Text
              className="ml-16 md:ml-[0] text-gray-700 text-lg"
              size="txtPoppinsRegular18"
            >
              Pricing
            </Text>
            <Text
              className="ml-16 md:ml-[0] text-gray-700 text-lg"
              size="txtPoppinsRegular18"
            >
              Features
            </Text>
            <a
              href="javascript:"
              className="md:ml-[0] ml-[68px] text-gray-700 text-lg"
            >
              <Text size="txtPoppinsRegular18">FAQs</Text>
            </a>
            <a
              href="javascript:"
              className="bg-cyan-600 h-[37px] justify-center mb-1.5 md:ml-[0] ml-[70px] pb-0.5 pt-[5px] sm:px-5 px-[34px] rounded-[18px] text-gray-200 text-lg w-[146px]"
            >
              <Text size="txtPoppinsRegular18Gray200">Sign up</Text>
            </a>
          </div>
          <div
            className="bg-cover bg-no-repeat flex flex-col h-[650px] items-center justify-end p-[148px] md:px-10 sm:px-5 w-full"
            style={{ backgroundImage: "url('images/img_group1.svg')" }}
          >
            <div className="bg-white-A700 border-2 border-gray-700 border-solid flex flex-col items-start justify-end p-7 md:px-5 rounded-[40px] w-[47%] md:w-full">
              <Text
                className="md:ml-[0] ml-[19px] mt-[15px] text-cyan-600 text-lg"
                size="txtPoppinsRegular18Cyan600"
              >
                Userid
              </Text>
              <Input
                name="email"
                placeholder=""
                className="p-2 w-full"
                wrapClassName="flex h-10 md:ml-[0] ml-[19px] mt-[15px] w-[93%]"
                value={data.email} onChange={(e)=>handleChange('email',e)}
              >   {errors.email && <div style={{ color: "red" }}>{errors.email}</div>}</Input>
              <Text
                className="md:ml-[0] ml-[19px] mt-8 text-cyan-600 text-lg"
                size="txtPoppinsRegular18Cyan600"
              >
                Password
              </Text>
              <Input
                name="passnew"
              value={data.passnew} onChange={(e)=>handleChange('passnew',e)}
                placeholder=""
                className="p-2 w-full"
                wrapClassName="flex h-10 md:ml-[0] ml-[19px] mt-3.5 w-[93%]"
              ></Input>
              {
                orgDropdown && 
              <OrganisationComponent data={data} setData={setData} />
              }
              <Button onClick={()=>handleSubmit()}
                className="cursor-pointer h-11 leading-[normal] md:ml-[0] ml-[188px] mt-10 rounded-[22px] text-center text-lg w-[136px]"
                size="sm"
              >
                Log in
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
 
export default Login;





// import React from "react";
// import { Img, Text } from "components";
// import { useNavigate } from "react-router";
// import Box from '@mui/material/Box';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import StepContent from '@mui/material/StepContent';
// import Button from '@mui/material/Button';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';

// import Createadmin from './Createadmin';
// import Setpassword from './Setpassword';
// import Publicdetails from './Publicdetails';


// const VerticalLinearStepper = () => {
//   const navigate = useNavigate();

//   const handleSubmit = () => {
//     console.log("Attempting to navigate");
//     // Your form submission logic
//     // After form submission, navigate to the pricing page
//     navigate("/login");
//   };
//   const [data, setData] = React.useState([]);
//   const [activeStep, setActiveStep] = React.useState(0);

//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleReset = () => {
//     setActiveStep(0);
//   };

//   const steps = [
//     {
//       label: 'Change Default Password',
//       content: (
//         <Setpassword
//           data={data}
//           setData={setData}
//           activeStep={activeStep}
//           setActiveStep={setActiveStep}
//         />
//       ),
//     },
//     {
//       label: 'Create Orgnisation System admin'  ,
//      content:(
//       <Createadmin
//       data={data}
//       setData={setData}
//       activeStep={activeStep}
//       setActiveStep={setActiveStep}
//     />
//      ),
//     },
//     { label: "Review Application",
//     content: (
//       <div>
//         {/* Content for the review application step */}
//       </div>
//     ),
//   },
//   {
//     label: "Public Details",
//     content: (
 
//         <Publicdetails
//         data={data}
//         setData={setData}
//         activeStep={activeStep}
//         setActiveStep={setActiveStep}
//       />
//        ),
//   },
//   ];

//   return (
//     <>
//       <div className="bg-white-A700 flex flex-col font-poppins items-center justify-end mx-auto p-[31px] sm:px-5 w-full">
//         <div className="flex flex-col justify-start max-w-[1444px] mt-0.5 mx-auto md:px-5 w-full">
//           <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start w-full">
//             {/* Left Side */}
//             <div className="flex md:flex-1 flex-row gap-6 items-center justify-start w-[18%] md:w-full">
//               <Img
//                 className="h-[43px] md:h-auto object-cover w-[43px]"
//                 src="images/img_convenelogo.png"
//                 alt="convenelogo"
//               />
//               <Text
//                 className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700"
//                 size="txtPoppinsSemiBold25"
//               >
//                 Convene chat
//               </Text>
//             </div>
//             {/* Nav Items */}
           
//           </div>
//           <Text
//             className="mt-[40px] text-3xl sm:text-[26px] md:text-[28px] text-cyan-600"
//             size="txtPoppinsSemiBold26"
//           >
//             Lets get started
//           </Text>
         
//           <Box sx={{marginTop:3}}>
//             <Stepper activeStep={activeStep}  orientation="vertical">
//               {steps.map((step, index) => (
//                 <Step key ={step.label}  
//                  >
//                   <StepLabel 
//                    >
//                     {step.label}
//                   </StepLabel>
//                   <StepContent>
//                     {step.content}
//                     <Box sx={{ mb: 2 }}>
//                       <div>
//                         <Button
//                           onClick={handleNext}
//                           sx={{ mt: 1, mr: 1,fontSize: 10 }}
//                         >
//                           {index === steps.length - 1 ? 'Finish' : 'Next'}
//                         </Button>
                        
//                       </div>
//                     </Box>
//                   </StepContent>
//                 </Step>
//               ))}
//             </Stepper>
//             {activeStep === steps.length && (
//               <Paper square elevation={0} sx={{ p: 3 }}>
//                 <Typography>All steps completed - you&apos;re finished</Typography>
//                 <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//                   Reset
//                 </Button>
//               </Paper>
//             )}
//           </Box>
//         </div>
//       </div>
//     </>
//   );
// };

// export default VerticalLinearStepper;
