    import React, { useState } from "react";
    import { Img, Text } from "components";
    import { useNavigate } from "react-router";
    import Box from '@mui/material/Box';
    import Stepper from '@mui/material/Stepper';
    import Step from '@mui/material/Step';
    import StepLabel from '@mui/material/StepLabel';
    import StepContent from '@mui/material/StepContent';
    import Button from '@mui/material/Button';
    import Paper from '@mui/material/Paper';
    import Typography from '@mui/material/Typography';

    import Verification from './Verification';
    import CommunicationDetails from './CommunicationDetails';


    import Setup from './Setup';
    import SystemStepper from './SytemconfStepper/SystemStepper';

    import Payment from "./Payment";
    import Createadmin from "./Createadmin";



    const VerticalLinearStepper = () => {
      const navigate = useNavigate();

      const handleSubmit = () => {
        console.log("Attempting to navigate");
        // Your form submission logic
        // After form submission, navigate to the pricing page
        navigate("/login");
      };
      const [data, setData] = React.useState([]);
      const [activeStep, setActiveStep] = React.useState(0);
      const [isDarkMode, setIsDarkMode] = useState(false);

      const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
      };
      const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };

      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };

      const handleReset = () => {
        setActiveStep(0);
      };
      

      const steps = [
        {
          label: 'Communication Details',
          content: (
            <CommunicationDetails
              data={data}
              setData={setData}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          ),
        },
        {
          label: 'Verification'  ,
          content: (
            <Verification
              data={data}
      setData={setData}
      activeStep={activeStep}
      setActiveStep={setActiveStep} 
            />
          ),
        },
      {
      label: "Payment",
      content: (
        <Payment
          data={data}
          setData={setData}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
      ];
      const handleStepClick = (stepIndex) => {
        setActiveStep(stepIndex);
      };
    

      return (
        <>
          <div className="bg-white-A700 flex flex-col font-poppins items-center justify-end mx-auto p-[31px] sm:px-5 w-full">
            <div className="flex flex-col justify-start max-w-[1444px] mt-0.5 mx-auto md:px-5 w-full">
              <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start w-full">
                {/* Left Side */}
                <div className="flex md:flex-1 flex-row gap-6 items-center justify-start w-[18%] md:w-full">
                  <Img
                    className="h-[43px] md:h-auto object-cover w-[43px]"
                    src="images/img_convenelogo.png"
                    alt="convenelogo"
                  />
                  <Text
                    className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700"
                    size="txtPoppinsSemiBold25"
                  >
                    Convene chat
                  </Text>
                </div>
                {/* Nav Items */}
              
              </div>
              <Text
                className="mt-[40px] text-3xl sm:text-[26px] md:text-[28px] text-cyan-600"
                size="txtPoppinsSemiBold26"
              >
                Lets get started
              </Text>
            
              <Box sx={{marginTop:3,width:"100%",alignItem:"center",justifyContent:"flex-start",alignSelf:"center"}}>

              <Stepper activeStep={activeStep} orientation="vertical" sx={[
                { ".css-1hv8oq8-MuiStepLabel-label.Mui-completed":{fontWeight:"bold",color: '#8CB369'}},
                { ".css-ahj2mt-MuiTypography-root":{fontSize:"22px"}},
                { ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {color: '#8CB369'}},
                { ".css-1hv8oq8-MuiStepLabel-label.Mui-active": {color: '#8CB369'}},
                { ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {color: '#8CB369'}}
              ]}>
              {steps.map((step, index) => (
                <Step key={step.label}> 
                  <StepLabel  onClick={() => handleStepClick(index)}>
                    <div>
                      <Typography  >{step.label}</Typography>
                      <div>
                        {activeStep === index && step.subSteps && step.subSteps.map(subStep => (
                          <div key={subStep.subLabel}>
                            <Typography variant="subtitle1" style={{ marginTop: 5 }}>{subStep.subLabel}</Typography>
                            {subStep.content}
                          </div>
                        ))}
                      </div>
                    </div>
                  </StepLabel>
                  <StepContent>
                    {step.content}
                    <Box sx={{ mt: 2 }}>
                      <div>
                      {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', width: "30%" }}>
        <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', borderRadius: '25px', backgroundColor: "#00A5CF", width: "22%",height:"25px" }}  onClick={handleNext}>
          <Img src="images/forward.png" alt="arrow" style={{ marginRight: '5px' }} />
          <Text style={{ fontSize: "12px" , color:'white' }}> {index === steps.length - 1 ? "Finish" : "Next"}</Text>
        </button>
        </div>  */}
                      
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
                        </Stepper>
              
                {activeStep === steps.length && (
                  <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                      Reset
                    </Button>
                  </Paper>
                )}
              </Box>
            </div>
          </div>
        </>
      );
    };

    export default VerticalLinearStepper;
