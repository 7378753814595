import React, { useState } from 'react';
import LinearWithValueLabel from '../../pages/MobileScreen/ProgressBar';
import { Img, Text } from 'components';
import { useNavigate } from 'react-router';

export default function Payment() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navigate = useNavigate();

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const [showLoginInfo, setShowLoginInfo] = useState(false);

    const handleSubmit = () => {
        console.log("Attempting to navigate");
        navigate("/Login");
    };

    return (
        <>
            <div className="bg-white-A700 flex flex-col font-poppins items-center justify-start mx-auto p-[34px] sm:px-5 w-full  " style={{ height: "35vh" }} >
                <div className="flex flex-col items-center justify-start max-w-[1444px] mb-5 mx-auto md:px-5 w-full">
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center',marginBottom:15 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '50%', gap: '10px', alignItems: 'center' }}>
                            <Img
                                className="h-[43px] md:h-auto object-cover w-[43px]"
                                src="images/img_convenelogo.png"
                                alt="convenelogo"
                            />
                            <Text
                                className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700 flex-shrink-0"
                                size="txtPoppinsSemiBold25"
                            >
                                Convene chat
                            </Text>
                        </div>
                        <Img
                            onClick={toggleNav}
                            className="md:h-[15px] md:ml-[0] ml-[29px] object-cover"
                            src="images/Threeline.png"
                            alt="idea"
                        />
                        {isNavOpen && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: 60,
                                    right: 0,
                                    width: "30%",
                                    background: "white",
                                    height: "40%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignContent: 'center',
                                    padding: "20px",
                                    boxSizing: "border-box",
                                }}
                            >
                                <Text size="txtPoppinsRegular18">Demo</Text>
                                <Text size="txtPoppinsRegular18">Pricing</Text>
                                <Text size="txtPoppinsRegular18">Features</Text>
                                <Text size="txtPoppinsRegular18">FAQs</Text>
                                <Text size="txtPoppinsRegular18">Sign up</Text>
                            </div>
                        )}
                    </div>
                    <div style={{ display: "flex", width: "50%", alignItems: "center", justifyContent: "center", alignSelf: "center", color: "#00A5CF",marginBottom:"30px" }}>
                        <p style={{ fontSize: '22px', fontWeight: "bold", textAlign: "center" }}>Setup admin account</p>
                    </div>
                  
                    <div style={{ display: "flex", alignItems: "left", justifyContent: "left", alignSelf: "left", width: "100%" }}>
                    <LinearWithValueLabel initialValue={25} />
                    </div>
                </div>
            </div>
        </>
    );
}
