
import React, { useEffect, useState, useRef } from 'react';
import { Dialog, Button } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
const GET_GROUP_FORMS = gql`
query GetFormName($_eq: bigint = "", $_eq1: bigint = "") {
  Form_api_data(where: {orgn_id: {_eq: $_eq}, _and: {group_id: {_eq: $_eq1}}}) {
    Form_Name
    id
  }
}
`;
 
const FormModal = ({ open, onClose ,groupid }) => {
 const [formArrya , setFormArray] = useState([])
 const navigate = useNavigate()

  const {  data: organisationcount , refetch:getformsagain } = useQuery(GET_GROUP_FORMS, {
    variables: {
      _eq:localStorage.getItem('orgnid'),
      _eq1:groupid,
    },
    onCompleted:(data)=>{
      console.log("data form got ",data.Form_api_data);
      setFormArray(data.Form_api_data)
      // setOrgdata([...orgdata,...data.user_organisations])
    },
    onError:(error)=>{
      console.log(error ,'yes')
    }
  });

  const handleFormnavigate =(form)=>{
    console.log(form)
    navigate("/formshower",{state :{form}})
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{
   
        position: 'absolute',
      
  height:"200px",
  width:"99%",
  marginTop:"20px",
       
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right', padding: '16px 24px', }}>
       
        <Button onClick={onClose} style={{ minWidth: 'auto', padding: 0, zIndex: 10 }}>X</Button>
      </div>
      <div style={{ height: '50px', overflowY: 'auto', height:"200px", width:"170px"}}>
        <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center',  }}>
          <div style={{ display: 'flex', alignItems: 'center',flexDirection:"column" ,gap:"10px"}}>
            {
              formArrya.map((item , index)=>(
                <text key={index} onClick={()=>{handleFormnavigate(item)}} >{index+1 + "\t" } {item.Form_Name} </text>
              ))
            }
            {/* <text>Form1</text>
            <text>Form1</text>
            <text>Form1</text> */}
          </div>
        </div>
      </div>
     
    </Dialog>
  );
};
export default FormModal