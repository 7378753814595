import { Checkbox, TextField } from '@mui/material'
import { Text } from 'components';
import React from 'react'
import Header from '../../components/Header/Header';
import { useNavigate } from 'react-router';

export default function CreateAdmin(props) {
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = React.useState(false);

    const handleCheckboxChange = (event) => {
        setIsAdmin(event.target.checked);
    };

    const handleNext = (event) => {
        event.preventDefault();
        
        navigate("/Rolls");
    };
  
    return (
        <div>
        <Header />
        <form style={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem' }}>
        <Text style={{color:"#00a5cf"}}> Create orgnisation Admin</Text>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem',alignItems:"center" }}>
                <Checkbox
                    checked={isAdmin}
                    onChange={handleCheckboxChange}
                    color="primary"
                />
                <text>I am the admin for this organization</text>
            </div>
            {!isAdmin && (
                <>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <label htmlFor="adminName">Admin Name:</label>
                    <TextField
                        id="adminName"
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            style: { background: 'transparent', backgroundColor: "#D9D9D9", marginLeft: 5 },
                        }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <label htmlFor="adminEmail">Admin Email:</label>
                    <TextField
                        id="adminEmail"
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            style: { background: 'transparent', backgroundColor: "#D9D9D9", marginLeft: 5 },
                        }}
                    />
                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",width:"100%",justifyContent:"space-evenly"}}>
                        <Text>copy invite link</Text>
                        <button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px',
                                borderRadius: '25px',
                                backgroundColor: "#8CB369",
                                width: '40%',
                                height: '30px'
                            }}
                        >
                            <Text style={{ fontSize: "12px", color: "#EEEEEE" }}>Send Invite</Text>
                        </button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                        <button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px',
                                borderRadius: '25px',
                                backgroundColor: "#00A5CF",
                                width: '40%',
                                height: '30px'
                            }}
                            onClick={(e) => handleNext(e)}
                        >
                            <img src="images/forward.png" alt="arrow" style={{ marginRight: '5px' }} />
                            <Text style={{ fontSize: "12px" }}>Next</Text>
                        </button>
                        <button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px',
                                borderRadius: '25px',
                                border: '1px solid #8CB369',
                                width: '40%',
                                height: '30px'
                            }}
                        >
                            <img src="images/tick.png" alt="tick" style={{ marginRight: '5px' }} />
                            <Text style={{ fontSize: "12px" }}>Save Stage</Text>
                        </button>
                    </div>
                </>
                
            )}
        </form>
        </div>
    )
}
