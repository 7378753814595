import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import gql from 'graphql-tag';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const CUT_THE_CALL = gql`
  mutation MyMutation($group_calling_status: Boolean = false, $_eq: bigint = "") {
    update_notification_master(where: {id: {_eq: $_eq}}, _set: {group_calling_status: $group_calling_status}) {
      affected_rows
    }
  }
`;

const modalStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#0D1B2A',
  boxShadow: 24,
  p: 4,
};

const headerStyle = {
  width: '100%',
  height: '15%',
  backgroundColor: '#0D1B2A',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 10px',
};

const buttonContainerStyle = {
  display: 'flex',
  alignItems: 'center',
};

const buttonStyle = {
  minWidth: 0,
  width: 48,
  height: 48,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
};

const iframeContainerStyle = {
  width: '100%',
  height: '90%',
  backgroundColor: 'white',
};

const endMeetingButtonStyle = {
  width: '50%',
  height: '60%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  backgroundColor: 'red',
  color: 'white',
  fontWeight: 700,
  fontSize: 20,
};

export default function CallingNotification(props) {
  const navigate = useNavigate();
console.log(props.calldata)
  useEffect(() => {
    const backAction = (event) => {
      event.preventDefault();
      if (window.confirm("Do you really want to close the app?")) {
        window.close();
      }
    };
    window.addEventListener('beforeunload', backAction);
    return () => window.removeEventListener('beforeunload', backAction);
  }, []);

  const [webviewVisible, setWebviewVisible] = useState(false);
  const [cutTheCall] = useMutation(CUT_THE_CALL, {
    onError: (error) => {
      console.log("Error for the personal chat", error);
    },
    onCompleted: (data) => {
      console.log('done');
      props.setModalVisible(false);
    }
  });

  const handleCutCall = async () => {
    console.log(props.calldata[0]?.id)
    try {
      await cutTheCall({
        variables: {
          _eq: props.calldata[0]?.id,
        },
      });
    } catch (error) {
      console.log("Error while last message updating", error);
    }
  };

  const handleEndCall = async () => {
    try {
      const response = await fetch(`https://portalapi.convenevc.com/api/leave/${props.calldata[0]?.meeting_id}/instant`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "MeetingName": props.calldata[0]?.Group_calling_name,
          "OrganizationID": 115566,
          "displayName": "displayName",
          "email": "connect@convenevc.com",
        })
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log('Response from API:', responseData);  
      } else {
        console.error('Failed to send verification email. Status:', response.status);
        const errorText = await response.text();
        console.error('Error message:', errorText);
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
    }
    props.setModalVisible(false);
  };

  return (
    <Modal
      open={props.modalVisible}
      onClose={() => props.setModalVisible(false)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        {
          !webviewVisible &&
          <Box sx={headerStyle}>
            <p style={{ fontSize: 20, fontWeight: 700, color: 'white' }}>
              {props.calldata[0].Group_calling_name}... calling
            </p>
            <Box sx={buttonContainerStyle}>
              <Button sx={{ ...buttonStyle, backgroundColor: '#8cb369', marginRight: 1 }} onClick={() => setWebviewVisible(true)}>
                <img src="../../images/calling.png" style={{ width: 30, height: 30 }} alt="Call" />
              </Button>
              <Button sx={{ ...buttonStyle, backgroundColor: '#ae2154', marginLeft: 1 }} onClick={handleCutCall}>
                <img src="../../images/calling.png" style={{ width: 30, height: 30 }} alt="Cut Call" />
              </Button>
            </Box>
          </Box>
        }
        {
          webviewVisible &&
          <>
            <Box sx={iframeContainerStyle}>
              <iframe
                src={props.calldata[0]?.call_url_users}
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="Call"
              />
            </Box>
            <Box sx={{ width: '100%', height: '10%', backgroundColor: 'white' }}>
              <Button sx={endMeetingButtonStyle} onClick={handleEndCall}>
                End Meeting
              </Button>
            </Box>
          </>
        }
      </Box>
    </Modal>
  );
}
