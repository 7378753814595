import { Img, Text } from 'components';
import LinearWithValueLabel from './ProgressBar';
import { TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
export default function Verification() {
    const navigate = useNavigate();
    const [isOTPComplete, setIsOTPComplete] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    
    setIsNavOpen(!isNavOpen);
  };
  const [otp, setOTP] = useState(['', '', '', '']); 
  const handleChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
    if (value === '' && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    } else if (index < 3 && value !== '') {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
    
  };
  const handleSubmit = () => {
      
    console.log("Attempting to navigate");
  // Your form submission logic
  // After form submission, navigate to the pricing page
   
    navigate("/Payment");
 
  }
  const isOtpFilled = otp.every(value => value !== '');
  return (
    <>
    <div className="bg-white-A700 flex flex-col font-poppins items-center justify-start mx-auto p-[34px] sm:px-5 w-full " >
    <div className="flex flex-col items-center justify-start max-w-[1444px] mb-5 mx-auto md:px-5 w-full">
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '50%', gap: '10px', alignItems: 'center' }}>
        <Img
          className="h-[43px] md:h-auto object-cover w-[43px]"
          src="images/img_convenelogo.png"
          alt="convenelogo"
        />
        <Text
          className="sm:text-[21px] md:text-[23px] text-[25px] text-gray-700 flex-shrink-0"
          size="txtPoppinsSemiBold25"
        >
          Convene chat
        </Text>
      </div>
      <Img
        onClick={toggleNav}
        className="md:h-[15px] md:ml-[0] ml-[29px] object-cover"
        src="images/Threeline.png"
        alt="idea"
      />
      {isNavOpen && (
        <div
          style={{
            position: "fixed",
            top: 60,
            right: 0, 
            width: "30%", 
            background: "white",
            height: "40%", 
             display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent:'center',
           
            padding: "20px",
            boxSizing: "border-box",
            
          }}
        > <Text size="txtPoppinsRegular18">Demo</Text>
        <Text size="txtPoppinsRegular18">Pricing</Text>
        <Text size="txtPoppinsRegular18">Features</Text>
        <Text size="txtPoppinsRegular18">FAQs</Text>
        <Text size="txtPoppinsRegular18">Sign up</Text>
        </div>
      )}
    </div>

    </div>
    <div style={{ display:"flex",width:"50%",alignItems:"center",justifyContent:"center",alignSelf:"center",color:"#00A5CF" }}>
    <p style={{fontSize:'22px',fontWeight:"bold",textAlign:"center"}}>Welcome convene chat!</p>
   
  </div>
<div style={{marginTop:"2%",marginBottom:20}}>
  <Text 
  className="text-base text-gray-700"
  size="txtPoppinsLight12"
>
please verify your Details
</Text>
</div>
<div style={{ display:"flex",alignItems:"left",justifyContent:"left",alignSelf:"left",width:"100%" }}>
<LinearWithValueLabel initialValue={50} />

</div>
</div>
<div style={{ width:"100%",display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:"column" }}>
<h1 style={{marginBottom:10, width:"90%",fontSize:"22px",color:"#8CB369",marginBottom:20}}>Verification</h1>
<text style={{marginBottom:10, width:"58%",fontSize:"18px",color:"#616161",textAlign:"center",marginBottom:20}}> Otp send on your register email address</text>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {otp.map((value, index) => (
          <input
            key={index}
            id={`otp-input-${index}`}
            type="text"
            maxLength="1"
            value={value}
            onChange={(e) => handleChange(index, e.target.value)}
            style={{ width: '40px', height: '40px', textAlign: 'center', fontSize: '12px', borderColor: value ? '#00A5CF' : '#C3C3C3',}}
          />
        ))}
      </div>
     
      {isOtpFilled && (
        <button
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '5px',
                borderRadius: '25px',
                backgroundColor: '#00A5CF',
                width: '130px',
                height: '40px',
                marginTop: '20px', 
            }}
            onClick={
                handleSubmit
            }
        >
        <text style={{color:"#EEEEEE",fontSize:"12px"}}>
            Proceed to Payment
            </text>
        </button>
    )}
    </div>
</>
  );
        };