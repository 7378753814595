import React, { useState } from 'react';
import { Text, Img } from 'components'; // Assuming 'Text' and 'Img' are custom components

const Upload = () => {
  const [isTableVisible, setIsTableVisible] = useState(false);

  const handleSeeReport = () => {
    setIsTableVisible(true);
  };

  return (
    <div style={{ marginLeft: '2%', width: '100%' }}>
      <Text style={{ color: '#00A5CF', marginBottom: 15 }}>
        Document uploaded successfully!
      </Text>
      <Text onClick={handleSeeReport}>See Uploaded Report</Text>
      {isTableVisible && (
        <>
        <table style={{ borderCollapse: 'collapse', width: '90%', border: '2px solid #616161', borderColor: '#616161', marginBottom: 15 }}>
        <tbody>
          <tr>
            <td style={{ border: '2px solid #616161', padding: '8px' }}>Total Entries</td>
            <td style={{ border: '2px solid #616161', padding: '8px' }}>10</td>
          </tr>
          <tr>
            <td style={{ border: '2px solid #616161', padding: '8px' }}>Successful Entries</td>
            <td style={{ border: '2px solid #616161', padding: '8px' }}>8</td>
          </tr>
          <tr>
            <td style={{ border: '2px solid #616161', padding: '8px' }}>Rejected Entries</td>
            <td style={{ border: '2px solid #616161', padding: '8px' }}>2</td>
          </tr>
        </tbody>
      
          </table>
          <Text style={{ color: '#00A5CF', width: '100%', marginBottom: 15 }}>
            Download Rejected Entries and Re-upload
          </Text>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'left',marginBottom:10 }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left', marginBottom: 10 }}>
              <div style={{ width: 100, height: 30, backgroundColor: '#00A5CF', borderRadius: 14, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginRight: 10 }}>
                <Img src="images/Download.png" alt="newitem" style={{ height: '13px', marginRight: '5px' }} />
                <Text style={{ fontSize: '12px', color: '#FFFFFF' }}>Download</Text>
              </div>
              <div style={{ width: 100, height: 30, backgroundColor: '#00A5CF', borderRadius: 14, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',marginBottom: 10 }}>
                <Img src="images/Upload.png" alt="newitem" style={{ height: '13px', marginRight: '5px' }} />
                <Text style={{ fontSize: '12px', color: '#FFFFFF' }}>Upload</Text>
              </div>
            </div>

            <Text style={{ color: '#00A5CF', width: '100%', marginBottom: 15 }}>Proceed without upload</Text>
          </div>
        </>
      )}
    </div>
  );
};

export default Upload;
