import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { NhostClient, NhostProvider } from "@nhost/react";
import { NhostApolloProvider } from "@nhost/react-apollo";
import ProtectedRoute from "pages/ProtectedRoute";
import NotFound from "pages/NotFound";
import Pricingchartdetails from "pages/Pricingchartdetails";
import Pricingchart from "pages/Pricingchart";
import EnquiryformOne from "pages/EnquiryformOne";
import BrowserloginOne from "pages/BrowserloginOne";
import Login from "./pages/BrowserloginOne/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Notification from "./pages/Notification";
import Orgprofile from "./pages/Dashboard/Orgprofile";
import Profile from "./pages/Dashboard/Profile";
import FormCreation from "./pages/Dashboard/FormCreation";
import Adduser from "./pages/Dashboard/Adduser";
import Usermanagement from "./pages/Dashboard/Usermanagement";
import Orgchannelmanagement from "./pages/Dashboard/Orgchannelmanagement";
import MainStepper from "./pages/AdminStepper/MainStepper";
import Stepper from "./pages/AdminStepper/Stepper";
import GroupStepper from "./pages/CreategroupStepper/GroupStepper";
import Createadmin from "./pages/AdminStepper/Createadmin";
import CommunicationDetails from "./pages/MobileScreen/CommunicationDetails";
import Verification from "./pages/MobileScreen/Verification";
import Payment from "./pages/MobileScreen/Payment";
import CreateAdmin from "./pages/MobileScreen/CreateAdmin";
import Rolls from "./pages/MobileScreen/Rolls";
import Userinfo from "./pages/MobileScreen/Userinfo";
import CreateForm from "./pages/FormBuilder/CreateForm";
import ChatList from "./pages/GroupSetting/ChatList";
import ChatScreen from "./pages/GroupSetting/ChatScreen";
import SettingMember from "./pages/GroupSetting/SettingMember";
import ExternalChatScreen from "components/ExternalIntegrationScreens/ExternalChatScreen";
import Formshower from "components/FormsPopup/Formshower";
import FormEditShower from "components/FormsPopup/FormEditShower";

function App() {
  const nhost = new NhostClient({
    subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
    region: process.env.REACT_APP_NHOST_REGION,
    autoSignIn: true,
    autoRefreshToken: true,
    refreshIntervalTime: 900,
  });

  return (
    <NhostProvider nhost={nhost}>
      <NhostApolloProvider nhost={nhost}>
        <HashRouter>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<EnquiryformOne />} />
            <Route path="/createform" element={<CreateForm />} />
            <Route path="/pricingchart" element={<Pricingchart />} />
            <Route path="/pricingchartdetails" element={<Pricingchartdetails />} />
            <Route path="/browserloginone" element={<BrowserloginOne />} />
            <Route path="/login" element={<Login />} />
            <Route path="/MainStepper" element={<MainStepper />} />
            <Route path="/Stepper" element={<Stepper />} />
            <Route path="/GroupStepper" element={<GroupStepper />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/Orgprofile" element={<Orgprofile />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Orgchannelmanagement" element={<Orgchannelmanagement />} />
            <Route path="/Usermanagement" element={<Usermanagement />} />
            <Route path="/FormCreation" element={<FormCreation />} />
            <Route path="/AdduSer" element={<Adduser />} />
            <Route path="/CommunicationDetails" element={<CommunicationDetails />} />
            <Route path="/Verification" element={<Verification />} />
            <Route path="/Payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
            <Route path="/CreateAdmin" element={<CreateAdmin />} />
            <Route path="/Rolls" element={<Rolls />} />
            <Route path="/Userinfo" element={<Userinfo />} />
            <Route path="/ChatList/:id/:userid" element={<ChatList />} />
            <Route path="/ChatScreen" element={<ChatScreen/>} />
            <Route path="/chatscreen/:intiater/:receiver" element={<ExternalChatScreen/>} />
            <Route path="/formshower" element={<Formshower/>} />
            <Route path="/formeditshower" element={<FormEditShower/>} />



            <Route path="/SettingMember" element={<SettingMember/>} />
          </Routes>
        </HashRouter>
      </NhostApolloProvider>
    </NhostProvider>
  );
}

export default App;
