import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import defaultProfileImage from '../../assets/images/gorupplaceholder.png';

const ChatListItem = (props) => {
  const navigate = useNavigate();
  const handleChatScreen = () => {
    navigate("/ChatScreen", { state: { chat: props.personalchat } }); // Pass personalchat data to ChatScreen
  };
  const { setOrgPersonalChat, orgPersonalChat } = props;

  const imageUrl = props.personalchat.groupdata.groupprofile_img
  ? `https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${props.personalchat.groupdata.groupprofile_img}`
  : defaultProfileImage;

  return (
    <div
      className="flex items-center  justify-center   p-4  hover:bg-gray-100 cursor-pointer mb-2 bg-[#FFFFFF] "
      style={{
        width: "95%",
        border: "1px solid #c3c3c3",
        alignSelf: ",Center",
        borderRadius: "12px",
        height: 60,
      }}
      onClick={handleChatScreen}
    >
      <div
        className="flex-shrink-0 w-12 h-12 flex items-center justify-center text-2xl"
        style={{
          backgroundColor: "#D9D9D9",
          border: "2px solid #c3c3c3",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={imageUrl}
          alt="Profile"
          style={{ resize: "both",  }}
        />
      </div>
      <div className="ml-4 flex-1">
        <div className="font-bold text-gray-800">
          {props.personalchat.groupdata.groupname}
        </div>
        {props.personalchat.members && (
          <div className="text-sm text-gray-600">
            {props.personalchat.members}
          </div>
        )}
        {props.personalchat.message && (
          <div className="text-sm text-gray-600">
            {props.personalchat.message}
          </div>
        )}
      </div>
      <div className="ml-4 text-right">
        <div className="text-sm text-gray-600">{props.personalchat.time}</div>
        <div className="flex flex-row gap-2">
          {props.personalchat.mention && (
            <div className="text-sm text-white text-[#c3c3c3] rounded-full w-4 h-4 flex items-center justify-center ">
              {props.personalchat.mention}
            </div>
          )}
          {props.personalchat.unread && (
            <div className="text-sm text-white bg-[#c3c3c3] text-[#FFFFFF] rounded-full w-4 h-4 flex items-center justify-center">
              {props.personalchat.unread}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ChatListItem.propTypes = {
  chat: PropTypes.shape({
    name: PropTypes.string.isRequired,
    members: PropTypes.string,
    message: PropTypes.string,
    time: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    unread: PropTypes.number,
  }).isRequired,
};

export default ChatListItem;
