import { gql, useMutation, useQuery } from "@apollo/client";
import { useChangePassword, useNhostClient, useUserId } from "@nhost/react";
import { Button, Img, Input, Text } from "components";
import { Heading } from "components/Heading";
import Header from "components/NewHeader/Header";
import React, { useRef, useState } from "react";
import { Navigate, redirect, useNavigate } from "react-router-dom";

const FETCH_USER_DETAIL = gql`
query MyQuery($_eq: uuid = "") {
    users(where: {id: {_eq: $_eq}}) {
      displayName
      email
      avatarUrl
    }
  }
`;
  const UPDATE_USER_DETAIL =gql`
  mutation MyMutation($_eq: uuid = "", $avatarUrl: String = "", $displayName: String = "", $email: citext = "") {
    updateUsers(where: {id: {_eq: $_eq}}, _set: {avatarUrl: $avatarUrl, displayName: $displayName, email: $email}) {
      returning {
        email
        displayName
      }
    }
  }`;

  const UPDATE_PROFILE_DETAIL =gql`
  mutation MyMutation($_eq: uuid = "", $avatarUrl: String = "") {
    updateUsers(where: {id: {_eq: $_eq}}, _set: {avatarUrl: $avatarUrl}) {
      returning {
        avatarUrl
      }
    }
  }`;
  


export default function FormcreationPage() {
    const userid=useUserId();
    const nhost = useNhostClient();
    const [editedUserData, setEditedUserData] = useState({});
    const [data,setData]= useState({});
    const [data1,setData1]= useState();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

       
  const handleChange = (key , value)=>{
    console.log(key,value)
    setData(
      {
     ...data,
        [key]: value
      }
    )
    }
   
    const { loading, error, data:userdata } = useQuery(FETCH_USER_DETAIL, {
        variables: {
          _eq: userid,
        },
        onError: (error) => {
          console.log('error fetching user details: ', error);
        },
        onCompleted: (data) => {
          // Data fetching is completed
          console.log('Data:', data.users);
      setData(data.users[0]); 
        },
    });


    const [updateUser] = useMutation(UPDATE_USER_DETAIL);
    const handleSaveChanges = async () => {
      try {
        const response = await updateUser({
          variables: {
            _eq: userid,
            displayName: data["displayName"],
            email: data["email"]
          }
        });
  
        // Update local state with the new data
        const updatedUserData = response.data.updateUsers.returning[0];
        setData(updatedUserData);
        console.log("User details updated successfully:", updatedUserData);
      } catch (error) {
        console.error("Error updating user details:", error);
      }
    };

    const { changePassword, isLoading, isSuccess, isError, errors } =
    useChangePassword()
  
  console.log({ isLoading, isSuccess, isError, errors })
  
  const handleFormSubmit = async (e) => {
    e.preventDefault()
    if (newPassword !== confirmPassword) {
      alert('New password and confirm password do not match.');
      return;
  }
  
    await changePassword(confirmPassword)
  }
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleLogout = async (e) => {
    localStorage.clear();
    navigate("/");
    nhost.auth.signOut()
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("slected",file)
      setSelectedImage(file);
    }
  };

  const [updateImage] = useMutation(UPDATE_PROFILE_DETAIL, {
    onCompleted: (data1) => {
      console.log("eqebqkndkj",data1.updateUsers.returning)
     setData1(data1.updateUsers.returning)
     
    },
    onError: (error) => {
      console.log("Error updating profile data:", error);
    },
  });
  const handleImageUpload = async () => {
    if (selectedImage) {
      try {
        // Perform image upload logic here
        // For example, using FormData to send the image file to your backend
     
  
      await updateImage({
          variables: {
            _eq: userid,
            avatarUrl:data1.selectedImage
          },
        });
        console.log(selectedImage)
  
        // Update local state with the new data
      
        console.log("Image updated successfully:", data1);
      } catch (error) {
        console.error("Error updating user image:", error);
      }
    } else {
      console.log("No image selected.");
    }
    console.log('Image uploaded:', selectedImage);
    console.log("Image updated successfully:", data1); 
  };
  
  

      // Send formData to your backend for image upload
      // Example: axios.post('/upload', formData);
     


  const openFileInput = () => {
    fileInputRef.current.click();
  };


  return (
    <>
     
     {/* <header className="flex justify-center items-center w-full p-9 sm:p-5 border-orange-300 border-b-[3px] border-solid bg-orange-50">
      <div className="flex flex-row justify-center w-full mx-auto md:px-5 max-w-[1427px]">
        <div className="flex flex-row sm:flex-col justify-between items-center w-full sm:gap-10">
          <div className="flex flex-row justify-between items-center w-auto gap-8">
            <Img
              src="images/img_convene_logo.png"
              alt="convenelogo_one"
              className="w-[60px] md:h-auto object-cover"
            />
            <Heading size="4xl" as="h2">
              Convene chat
            </Heading>
          </div>
          <div className="flex flex-row justify-between items-center w-[12%] sm:w-full">
            <Img src="images/img_icon.svg" alt="icon_one" className="h-[49px]"  />
            <div className="flex flex-col items-center justify-start w-[44%]">
              <div className="h-[71px] w-full relative">
                <Img
                  src="images/img_rectangle_6.png"
                  alt="image"
                  className="h-[68px] w-[68px] top-0 right-0 left-0 m-auto object-cover absolute"
                  />
                <button
                  color="light_green_400"
                  size="xs"
                  variant="fill"
                  shape="round"
                  className="w-[21px] bottom-0 right-0 m-auto border-white-A700 border-[5px] border-solid absolute"
                >
                  <Img src="images/img_group_62.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header> */}
     <Header backgroundColor={"#E9EAEE"} border={"3px solid #616161"}/>
      {/* profile section */}
      <div className="gap-[31px] ml-12 flex w-full flex-col items-start w-[95%]">
        <Text style={{dipslay:'flex',fontSize:'30px',marginTop:"92px",color:"#616161",fontWeight:"bold"}}
        >
          Profile
        </Text>
        
        <div className="gap-[49px] flex items-start self-stretch md:flex-col">
          {/* edit profile section */}
          <div className="gap-[13px] flex flex-1 flex-col md:self-stretch md:p-5">
            <div className="w-[73%] flex flex-wrap justify-between gap-5 md:w-full">
              <Heading as="h2" className="self-start">
                Edit Profile
              </Heading>
              <Heading as="h3" className="self-end">
                Change Password
              </Heading>
            </div>

            {/* personal details section */}
            <div className="gap-[49px] flex md:flex-col w-[91%]">
              <div className="flex flex-1 flex-col items-start gap-6 md:self-stretch">
                <div className="rounded-[15px] flex flex-col gap-3.5 self-stretch  p-6 sm:p-5"style={{border:"2px solid #D9D9D9"}}>
                  <div className="flex flex-col items-start gap-3.5 w-full">
                    <div className="gap-4 " >
                      <text className="text-gray-500  mb-2.5">Full Name</text>
                      </div>
                  
                    <input
                      shape="round"
                      name="displayName"
                     
                      value={data?.displayName}
           
              onChange={(e)=>handleChange('displayName',e.target.value)}
  
                      className=" sm:px-5 w-full p-4 border border-solid border-#C4C4C4 rounded-[15px]"
                      
                      />
                    <div className="flex flex-col items-start gap-4 self-stretch">
                      <Text as="p">
                        <span className="text-gray-500">Email Adress&nbsp;</span>
                        <span className="text-[10px] text-red_A700">*&nbsp;</span>
                      </Text>
                      <input
                      shape="round"
                      name="email"
                      value={data?.email}
           
                      onChange={(e)=>handleChange('email',e.target.value)}
                      className=" sm:px-5 w-full p-4 border border-solid border-#C4C4C4 rounded-[15px]"
                      
                      />
                    </div>
                  </div>
                  <div className="mb-[11px] flex flex-col gap-3.5">
                    <div className="w-[58%] flex flex-wrap justify-between gap-5 md:w-full">
                      <Text as="p" className="self-start">
                        Number{" "}
                      </Text>
                      <Text as="p" className="self-end">
                        City
                      </Text>
                    </div>
                    <div className="gap-[22px] flex sm:flex-col">
                    <input
                      shape="round"
                      name="fullName"
                      placeholder={`Madhumati oak`}
                      className=" sm:px-5 w-full p-4 border border-solid border-#C4C4C4 rounded-[15px]"
                      
                      />
                       <input
                      shape="round"
                      name="fullName"
                      placeholder={`Madhumati oak`}
                      className=" sm:px-5 w-full p-4 border border-solid border-#C4C4C4 rounded-[15px]"
                      
                      />                  </div>
                  </div>
                </div>
                <button style={{  backgroundColor: '#00A5CF', 
    border: 'none',            
    width: '218px',              
    height: '45px',           
    borderRadius:"14px",
                 
    left: '99px',               
    color: 'white',             
    cursor: 'pointer',           
    outline: 'none'  }} onClick={ handleSaveChanges}>
                 Save Changes
                </button>
              </div>

              {/* password change section */}
              <div className="w-[42%] flex flex-col items-start gap-6 md:w-full">
                <div className="rounded-[15px] gap-[15px] flex flex-col items-start self-stretch border border-solid border-blue_gray-100 p-6 sm:p-5">
                  <Text as="p" className="ml-[3px] md:ml-0">
                    Current Password
                  </Text>
                  <input
                      shape="round"
                      name="fullName"
                      placeholder={`Madhumati oak`}
                      className=" sm:px-5 w-[70%] p-4 border border-solid border-#C4C4C4 rounded-[15px]"
                      
                      />
                  <Text as="p" className="ml-[3px] md:ml-0">
                    New Password
                  </Text>
                  <input
                      shape="round"
                      name="fullName"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className=" sm:px-5 w-[70%] p-4 border border-solid border-#C4C4C4 rounded-[15px]"
                      
                      />
                  <Text as="p" className="ml-[3px] md:ml-0">
                    Confirm New Password
                  </Text>
                  <input
                      shape="round"
                      name="fullName"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className=" sm:px-5 w-[70%] p-4 border border-solid border-#C4C4C4 rounded-[15px]"
                      
                      />
                </div>
                <button style={{  backgroundColor: '#00A5CF', 
    border: 'none',            
    width: '218px',              
    height: '45px',           
    borderRadius:"14px",
                 
    left: '99px',               
    color: 'white',             
    cursor: 'pointer',           
    outline: 'none'  }}  onClick={ handleFormSubmit}>
                 save changes
                </button>
              </div>
              
            </div>
          </div>

          {/* profile icon change section */}
          <div className="mt-[3px] w-[19%] flex flex-col items-start gap-3 md:w-full md:p-5">
            <Heading as="h2">Change profile icon</Heading>
            <div className="gap-[31px] flex flex-col items-start ">
            <div className="w-[90%] flex items-center gap-4 md:w-full">
  <div className="h-[120px] flex-1 border border-solid border-blue_gray-100">
    {selectedImage ? (
      <img src={URL.createObjectURL(selectedImage)} style={{ height: "100%", width: "100%", objectFit: "cover" }} />
    ) : (
      <Img src={`https://ziuetfnxlyjlcvdiraet.storage.ap-south-1.nhost.run/v1/files/${data?.avatarUrl}`} style={{ height: "100%", width: "100%", objectFit: "cover" }} />
    )}
  </div>
  <div className="w-[20px] h-[30px] self-end" onClick={openFileInput}>
    <input type="file" onChange={handleImageChange} ref={fileInputRef} style={{ display: "none" }} />
    <Img src="images/Edit.svg" />
  </div>
</div>
              <button style={{  backgroundColor: '#00A5CF', 
    border: 'none',            
    width: '218px',              
    height: '45px',           
    borderRadius:"14px",
                 
    left: '99px',               
    color: 'white',             
    cursor: 'pointer',           
    outline: 'none'  }} onClick={handleImageUpload}  >
                 save changes
                </button>
            </div>
          </div>
          <div>
          <button style={{  backgroundColor: '#00A5CF', 
    border: 'none',            
    width: '218px',              
    height: '45px',           
    borderRadius:"14px",
                 
    left: '99px',               
    color: 'white',             
    cursor: 'pointer',           
    outline: 'none'  }} onClick={handleLogout}  >
                 Logout
                </button>
        </div>
        </div>
      </div>
    </>
  );
}