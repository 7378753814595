import { gql, useMutation, useQuery } from "@apollo/client";
import { useUserId } from "@nhost/react";
import React, { useEffect, useState } from "react";
import { Text } from "components";


const INSERT_FORM_CREATED_DETAIL = gql`
mutation MyMutation($Form_Name: String = "", $Form_api: jsonb = "", $Form_data: jsonb = "", $created_user_id: uuid = "", $group_id: bigint = "", $orgn_id: bigint = "", $status: String = "active") {
    insert_Form_api_data(objects: {Form_Name: $Form_Name, Form_api: $Form_api, Form_data: $Form_data, created_user_id: $created_user_id, group_id: $group_id, orgn_id: $orgn_id, status: $status}) {
      returning {
        orgn_id
        id
      }
    }
  }
  
`;

const GET_ALL_GROUPS_ADMIN_CREATED = gql`
query getgroupsoforginsation($_eq: bigint = "", $_eq1: String = "orgadmincreated") {
  group_master(where: {orgn_id: {_eq: $_eq}, _and: {type: {_eq: $_eq1}}}) {
    groupname
    id
  }
}

`;

export default function CreateForm() {
  const [data, setData] = useState({});
  const userid = useUserId()
  const [organisation, setSelectOrganisation] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState('');

  const [apiData, setApiData] = useState({});
  const [allFieldsData, setAllFieldsData] = useState([]);
  const [inputFieldActive, setInputFieldActive] = useState(false);
  const [imageFieldActive, setimageFieldActive] = useState(false);
  const [dateFieldActive, setdateFieldActive] = useState(false);
  const [radioFieldActive, setradioFieldActive] = useState(false);
  const [staticDataActive, setstaticDataActive] = useState(false);

  const [apiFieldActive, setapiFieldActive] = useState(false);
const [groupsId , setGroupsId] = useState(0)
  const [radioFieldsData, setRadioFieldsData] = useState([]);
  const [tempRadioFieldsData, setTempRadioFieldsData] = useState([]);
  const [formName , setFormName] = useState('')

  const handleInputField = (type) => {
    // console.log(type);
    if (type === "textfield") {
      handleChange("type" , type)
      setInputFieldActive(true);
      setimageFieldActive(false);
      setdateFieldActive(false);
      setapiFieldActive(false);
      setradioFieldActive(false);
    } else if (type === "image") {
      handleChange("type" , type)
      setimageFieldActive(true);
      setInputFieldActive(false);
      setdateFieldActive(false);
      setapiFieldActive(false);
      setradioFieldActive(false);
    } else if (type === "date") {
      handleChange("type" , type)
      setdateFieldActive(true);
      setInputFieldActive(false);
      setimageFieldActive(false);
      setradioFieldActive(false);
      setapiFieldActive(false);
    } else if (type === "radio") {
      handleChange("type" , type)
      setradioFieldActive(true);
      setInputFieldActive(false);
      setimageFieldActive(false);
      setdateFieldActive(false);
      setapiFieldActive(false);
    } else if (type === "configureapi") {
      handleChange("type" , type)
      setapiFieldActive(true);
      setInputFieldActive(false);
      setimageFieldActive(false);
      setdateFieldActive(false);
      setradioFieldActive(false);
    }
    else if (type === "staticdata") {
      handleChange("type" , type)
      setstaticDataActive(true);
      setInputFieldActive(false);

    }

    // setInputFieldActive(true);
  };
  const [InsertUserDetail] = useMutation(INSERT_FORM_CREATED_DETAIL, {
    onCompleted: (data) => {
      // console.log("User final ID:", data);
      // alert("good")
    
    },
    onError: (error) => {
      console.log("error updating profile : ", error);
    },
  });

  const handleSelectChange = (event) => {
    console.log(event.target.value)
    setGroupsId(event.target.value)
    
  };
  const handleChange = (key, value) => {
    console.log(key , value)
    setData({ ...data, [key]: value });
    
  };
  const handleApidataChange = (key, value) => {
    setApiData({ ...apiData, [key]: value });
  };

  const handleTextInput = async(type) => {
    // Push the current data to the array
   await handleChange("type" , type)
    console.log(data)
    // console.log(data)
    // Clear the current data
    // setData({});
    // Deactivate input field
    setInputFieldActive(false);
    tempfucntion()
  };
  const tempfucntion = async ()=>{
    setAllFieldsData([...allFieldsData, data]);

  }
  const handleImagepicker = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
      let file = event.target.files[0];
      console.log("this is first image : ", file);
    };
    input.click();
  }
  const { loading, error, data: queryData } = useQuery(GET_ALL_GROUPS_ADMIN_CREATED, {
    variables: {
       _eq: localStorage.getItem('id') ,

    },
    onCompleted: (data) => {
      console.log("required data" , data.group_master[0]);
      setSelectOrganisation( data.group_master);
    },
  });
  const [editMode, setEditMode] = useState(null);

  // const handleAddRadioField = () => {
  //     const newRadioField = {
  //         type: 'radio',
  //         label: '',
  //         value: '' // Add value property as per your requirement
  //     };
  //     setRadioFieldsData([...radioFieldsData, newRadioField]);
  // };
  const handleEditChange = (index, key, value) => {
    const newArray = [...allFieldsData];
    newArray[index][key] = value;
    setAllFieldsData(newArray);
  }

  const handleAddRadioField = () => {
    setTempRadioFieldsData([
      ...tempRadioFieldsData,
      { label: "", value: "false" },
    ]);
  };

  const handleAddRadioFieldToForm = () => {
    // console.log(tempRadioFieldsData)
    const radioFieldObject = {
      label: data.label,
      type: "radio",
      // assuming data.label holds the question for radio buttons
      options: tempRadioFieldsData.map((radioField) => ({
        label: radioField.label,
        value: radioField.value,
      })),
    };
    // setAllFieldsData(prevAllFieldsData => [...prevAllFieldsData, radioFieldObject]);
    setAllFieldsData([...allFieldsData, radioFieldObject]);
    setRadioFieldsData([...radioFieldsData, ...tempRadioFieldsData]);
    setTempRadioFieldsData([]);
    setradioFieldActive(false);
    // console.log(allFieldsData)
  };
  const handleApiCreation =async () => {
    // console.log("hello",apiData)
    
  };
  const handleSubmitData = async () => {
    console.log(allFieldsData, "data array")
    try {
      const { data: insertedData } = await InsertUserDetail({
        variables: {
          Form_data: allFieldsData,
          Form_api: apiData,
          Form_Name: formName,
          created_user_id: userid,
          group_id: groupsId,
          orgn_id: localStorage.getItem('id'),
        },
      });
 
      console.log("inserted user info", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  }

  const toggleEditMode = (index) => {
    setEditMode(editMode === index ? null : index);
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "40%",
          height: "100%",
          border: "2px solid grey",
        }}
      >
        <input
              style={{ width: 200, height: 30, border: "2px solid black" }}
              placeholder="Enter Form Name"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
            />
        <button
          style={{ width: "100%", height: 50, border: "2px solid black" }}
          onClick={() => {
            handleInputField("textfield");
          }}
        >
          Input Field
        </button>

        {inputFieldActive && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              style={{ width: 200, height: 30, border: "2px solid black" }}
              placeholder="Enter Label value"
              value={data.label || ""}
              onChange={(e) => {handleChange("label", e.target.value); }}
            />
            <input
              style={{ width: 200, height: 30, border: "2px solid black" }}
              placeholder="Enter Placeholder"
              value={data.placeholder || ""}
              onChange={(e) => handleChange("placeholder", e.target.value)}
            />
            <input
              style={{ width: 200, height: 30, border: "2px solid black" }}
              placeholder="Enter value name to be stored"
              value={data.valuedata || ""}
              onChange={(e) => handleChange("valuedata", e.target.value)}
            />
            <button
              style={{
                width: 200,
                height: 40,
                margin: 2,
                border: "2px solid black",
              }}
              onClick={() => {
                handleChange("type", "textfield");
                handleTextInput("textfield");
              }}
            >
              Add input field
            </button>
          </div>
        )}

        {/* Image field button */}
        <button
          style={{ width: "100%", height: 50, border: "2px solid black" }}
          onClick={() => {
            handleInputField("image");
          }}
        >
          Image Picker
        </button>
        {imageFieldActive && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              style={{ width: 200, height: 30, border: "2px solid black" }}
              placeholder="Enter Image picker Lable"
              value={data.label || ""}
              onChange={(e) => {
                handleChange("label", e.target.value);
              }}
            />
            <input
              style={{ width: 200, height: 30, border: "2px solid black" }}
              placeholder="Enter Placeholder"
              value={data.placeholder || ""}
              onChange={(e) => handleChange("placeholder", e.target.value)}
            />
            <input
              style={{ width: 200, height: 30, border: "2px solid black" }}
              placeholder="Enter value name to be stored"
              value={data.valuedata || ""}
              onChange={(e) => handleChange("valuedata", e.target.value)}
            />
            <button
              style={{
                width: 200,
                height: 40,
                margin: 2,
                border: "2px solid black",
              }}
              onClick={() => {
                handleTextInput('image');
              }}
            >
              Add Image field
            </button>{" "}
          </div>
        )}

        {/* <button style={{ width: '100%', height: 50, border: '2px solid black' }} onClick={()=>{handleInputField("image")}}>Image Picker</button>
                {imageFieldActive && (
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <input style={{ width: 200, height: 30, border: '2px solid black' }} placeholder='Enter Image picker Lable' value={data.label || ''} onChange={(e) => {handleChange('label', e.target.value); }} />
                        <input style={{ width: 200, height: 30, border: '2px solid black' }} placeholder='Enter Placeholder' value={data.placeholder || ''} onChange={(e) => handleChange('placeholder',e.target.value)} />
                        <input style={{ width: 200, height: 30, border: '2px solid black' }} placeholder='Enter value name to be stored' value={data.valuedata || ''} onChange={(e) => handleChange('valuedata',e.target.value)} />
                        <button style={{ width: 200, height: 40, margin: 2, border: '2px solid black' }} onClick={()=>{handleTextInput()}}>Add Image field</button>                    </div>
                )} */}

        {/* Date field button */}
        <button
          style={{ width: "100%", height: 50, border: "2px solid black" }}
          onClick={() => {
            handleInputField("date");
          }}
        >
          Date Picker
        </button>
        {dateFieldActive && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              style={{ width: 200, height: 30, border: "2px solid black" }}
              placeholder="Enter Date picker Lable"
              value={data.label || ""}
              onChange={(e) => {
                handleChange("label", e.target.value);
              }}
            />
            <input
              style={{ width: 200, height: 30, border: "2px solid black" }}
              placeholder="Enter Placeholder"
              value={data.placeholder || ""}
              onChange={(e) => handleChange("placeholder", e.target.value)}
            />
            <input
              style={{ width: 200, height: 30, border: "2px solid black" }}
              placeholder="Enter value name to be stored"
              value={data.valuedata || ""}
              onChange={(e) => handleChange("valuedata", e.target.value)}
            />
            <button
              style={{
                width: 200,
                height: 40,
                margin: 2,
                border: "2px solid black",
              }}
              onClick={() => {
                handleTextInput("date");
              }}
            >
              Add Date field
            </button>{" "}
          </div>
        )}

        {/* Radio field button */}
        <button
          style={{ width: "100%", height: 50, border: "2px solid black" }}
          onClick={() => {
            handleInputField("radio");
          }}
        >
          Radio Buttons
        </button>
        {radioFieldActive && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              style={{ width: 250, height: 30, border: "2px solid black" }}
              placeholder="Enter Question to be asked"
              value={data.label}
              onChange={(e) => {
                handleChange("label", e.target.value);
              }}
            />

            {tempRadioFieldsData.map((radioField, index) => (
              <div
                key={index}
                style={{
                  width: 250,
                  height: 50,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  border: "2px solid red",
                }}
              >
                <input
                  type="radio"
                  name={`radioOption${index}`}
                  value={radioField.value}
                />
                <input
                  style={{ width: 200, height: 30, border: "2px solid black" }}
                  placeholder="Enter Radio Label"
                  value={radioField.label || ""}
                  onChange={(e) => {
                    radioField.label = e.target.value;
                    setTempRadioFieldsData([...tempRadioFieldsData]);
                  }}
                />
              </div>
            ))}
            <img
              src={require("../../assets/images/plus.png")}
              alt="plus"
              style={{
                width: 30,
                height: 30,
                border: "2px solid black",
                borderRadius: 50,
                cursor: "pointer",
              }}
              onClick={() => handleAddRadioField()}
            />
            <button
              style={{
                width: 200,
                height: 40,
                margin: 2,
                border: "2px solid black",
              }}
              onClick={handleAddRadioFieldToForm}
            >
              Add Radio field
            </button>
          </div>
        )}

         {/* Radio field button */}
         <button
          style={{ width: "100%", height: 50, border: "2px solid black" }}
          onClick={() => {
            handleInputField("staticdata");
          }}
        >
          Please Enter Static data
        </button>
        {staticDataActive && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              style={{ width: 250, height: 30, border: "2px solid black" }}
              placeholder="Enter Question to be asked"
              value={data.label}
              onChange={(e) => {
                handleChange("label", e.target.value);
              }}
            />

            {tempRadioFieldsData.map((radioField, index) => (
              <div
                key={index}
                style={{
                  width: 250,
                  height: 50,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  border: "2px solid red",
                }}
              >
                <input
                  type="radio"
                  name={`radioOption${index}`}
                  value={radioField.value}
                />
                <input
                  style={{ width: 200, height: 30, border: "2px solid black" }}
                  placeholder="Enter Radio Label"
                  value={radioField.label || ""}
                  onChange={(e) => {
                    radioField.label = e.target.value;
                    setTempRadioFieldsData([...tempRadioFieldsData]);
                  }}
                />
              </div>
            ))}
            <img
              src={require("../../assets/images/plus.png")}
              alt="plus"
              style={{
                width: 30,
                height: 30,
                border: "2px solid black",
                borderRadius: 50,
                cursor: "pointer",
              }}
              onClick={() => handleAddRadioField()}
            />
            <button
              style={{
                width: 200,
                height: 40,
                margin: 2,
                border: "2px solid black",
              }}
              onClick={handleAddRadioFieldToForm}
            >
              Add Radio field
            </button>
          </div>
        )}
        {/* Configure Api field button */}

        <button
          style={{
            width: "100%",
            height: 50,
            border: "2px solid black",
            marginTop: 10,
            backgroundColor: "#A1B5d8",
            color: "white",
          }}
          onClick={() => {
            handleInputField("configureapi");
          }}
        >
          Configure ApI
        </button>
        {apiFieldActive && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: 250, height: 80 }}>
              {/* <p>Enter Domain or IPaddress</p> */}
              <p>Please enter like "https://192.168.29.192:8080" only</p>

              <input
                style={{ width: 250, height: 30, border: "2px solid black" }}
                placeholder="Enter Domain or IPaddress"
                value={apiData.ipdomain}
                onChange={(e) => {
                  handleApidataChange("ipdomain", e.target.value);
                }}
              />
            </div>

            <div style={{ width: 250, height: 80 }}>
              {/* <p>Enter Domain or IPaddress</p> */}
              <p>Please enter like "adduser" only</p>

              <input
                style={{ width: 250, height: 30, border: "2px solid black" }}
                placeholder="Enter End Point"
                value={apiData.endpoint}
                onChange={(e) => {
                  handleApidataChange("endpoint", e.target.value);
                }}
              />
            </div>
            {/* <input style={{ width: 250, height: 30, border: '2px solid black' }} placeholder='Enter End poi' value={data.valuedata || ''} onChange={(e) => handleChange('valuedata',e.target.value)} /> */}

            <button
              style={{
                width: 200,
                height: 40,
                margin: 2,
                border: "2px solid black",
              }}
              onClick={() => {
                handleApiCreation();
              }}
            >
              Configure Api
            </button>
          </div>
        )}

<div className='w-full h-50'>
      <Text
        className="md:ml-[0] ml-[19px] mt-8 text-cyan-600 text-lg"
        size="txtPoppinsRegular18Cyan600"
      >
        Select Group
      </Text>
      <select
        name="organisation"
        className="pl-2 w-100 mt-3.5 ml-2"
        style={{ height: 40, width: '93%', borderRadius: 10 }}
        value={selectedOrganisation}
        onChange={handleSelectChange}
      >
        <option value="">Select a Group</option>
        {organisation.map((item) => (
          <option key={item.id} value={item.id}>
            {item?.groupname}
          </option>
        ))}
      </select>
    </div>

<button
          style={{
            width: "100%",
            height: 50,
            border: "2px solid black",
            marginTop: 10,
            backgroundColor: "#A0bc95",
            // color: "white",
          }}
          onClick={() => {
            handleSubmitData();
          }}
        >
          Complete and Submit Form
        </button>

      </div>
      {/* the map function for showing form starts here */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "60%",
          height: "100%",
          border: "2px solid red",
        }}
      >
        {allFieldsData.map((item, index) => (
        <div key={index} style={{ width: '90%', display: 'flex', flexDirection: 'column', marginTop: 10, border: '2px solid #D9D9D9', borderRadius: 10, boxShadow: 'unset', padding: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <p style={{ color: '#00A5CF', fontSize: 17, margin: 8 }}>{item.label}</p>
          <button onClick={() => toggleEditMode(index)}>Edit</button>
        </div>
        {editMode === index ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="text"
              placeholder="Label"
              value={item.label}
              onChange={(e) => handleEditChange(index, 'label', e.target.value)}
              style={{ marginBottom: 10 }}
            />
            <input
              type="text"
              placeholder="Placeholder"
              value={item.placeholder}
              onChange={(e) => handleEditChange(index, 'placeholder', e.target.value)}
              style={{ marginBottom: 10 }}
            />
            <input
              type="text"
              placeholder="Value Data"
              value={item.valuedata}
              onChange={(e) => handleEditChange(index, 'valuedata', e.target.value)}
              style={{ marginBottom: 10 }}
            />
          </div>
        ) : (
          <>
            {item.type === "textfield" && (
              <input
                type="text"
                style={{ width: '90%', height: 50, color: 'black', borderRadius: 5, backgroundColor: '#EBEBEB', marginBottom: 10 }}
                placeholder={item.placeholder}
                onChange={(e) => handleChange(item.valuedata, e.target.value)}
                value={data[item.valuedata]}
              />
            )}
            {item.type === "image" && (
              <div style={{ width: '90%', height: 50, color: 'black', borderRadius: 5, backgroundColor: '#EBEBEB', marginBottom: 10, display: 'flex', alignItems: 'center' }} onClick={handleImagepicker}>
                <div style={{ width: '40%', height: '70%', backgroundColor: 'grey', marginLeft: 10, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <p style={{ color: '#FFFFFF' }}>Choose File</p>
                </div>
              </div>
            )}
            {item.type === "date" && (
              <input
                type="date"
                style={{ width: '90%', height: 50, color: 'black', borderRadius: 5, backgroundColor: '#EBEBEB', marginBottom: 10 }}
                onChange={(e) => handleChange(item.valuedata, e.target.value)}
                value={data[item.valuedata]}
              />
            )}
            {item.type === "radio" && (
              <div style={{ flexDirection: 'column', marginBottom: 10, width: '90%' }}>
                {item.options.map((option, optionIndex) => (
                  <label key={optionIndex} style={{ display: 'flex', alignItems: 'center', marginVertical: 10 }}>
                    <input
                      type="radio"
                      name={item.valuedata}
                      value={option.label}
                      checked={data[item.valuedata] === option.label}
                      onChange={() => handleChange(item.valuedata, option.label)}
                      style={{ marginRight: 10 }}
                    />
                    <span style={{ color: '#616161' }}>{option.label}</span>
                  </label>
                ))}
              </div>
            )}
          </>
        )}
      </div>
        ))}
      </div>
    </div>
  );
}
